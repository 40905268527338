import { useEffect, useState } from 'react';
import { HANDCOUNT_THRESHOLD } from '../constants';
import useSecurity from './useSecurity';

const usePhrases = () => {
  const { getProfile } = useSecurity();
  const [lang, setLang] = useState(getProfile()?.lang || 'EN');

  useEffect(() => {
    const user = getProfile();
    if (user) {
      setLang(getProfile().lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const phrases = (userLang) => {
    switch (userLang || lang) {
      default:
      case 'EN':
      case 'en':
        return {
          nav: {
            favorites: 'Favorites',
            profile: 'Profile',
            settings: 'Settings',
            logout: 'Logout',
            collapseSidebar: 'Collapse Sidebar',
            home: 'Home',
            structures: 'Structures',
            machines: 'Machines',
            sensors: 'Sensors',
            users: 'Users',
            manageStructures: 'Structures',
            manageMachines: 'Machines',
            roles: 'Roles',
            sensorTypes: 'Sensor Types',
            machineTypes: 'Machine Types',
            structureOverview: 'Structure Overview',
            machineOverview: 'Machine Overview',
            reasons: 'Reasons',
            reasonsManagement: 'Reasons',
            categoriesManagement: 'Categories',
            machineStatusesManagement: 'Statuses',
            terminal: 'Terminal',
            oee: 'OEE',
            report: 'Report',
            advanced_report: 'Advanced Report',
            schedule: 'Schedule',
            andon: 'Andon',
            views: 'Views',
            management: 'Management',
            orders: 'Orders',
            orders_export_validation: "Review",
            supportTicket: 'Support Ticket',
            supportPage: 'Support Website',
            help: 'Help',
            machineNaming: 'Machine Naming',
            machinePerformance: 'Machine Performance',
            jobs: "Jobs",
            reload: "Update Cycle",
            instant: "Instant",
            reloadTimes: {
              "0": "Instant",
              "10": "Fast",
              "60": "Medium",
              "300": "Slow",
              "3600": "Hourly",
            }
          },

          machineFilters: {
            default: 'default',
            availabilityminmax: 'availability-min-max',
            availabilitymaxmin: 'availability-max-min',
            performanceminmax: 'performance-min-max',
            performancemaxmin: 'performance-max-min',
            qualityminmax: 'quality-min-max',
            qualitymaxmin: 'quality-max-min',
          },

          pageTitles: {
            sensorsManagement: 'Sensors management',
            sensorTypesManagement: 'Sensor types management',
            usersManagement: 'Users management',
            rolesManagement: 'Roles management',
            machinesMangement: 'Machines management',
            machineTypesManagement: 'Machine types management',
            structuresManagement: 'Structures management',
            signIn: 'Sign in',
            help: 'Help',
          },

          tabs: {
            CreateStructure: 'Create Structure',
            ExistingStructures: 'Existing Structures',
            CreateMachine: 'Create Machine',
            ExistingMachines: 'Existing Machines',
            CreateSensor: 'Create Sensor',
            ExistingSensors: 'Existing Sensors',
            CreateUser: 'Create User',
            ExistingUsers: 'Existing Users',
            CreateRole: 'Create Role',
            ExistingRoles: 'Existing Roles',
            CreateSensorType: 'Create Sensor Type',
            ExistingSensorTypes: 'Existing Sensor Types',
            CreateMachineType: 'Create Machine Type',
            ExistingMachineTypes: 'Existing Machine Types',
            CreateReason: 'Create Reason',
            ExistingReasons: 'Existing Reasons',
            CreateCategory: 'Create Category',
            ExistingCategories: 'Existing Categories',
            CreateMachineStatus: 'Create Status',
            ExistingMachineStatuses: 'Existing Statuses',
            UserLogin: 'User Login',
            AppLogin: 'App Login',
            TerminalLogin: 'Terminal Login',
            SimpleView2Login: 'Machinen-Übersicht Login',
            SensorHealth: 'Sensor Health',
          },

          errorMessages: {
            emailOrPasswordIncorret: 'Email or password incorrect.',
            notAuthorized: 'You are not authorized to do that',
            pageNotFound: 'The page you are trying to access does not exist.',
            moduleNotFound:
              'The module you are trying to access is not available.',
            notAuthorizedToViewMachine:
              'You are not authorized to view this machine.',
            machineDoesNotExist:
              'The machine you are trying to access does not exist.',
              noMaxCycleTime: 'No "maxCycleTime" configured for this machine.',
          },

          misc: {
            selectStructureYouWantToView:
              'Select a structure you want to view.',
            selectMachineYouWantToView: 'Select a machine you want to view.',
            toContinueToLeanFocus: 'To continue to LEANFOCUS',
            noMachinesInThisStructure: 'No machines in this structure',
            loading: 'Loading...',
            minAvailability: 'Min. Availability',
            maxAvailability: 'Max. Availability',
            copyUrl: 'Copy URL',
            urlCopySuccess: 'URL copied successfully',
            availability: 'Availability',
            time: 'Time',
            search: 'Search',
            overview: 'Overview',
            showMachines: 'Show Machines',
            machineStatusDistribution: 'Machine status distribution',
            machineStatusTimeDistribution: 'Machine status time distribution',
            machineStatus: 'Machines Status',
            deselectAll: 'Deselect ALL',
            sortBy: 'Sort By',
            default: 'Default',
            for: 'for',
            with: 'with',
            average: 'at an average of',
            applyFilters: 'Apply Filters',
            all: 'All',
            title: 'Filters',
            reset: 'Reset',
          },

          modal: {
            EditStructure: 'Edit Structure',
            EditMachine: 'Edit Machine',
            EditSensor: 'Edit Sensor',
            EditUser: 'Edit User',
            EditRole: 'Edit Role',
            EditSensorType: 'Edit Sensor Type',
            EditMachineType: 'Edit Machine Type',
            CreateSensorType: 'Create Sensor Type',
            CreateMachineType: 'Create Machine Type',
            EditReason: 'Edit Reason',
            EditCategory: 'Edit Category',
            EditMachineStatus: 'Edit Machine Status',
          },

          timespanSelection: {
            last: 'Last',
            from: 'From',
            to: 'To',
            Last: 'Last',
            Hour: 'Hour',
            Hours: 'Hours',
            Minutes: 'Minutes',
            Minute: 'Minute',
            Days: 'Days',
            Day: 'Day',
            Weeks: 'Weeks',
            Week: 'Week',
            LastHour: 'Last Hour',
            Last6Hours: 'Last 6 Hours',
            Last12Hours: 'Last 12 Hours',
            Last24Hours: 'Last 24 Hours',
            Last7Days: 'Last 7 Days',
            ShowLastHour: 'Show Last Hour',
            ShowLast6Hours: 'Show Last 6 Hours',
            ShowLast12Hours: 'Show Last 12 Hours',
            ShowLast24Hours: 'Show Last 24 Hours',
            ShowLast7Days: 'Show Last 7 Days',
            Relative: 'Relative',
            DateRange: 'Date Range',
            CustomTimePreset: "Custom Time Preset",
            ctpTitleAlreadyPresent: "The title of the new preset is already present in the list. Please pick another one",
            ctpTitleEmpty: "The provided title is empty or invalid",
            newPresetTitle: "Title",
            addNewPreset: "Add new preset",
            addNewPresetDescription: "Please add the title of the preset and its start and end times.",
            deletePresetModalTitle: "Delete Custom Preset",
            addPresetModalTitle: "Add Custom Preset",
            areYouSureDeleteTimePreset: "Are you sure that you want to delete the preset?",
            dayOfPreset: "Day of Preset",
            CustomBegin: "Begin",
            CustomEnd: "End",
            SelectTimespan: 'Select Timespan',
            Today: 'Today',
            ShowPulseForToday: 'Show Pulse For Today',
            Yesterday: 'Yesterday',
            ShowPulseForYesterday: 'Show Pulse For Yesterday',
            ThisWeek: 'This week',
            ThisMonth: 'This Month',
            ThisYear: 'This Year',
            ShowPulseForThisWeek: 'Show Pulse for this week',
            ShowPulseForThisMonth: 'Show Pulse for this month',
            ShowPulseForThisYear: 'Show Pulse for this year',
            LastWeek: 'Last week',
            ShowPulseForLastWeek: 'Show Pulse For Last week',
            Presets: 'Presets',
            CustomPresets: 'Custom Presets',
            RestoreDefaultTimespan: 'Restore Default Timespan',
            localeDef: {
                "decimal": ".",
                "thousands": ",",
                "grouping": [3],
                "currency": ["$", ""],
                "dateTime": "%a %b %e %X %Y",
                "date": "%Y-%m-%d",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                "months": ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            },
            timeFormat: "MMMM do - HH:mm",
            dateFormat: "MMMM do",
          },

          modules: {
            andon: {
              config: 'ANDON CONFIG',
              theme: 'Theme',
              light: 'Light',
              dark: 'Dark',
              preview: 'ANDON PREVIEW',
              loadingMachines: 'Loading machines',
              loadingStructure: 'Loading structure',
              noStructureSelected: 'No structure selected.',
              noMachines: 'No machines in selected structure.',
              slideshowInterval: 'Slideshow interval',
              set: 'Set',
              minutes: 'Minutes',
              seconds: 'Seconds',
              minutesAbbr: 'Min',
              secondsAbbr: 'Sec',
              machines: 'Selected machines',
              machinesLabel: 'Machines',
              running: 'Running in timeframe',
              machineTypes: 'Selected machine types',
              machineTypesLabel: 'Machine types',
              on: 'On',
              off: 'Off',
              filter: 'Filter',
            },
            machine: {
              legendModalTitle: "Definition Overview",
              staticReason: "Reason",
              statusTime: "Time",
              loadingPulse: 'Loading the production pulse...',
              runtime: 'Runtime',
              downtime: 'Downtime',
              running: 'Running',
              notRunning: 'Not Running',
              online: 'Online',
              lastSeen: 'Last seen',
              never: 'never',
              duration: 'Duration',
              state: 'State',
              machine: 'Machine',
              drillDown: 'Drill Down',
              machinesPermissions: 'Machines permissions',
              machineTypesPermission: 'Machine Types permissions',
              machineStatusesPermission: 'Machine statuses permissions',
              availabilityLowToHigh: 'Availability: Low to High',
              availabilityHighToLow: 'Availability: High to Low',
              performanceLowToHigh: 'Performance: Low to High',
              performanceHighToLow: 'Performance: High to Low',
              loadingMachine: 'Loading machine...',
              partsPerMinute: 'Parts/Minute',
              secPerPart: 'Sec/Part',
              partsPerMinuteString: 'parts per minute',
              secPerPartString: 'seconds per part',
              currentSpeedTarget: 'Current Speed (Target)',
              selectMachine: 'Please select a machine',
              sortBy: 'Sort by',
              qualityLowToHigh: 'Quality: Low to High',
              qualityHighToLow: 'Quality: High to Low',
              speedTarget: 'Ø Speed (Target)',
              speedJob: 'Job Speed (Target)',
              splitState: 'Split State',
              mergeState: 'Merge States',
              merge: 'Merge',
              addPreviousState: 'Add previous State',
              addNextState: 'Add next State',
              noMoreStates: 'No more states available in selected timeframe',
              splitStateSuccessful: 'State has been split succesfully.',
              mergeStateSuccessful: 'States have been merged succesfully.',
            },
            simple_machine: {
              default: "Hello",
              parts: "Parts",
              shiftGoalLabel: "Shift\n[bold]Goal[/]",
              shiftGoal: "Shift Goal",
              timeGoal: "Time Goal",
              unit: "Parts",
              gauge: "Gauge",
              progress: "Progress Bar",
              targetCylceTime: 'Target Cycle Time',
              currentSpeed: 'Current Speed',
              partsPerHour: 'Parts/Hour',
            },
            simple_machine2: {
              currentSpeed: 'Current',
              targetSpeed: 'Target',
              shiftSpeed: "Shift",
              currentOrder: 'Current Order',
              customer: 'Customer',
              orderLoading: 'Loading Order...', 
              product: "Product",
              order: "Order",
              shiftStart: "Shift Start",
              shiftEnd: "Shift End",
            },
            order_export: {
              isWorkingOrderComplete: "Working Order Completed",
              totalScrap: "Scrap Count",
              isSetup: "Setup",
              intendedMachineNotEqualtoMachineTitle: "Intended Machine not equal to Machine",
              intendedMachineNotEqualtoMachine: "The intended machine of the Job is not equal to the machine you selected. Are you sure you want to continue?",
              noPartsProduced: "No parts produced in timeframe",
              handCountWarning: `Hand count is over ${parseInt(HANDCOUNT_THRESHOLD*100)}% of the sensor count`,
              label: "Permissions for order export",
              selectMachine: "Select Machine",
              selectStructure: "Select Structure",
              selectOrder: "Select Order",
              activations: "Activations",
              article_id: "Article",
              operator: "Operator",
              startDate: "Start",
              endDate: "End",
              oee: "OEE",
              mandant: "Mandant",  
              editActivation: "Edit Activation",         
              deleteActivation: "Delete Activation",
              addActivation: "Add Activation",
              qualityReports: "Quality Reports",
              job: "Job",
              activationStatus: "Activation Status",
              handCount: "Manual",
              user: "Operator",
              partsProduced: "Sensor",
              machine: "Machine",
              editActivationModalTitle: "Edit Activation",
              addActivationModalTitle: "Add Activation",
              activationUpdated: "Activation was successfully updated",
              deleteActivationModalTitle: "Delete Activation",
              activationAdded: "Activation was successfully added",
              userNotFound: "User not found",
              jobNotFound: "Job not found",
              productNotFound: "Product not found",
              genericError: "Something went wrong",
              activationDeleted: "Activation was successfully deleted",
              deleteError: "Deletion of activation was not successful",
              deleteActivationModalText: "Are you sure that you want to delete the activation?",
              qualityReportsList: "Quality Reports",
              selectedJob: "Selected Job",
              errors: {
                activationAlreadyPresent: "Activation already present in this timeframe",
                activationStatusRequired: "Activation status is required",
                operatorRequired: "Operator is required",
                handCountRequired: "Hand count is required",
                partsProducedRequired: "Parts produced is required",
                startTimeRequired: "Start time is required",
                endTimeRequired: "End time is required",
                endTimeBeforeStartTime: "End time must be after start time",
                productRequired: "Product is required",
                jobRequired: "Job is required",
                machineRequired: "Machine is required",
              },
              quality_report: {
                wasteCount: "Waste Count",
                startTime: "Start Time",
                endTime: "End Time",
                scrapType: "Scrap Type",
                editQualityReport: "Edit Quality Report",
                deleteQualityReport: "Delete Quality Report",
                addQualityReport: "Add Quality Report",
                deleteQualityReportModalTitle: "Delete Quality Report",
                deleteQualityReportModalText: "Are you sure that you want to delete the quality report?",
                qualityReportDeleted: "Quality report was successfully deleted",
              }

            }, 
            machine_shift: {
              shift: 'Shift',
              shifts: 'Shifts',
              createNewMachineShift: 'Create new machine shift',
              existingMachineShifts: 'Exisiting Machine Shifts',
              deleteConfirmation: "Are you sure that you want to delete shift '{shiftName}' from {startTime} to {endTime}?",
              confirmDeleteTitle: "Confirm delete shift",
              editMachineShift: "Edit machine shift",
              machineShiftPermission: "Permission for machine shifts",
              errors:{
                endTimeLargerStartTime: "End time must be larger than start time",
                noShiftFound: "No shift found for current time",
                nameRequired: "Shift name is required",
                machineRequired: "Machine is required",
                startTimeRequired: "Start time is required",
                endTimeRequired: "End time is required",
                isActiveRequired: "Active value is required",
                targetNumberLargerZero: "Target number must be larger than 0",
                mondayRequired: "Monday value is required",
                tuesdayRequired: "Tuesday value is required",
                wednesdayRequired: "Wednesday value is required",
                thursdayRequired: "Thursday value is required",
                fridayRequired: "Friday value is required",
                saturdayRequired: "Saturday value is required",
                sundayRequired: "Sunday value is required",
              },
              forms: {
                machineShiftAdded: "Machine shift was successfully added",
                machineShiftUpdated: "Machine shift was successfully updated",
                name: "Shift name",
                startTime: "Start time",
                endTime: "End time",
                machine: "Machine",
                machines: "Maschines",
                monday: "Monday",
                tuesday: "Tuesday",
                wednesday: "Wednesday",
                thursday: "Thursday",
                friday: "Friday",
                saturday: "Saturday",
                sunday: "Sunday",
                isActive: "Active",
                targetNumber: "Target Number",
              }
            },
            reporting: {
              minStartTime: 'Min Start Time',
              minEndTime: 'Min End Time',
              maxStartTime: 'Max Start Time',
              maxEndTime: 'Max End Time',
              minDuration: 'Min Duration',
              maxDuration: 'Max Duration',
              loadingReportData: 'Loading report data',
              oeeTab: 'OEE',
              machineTab: 'Machine',
              machineTabMachine: 'Machine',
              jobTab: 'Jobs',
              noMatchingData: 'Sorry, there is no matching data to display',
              minAvailability: 'Min Availability',
              maxAvailability: 'Max Availability',
              loadingMachines: 'Loading machines...',
              loadingQualityReports: 'Loading Quality Reports...',
              actualIdealCount: 'Actual/Target count',
              actualCount: 'Actual Count',
              goodCount: 'Good Count',
              targetCount: 'Target Count',
              scrapCount: 'Scrap Count',
              plannedDowntime: 'Planned Downtime',
              downtime: 'Unplanned Downtime',
              scheduleLoss: 'Schedule Loss',
            },
            advanced_reporting: {
                machineRankingHeadingText: "Machine Performance Overview",
                machineRankingChartID: "Machine Ranking",
                machineRankingValue: "performance",
                machineRankingCategory: "name",
                noPerformanceText: "N/A",
                noPerformanceValue: 0,
                reasonRankingHeadingText: "Worst failure reason Overview",
                reasonRankingChartID: "Reason Ranking",
                reasonRankingValue: "count",
                reasonRankingCategory: "name",
                noReasonsText: "No reasons yet",
                noReasonsValue: 1,
                averageHeadingText: "Average Overview",
                averageChartID: "Average Overview",
                averageValue: "value",
                averageCategory: "category",
                fromText: "From",
                toText: "To",
                oneh: "1h",
                twoh: "2h",
                threeh: "3h",
                timeSelectorHeading: "Time Selector",
                timeSelectorTimeframeSelect: "Select a timeframe",
                pickTime: "Enter a time (last hours)"
            },
            user: {
              signIn: 'Sign In',
              noStructures: 'No structures or machines assigned to the user.',
              noViews: 'No views for structure or machine assigned to user.',
              note: 'NOTE',
              editingDisabled:
                'Editing of the System Admin role is disabled by the system!',
              users: 'Users',
              usersPermissions: 'Users permissions',
              deactivateUsers: 'Deactivate Users',
              oeeOverview: 'OEE Overview',
              andonBoard: 'Andon Board',
              comparisonOverview: 'Comparison Overview',
              reportingOverview: 'Reporting Overview',
              scheduleOverview: 'Schedule Overview',
              machineOverview: 'Machine Overview',
              structureManagement: 'Structure Management',
              machineManagement: 'Machine Management',
              sensorManagement: 'Sensor Management',
              userManagement: 'User Management',
              view: 'View',
              structure: 'Structure',
              machine: 'Machine',
              homeview: 'Home view',
              units: {
                SPP: "Seconds per Part",
                MPP: "Minutes per Part",
                PPM: "Parts per Minute",
                PPH: "Parts per Hour",
                PPS: "Parts per Second",
                short_caps : {
                  SPP: "Seconds/Part",
                  MPP: "Minutes/Part",
                  PPM: "Parts/Minute",
                  PPH: "Parts/Hour",
                  PPS: "Parts/Second",
                },
                short: {
                  SPP: "s/part",
                  MPP: "min/part",
                  PPM: "parts/min",
                  PPH: "parts/h",
                  PPS: "parts/s",
                }
              }
            },
            structure: {
              averageRuntime: 'Avg. Machine Runtime',
              averageDowntime: 'Avg. Machine Downtime',
              machines: 'Machines',
              timespan: 'Timespan',
              productionPulse: 'Production Pulse',
              productionMachines: 'Production Machines',
              structureLoading: 'Structure loading...',
              runningCalculations: 'Running calculations...',
              deactivateStructures: 'Deactivate structures',
              selectStructure: 'Please select a structure',
              machinesRunning: 'machines running.',
              of: 'of',
            },
            performance: {
              slowRunning: 'Slow Running',
              idle: 'Idle',
              jobs: "Jobs",
              availability: 'Availability',
              performance: 'Performance',
              minPerformance: 'Min. Performance',
              maxPerformance: 'Max. Performance',
              qualityShort: 'QUA',
              performanceShort: 'PER',
              availabilityShort: 'AVA',
              quality: 'Quality',
              oee: 'OEE',
              parts: 'Parts',
              avgSign: 'Ø',
              partsPerMinute: 'Parts/Min',
              partsProduced: 'Parts produced',
              partsSurplus: 'Parts surplus',
              partsMissed: 'Parts missed',
              target: 'Target count',
              chartTargetCycleTime: 'Target Cycle Time',
              chartParts: 'Parts',
              chartPartsOf: 'of',
              chartPartsSec: 'Seconds',
              chartPartsProduced: 'Parts produced (Parts/Min)',
              chartPartsProducedSecPart: 'Parts produced (Sec/Part)',
              chartPartsProducedPartsHour: 'Parts produced (Parts/Hour)',
              chartPartsPartsHour: 'Parts/Hour',
              chartPartsTitle: 'Produced parts',
              chartPartsPartsMin: 'Parts/Min',
              chartPartsSecPart: 'Sec/Part',
              isLoadingParts: 'Loading parts...',
            },
            terminal: {
              productionCapacity: 'Production capacity',
              onlineStatus: 'Online Status',
              connected: 'Connected',
              notConnected: 'Not connected',
              internet: 'Internet',
              machineData: 'Machine Data',
              mongodbData: 'MongoDB Data',
              lfBackend: 'LF Backend',
              postgresData: 'Postgres Data',
              lastCheck: 'Last check',
            },
            reasons: {
              reasons: 'Reasons',
              noAssignedReasonsTimeframe: 'No reasons assigned in this timeframe.',
              deactivateReasons: 'Deactivate Reasons',
              categoriesPermissions: 'Categories permissions',
              notesPermissions: 'Notes permissions',
              reasonsPermissions: 'Reasons permissions',
            },
            sensor: {
              deactivateSensors: 'Deactivate Sensors',
              sensorsPermission: 'Sensors permissions',
              sensorTypesPermission: 'Sensor Types permissions',
              notConnected: 'Not connected',
              noSignal: 'No signal',
              signalStrength: 'Signal strength',
              rssiExcellent: 'Excellent',
              rssiGood: 'Good',
              rssiFair: 'Fair',
              rssiWeak: 'Weak',
              noMachine: 'No associated machine',
              lastSeenAt: 'Last seen at: ',
              online: 'Online: ',
              rssiAverage: 'RSSI (average 7 days)',
              version: 'Version: ',
              hardware: 'Hardware: ',
            },
            roles: {
              rolesPermission: 'Roles permissions',
            },
            schedule: {
              setup: 'Setup',
              create: 'Create',
              save: 'Save',
              createAndSave: 'Create and Save',
              saveAndDeploy: 'Save and deploy',
              createAndDeploy: 'Create and deploy',
              monday: "Monday",
              tuesday: "Tuesday",
              wednesday: "Wednesday",
              thursday: "Thursday",
              friday: "Friday",
              saturday: "Saturday",
              date: 'Date',
              today: 'TODAY',
              dayTemplatePermissions: 'Day Template permissions',
              weekTemplatePermissions: 'Week Template permissions',
              schedulePermissions: 'Schedule permissions',
              shiftsPermissions: 'Shifts permissions',
              activateSchedule: 'Activate schedule',
              schedule: 'Schedule',
              noEligibleSchedules: 'No eligible schedules for this machine.',
              changeActiveSchedule: 'Change active schedule',
              editSchedule: 'Edit schedule',
              editDay: 'Edit day',
              deactivateTemplates: 'Deactivate Templates',
              existingShifts: 'Existing Shifts',
              createShift: 'Create Shift',
              start: 'Start',
              end: 'End',
              scheduled: 'Scheduled',
              duration: 'Duration',
              preview: 'Preview',
              enabled: 'Enabled',
              shiftUpdated: 'Shift updated succesfully.',
              shifts: 'Shifts',
              editShift: 'Edit shift',
              selectedTime: 'Selected time of: ',
              invalidStart:
                ' is invalid as it conflicts with the start of the shift.',
              invalidEnd:
                ' is invalid as it conflicts with the end of the shift.',
              shiftStart: 'Shift start',
              shiftEnd: 'Shift end',
              manageSchedules: 'Manage Schedules',
              createSchedule: 'Create Schedule',
              existingWeekTemplates: 'Existing week templates',
              createWeekTemplate: 'Create week template',
              existingDayTemplates: 'Existing day templates',
              createDayTemplate: 'Create day templates',
              weekRemoved: 'Week removed successfully.',
              weekEdited: 'Week edited successfully.',
              dayEdited: 'Day edited successfully.',
              scheduleCreated: 'The schedule has been created successfully.',
              scheduleUpdated: 'The schedule has been updated successfully.',
              startDate: 'Start date',
              endDate: 'End date',
              addWeek: 'ADD WEEK',
              editWeek: 'Edit week',
              scheduleInfo: 'Schedule info',
              scheduleName: 'Name',
              scheduleDescription: 'Description:',
              lastDeployed: 'Last Deployed:',
              lastEdited: 'Last Edited:',
              goTo: 'Go to:',
              scheduleDeployed: 'The schedule has been successfully deployed.',
              deploymentCanceled: 'Deployment has been successfully canceled.',
              scheduleActivated:
                'The schedule has been successfully activated.',
              scheduleDuplicated:
                'The schedule has been successfully duplicated.',
              deployment: 'Deployment',
              activation: 'Activation',
              areYouSureDeploy: 'Are you sure you want to deploy ',
              areYouSureActivate: 'Are you sure you want to activate ',
              overwriteOnMachines:
                'This will overwrite any changes done on any of the machines listed below.',
              onTheseMachines: ' on these machines:',
              editCreatedSchedule:
                'Do you want to edit newly created schedule?',
              areYouSureDelete: 'Are you sure you want to delete ',
              areYouSureDuplicate: 'Are you sure you want to duplicate ',
              areYouSureCancel: 'Are you sure you want to cancel deployment for ',
              scheduleDeleted: 'The schedule has been deleted succesfully.',
              schedules: 'Schedules',
              areYouSure: 'Are you sure?',
              editNewSchedule: 'Edit new schedule',
              doItLater: 'I will do it later',
              deployingSchedule: 'Deploying schedule: ',
              cancelingDeployment: 'Canceling deployment of schedule: ',
              takeWhile: 'It may take a while depending on number of machines.',
              activatingSchedule: 'Activating schedule: ',
              addRemoveMachines: 'Add/Remove machines',
              editMasterTemplate: 'Edit master template',
              editDeployedSchedule: 'Edit deployed schedule',
              editNewWeekTemplate:
                'Do you want to edit the newly created week template?',
              deployingYourSchedule: 'Deploying your schedule...',
              editNewTemplate: 'Edit new template',
              scheduleCantBeEdited: 'The Schedule cannot be edited before',
              selectedMachines: 'Selected machines: ',
              weekTemplateDuplicated:
                'The week template has been successfully duplicated.',
              addDayTemplate: 'Add day template',
              scheduleTemplateUpdated:
                'The schedule template updated succesfully.',
              weekTemplates: 'Week templates',
              editWeekTemplate: 'Edit week template',
              weekTemplateCreated:
                'The week template has been created successfully.',
              weekTemplateUpdated:
                'The week template has been updated successfully.',
              clearDay: 'Clear day',
              templateNameRequired:
                'The template name is required. Cannot be left empty.',
              templateEvents: 'Template events cannot be left empty.',
              templateTimeframe:
                'Template timeframe has to be filled completely.',
              scheduleNameRequired:
                'The schedule name is required. Cannot be left empty.',
              scheduleToMachine:
                'The schedule has to be connected to at least one machine.',
              startDateCantBeInThePast:
                'Start Date must not be in the past.',
              endDateCantBeBeforeStartDate:
                'End Date must not be before start date.',
              atLeastOneWeekTemplate: 'Schedule must have at least one week template added.',
              dayTemplateDuplicated:
                'The day template has been successfully duplicated.',
              dayTemplates: 'Day Templates',
              editTemplate: 'Edit template',
              dayTemplateCreated:
                'The day template has been successfully created.',
              dayTemplateUpdated:
                'The day template has been successfully updated.',
              duplicate: 'Duplicate',
              edit: 'Edit',
              view: 'View',
              delete: 'Delete',
              hasExpired: 'Has Expired',
              hasNotExpired: 'Has Not Expired',
              all: 'All',
              expiration: 'Expiration',
              cancelDeployment: 'Cancel Deployment',
              deploy: 'Deploy',
              activateOn: 'Activate on:',
              activationScheduledSuccessfully: 'Activation has been scheduled successfully.',
              activationCanceledSuccessfully: 'Scheduled activation has been canceled successfully.',
              isDueForActivationOn: 'is due for activation on',
              selectAllMachines: 'SELECT ALL MACHINES',
              remove: "REMOVE",
            },
            quality: {
              scrapCountTextfield: 'Amount of scrap',
              quality: 'Quality',
              easyScrap: 'Easy Scrap',
              easyScrapButton: 'Easy Scrap',
              existingScrapTypes: 'Existing Scrap Types',
              createNewScrapType: 'Create a new scrap type',
              scrapTypes: 'Scrap types',
              selectScrapType: 'Select Scrap Type',
              easyScrapNote: 'Added by Easy Scrap',
              easyScrapDescription: 'Easy Scrap',
              scrapType: 'Scrap type',
              minQuality: 'Min. Quality',
              maxQuality: 'Max. Quality',
              count: 'Count',
              qualityReportAdded:
                'A new quality report has been added successfully.',
              qualityReportDeleted: 'The quality report has been deleted successfully',
              qualityReport: 'Quality report',
              alreadyAdded:
                ' already added. Please click on edit above to change.',
              productionTimePeriod: 'Production time period',
              wasteCount: 'Waste count',
              totalWasteCount: 'Total Waste Count: ',
              scrapTypeRequired: 'Scrap type required',
              endTimeRequired: 'End time required',
              startTimeRequired: 'Start time required',
              countRequired: 'Count required. Cannot be 0 or left blank.',
              invalidScrapNumber: 'Invalid amount of scrap, cannot be negative',
              tooMuchScrap: 'Invalid amount of scrap, amount too big',
              actualCountInvalid: 'Sensor count not valid, cannot enter scrap',
              typeNameRequired:
                'Scrap Type name is required. Cannot be left empty.',
              typeIdentifierRequired:
                'Scrap Type identifier is required. Cannot be left empty.',
              categoryRequired: 'Category is required. Cannot be left empty.',
              originRequired: 'Origin is required. Cannot be left empty.',
              scrapTypesPermissions: 'Scrap Types Permissions',
              parts: 'Parts',
              noQualityReportSubmitted: 'No quality reports submitted in the selected timeframe.',
              qualityLosses: 'Quality Losses',
              qualityReports: 'Quality Reports',
              deleteQualityReportModalTitle: 'Delete quality report?',
              areYouSureDeleteQualityReport: 'Are you sure you want to delete this quality report?',
              noQualityReportsSubmitted: 'No quality reports submitted in the selected timeframe.',
            },
            orders: {
              start_working_order: 'Start Working Order',
              stop_working_order: 'Finish Working Order',
              average: 'average',
              areYouSureDeleteJob: "Delete Job?",
              areYouSureDeleteJobText: "Are you sure that you want to delete the following job?",              
              orders: "Orders",
              edit: "Edit",
              endDate: "End date",
              delete: "Delete",
              pastActiveJobs: "Past active Jobs",
              noPastActiveJobsInTimeframe: 'No past active jobs in selected timeframe',
              ordersPermissions: 'Orders Permissions',
              progress: "Progress (%)",
              speedJob: "Speed",
              jobsPermissions: 'Jobs Permissions ',
              productsPermissions: 'Products Permissions',
              activeJobs: 'Active Job',
              jobId: 'Job ID',
              orderId: 'Order ID',
              status: 'Status',
              plannedStartDate: 'Planned start date',
              actualStartDate: 'Actual start date',
              target: 'Target',
              sensorCount: 'Sensor count',
              goodCount: 'Good parts',
              scrapCount: 'Scrap',
              handCount: 'Hand count',
              machine: 'Machine',
              structureField: 'structure',
              intendedMachineField: 'intendedMachine',
              intendedMachine: 'Intended Machine',
              structure: 'Structure',
              intended: 'Intended',
              operator: 'Operator',
              startTime: 'Start time',
              endTime: 'End time',
              duration: 'Duration',
              uploadCSV: 'Upload CSV',
              chooseFileToUpload: 'Choose file to upload',
              chooseFile: 'Choose file',
              close: 'Close',
              upload: 'Upload',
              uploading: 'Uploading',
              uploadAnotherFile: 'Upload another file',
              jobsCreatedSuccessfully: 'jobs created successfully',
              backToUploadFile: 'Back to upload file',
              fileHasBeenUploaded: 'File has been uploaded.',
              uploadAgainWithCorrections:
                'However same file needs to be uploaded again with following corrections:',
              row: 'Row',
              backToJobsList: 'Back to jobs list',
              product: 'Product',
              description: 'Description',
              jobStatus: 'Job Status',
              targetCount: 'Target Count',
              targetCycleTime: 'Target cycle time',
              targetSetupTime: 'Target setup time',
              engagements: 'Activations',
              operatorNotes: 'Operator notes',
              loadingJob: 'Loading job...',
              existingNotes: 'Existing notes',
              enterNoteText: 'Enter note text',
              addNote: 'Add Note',
              clearScreen: 'Clear Screen',
              start: 'Start Recording',
              stop: 'Stop Recording',
              setJobStatus: 'Set the job status.',
              ACTIVE: 'ACTIVE',
              OPEN: 'OPEN',
              PAUSED: 'PAUSED',
              COMPLETED: 'COMPLETED',
              setJobStatusPaused: 'Set job status to paused.',
              setJobStatusCompleted: 'Set job status to completed.',
              currentlyEngaged: 'Currently activated',
              jobTotalCount: 'Job Total Count',
              speed: 'Speed',
              actual: 'Actual',
              setup: 'Setup',
              warning: 'Warning',
              areYouSureOverwriteHandcount:
                'Are you sure you want to overwrite the current handcount?',
              byClickingSubmit: 'By clicking submit, the current handcount:',
              willBeDeleted: 'will be deleted.',
              yes: 'Yes',
              no: 'No',
              STARTED: 'STARTED',
              ENDED: 'ENDED',
              intendedTable: 'intended',
              unintendedTable: 'unintended',
              fulltime: 'Full Time',
              timeframe: 'Timeframe',
              pastTwoWeeks: 'Past 2 Weeks',
              pastMonth: 'Past Month',
              pastSixMonths: 'Past 6 Months',
              pastYear: 'Past Year',
              jobProgressOverview: 'Job Progress Overview',
              jobProgress: 'Job Progress',
              existingJobs: 'Existing Jobs',
              createNewJob: "Create New Job",
              activationOverview: 'Activation Overview',
              addedHandCount: 'Added Hand count',
              removeAssignedJob: 'Clear Assignment',
              assignJob: 'Assign',
              startHandCount: "Start Count",
              endHandCount: "End Count",
              handCountCalculator: "Hand Count Calculator",
              apply: "Apply",
              notAvailable: 'Job is not available: ',
            },
            machinePerformance: {
              machinePerformance: 'Machine Performance',
              theoreticalOutput: 'Theoretical Maximum Output',
              workingOutput: 'Scheduled Maximum Output',
              scheduledOutput: 'After Schedule Loss',
              downtimeLoss: 'After Downtime Loss',
              speedLoss: 'After Speed Loss',
              qualityLoss: 'After Quality Loss',
              theoreticalOutputDesc: 'Theoretical maximum of produced parts when a machine runs 24/7',
              workingOutputDesc: 'Theoretical produced parts according to the schedule plan',
              scheduledOutputDesc: 'Theoretical produced parts according to the schedule plan without planned breaks',
              downtimeLossDesc: 'Theoretical maximum produced parts while machine was running',
              speedLossDesc: 'Parts produced',
              qualityLossDesc: 'Good parts',
              performanceLoading: 'Loading machine performance...',
              unit: 'Parts',
              unitMaxCycleTime: 'Parts/min',
              totalTime: 'Total Time',
              maxCycleTime: 'Max. parts per minute',
              noPerformance: 'No performance data available',
              workingTime: 'Planned working time',
              plannedReasons: 'Total planned reasons time',
              plannedReasonsHeading: 'Planned Downtimes',
              runningTime: 'Total running time',
              runningStates: 'Number of running states',
              avgPartsPerMinute: 'Average parts per minute',
              avgScrapPerMinute: 'Average scrap per minute',
              total: 'Total',
              totalDowntimeLoss: 'Total downtime loss',
              delta: 'Delta',
              reasonsHeading: 'Unplanned Downtimes',
              qualityLossesHeading: 'Quality Reports',
              backToMachineView: 'Back to Machine View',
              scheduledWorkingTime: 'Scheduled Working Time',
              overtime: 'Overtime',
              plannedRunningTime: "Planned Runtime"
            }
          },

          forms: {
            reloadInterval: {
              success: "Reload interval updated successfully",
              failure: "Updating reload interval failed",
            },

            jobs: {
              product: "Product",
              targetCycleTime: "Target Cycle Time",
              targetSetupTime: "Target Setup Time",
              targetQuantity: "Target Quantity",
              description: "Description",
              intendedMachine: "Intended Machine",
              scanCode: "Scan Code",
              handCount: "Hand Count",
              completionStatus: "Completion Status",
              businessJobId: "Business ID",
              modalTitle: "Edit Job",
              jobEditSuccess: "Job saved successfully",
              jobEditFailure: "Saving Job failed",
              jobDeleteSuccess: "Deleted Job successfully",
              jobDeleteFailure: "Deleting Job failed",
              validate: {
                handCountRequired: "Hand Count number is required",
                descriptionRequired: "Job Description is required",
                completionStatusRequired: "A Job Completion status is required",
                intendedMachineRequired: "A machine is required",
                targetQuantityRequired: "A target quantity number is required",
                targetSetupTimeRequired: "A target setup time value is required",
                targetCycleTimeRequired: "A target cycle time value is required",
                productRequired: "A product selection is required"
              }

            },
            machine: {
              fields: {
                placeNumber: 'Place Number',
                manufacturer: 'Manufacturer',
                machineConfiguration: 'Machine Configuration',
                newMachineConfiguration: "New Machine Configuration",
                configurationName: 'Configuration Name',
                configurationValue: 'Configuration Value',
                selectConfigurationValue: "Custom Configuration Value",
              },
              configLabels: {
                defaultActivationStatus: "Default Activation Status",
                targetCycleTime:"Target Cycle Time",
                maxCycleTime: "Max Cycle Time",
                partsChartSimple: "Allow Simple Parts Chart View",
                partsChart: "Allow Parts Chart View",
                speedLow: "Minimum Machine Speed",
                speedMedium: "Medium Machine Speed",
                speedMax: "Maximum Machine Speed",
                handCountStartEnd: "Allow Hand Count Start/End",
                jobBasedTerminal: "Job Based Terminal",
              },
              configUnits: {
                defaultActivationStatus: "ACTIVE/ENDED",
                targetCycleTime: "seconds/part",
                maxCycleTime: "seconds/part",
                partsChartSimple: "true/false",
                partsChart: "true/false",
                speedLow: "seconds/part",
                speedMedium: "seconds/part",
                speedMax: "seconds/part",
                handCountStartEnd: "true/false",
                jobBasedTerminal: "true/false",
              },
              buttons: {
                removeField: 'Remove Field',
                addField: 'Add Field',
                addCustomField: 'Add Custom Field',
              },

              errors: {
                machineNameRequired:
                  'The machine name is required. Cannot be left empty.',
                structureRequired:
                  'The structure is required. Cannot be left empty.',
                machineTypeRequired:
                  'Machine type is required. Cannot be left empty.',
                nameCannotBeBlank: 'Name is required. Cannot be left empty.',
                genericError: 'Something went wrong. Please try again later.',
                sensorPerMachine:
                  'Current version only supports one sensor per machine.',
                machineStatusName:
                  'The machine status name is required. Cannot be left empty.',
                machineStatusActivity:
                  'The machine status activity is required. Cannot be left empty.',
                machineStatusColor: 'Color is required. Cannot be left empty.',
                machineStatusCategory:
                  'Category is required. Cannot be left empty.',
                machineStatusDataLayer:
                  'Data layer is required. Cannot be left empty.',
              },

              success: {
                machineAdded: 'A new machine has been added successfully.',
                machineUpdated: 'The machine has been updated successfully.',
                machineTypeAdded:
                  'A new machine type has been added successfully.',
                machineTypeUpdated:
                  'The machine type has been updated successfully.',
                machineTypeDeleted:
                  'The machine type was deleted successfully.',
                machineConfigSent:
                  'The machine config has been sent successfully.',
              },
            },

            sensor: {
              fields: {
                sensorID: 'Sensor ID',
                measurementUnit: 'Measurement Unit',
              },

              errors: {
                sensorIdRequired:
                  'The sensor ID is required. Cannot be left empty.',
                aliasRequired:
                  'Sensor alias is required. Cannot be left empty.',
                sensorTypeRequired:
                  'The sensor type is required. Cannot be left empty.',
                isActiveUndefined:
                  'Active toggle is required. Cannot be left undefined.',
                genericError: 'Something went wrong. Please try again later.',
                sensorNameRequired:
                  'Sensor name is required. Cannot be left blank.',
                measurementUnitRequired:
                  'Measurement unit is required. Cannot be left blank',
              },

              success: {
                sensorAdded: 'A new sensor has been added successfully.',
                sensorUpdated: 'The sensor has been updated successfully.',
                sensorDeleted: 'The sensor has been deleted successfully.',
                sensorTypeAdded:
                  'A new sensor type has been added successfully.',
                sensorTypeUpdated:
                  'The sensor type has been updated successfully.',
                sensorTypeDeleted: 'Sensor type deleted successfully.',
              },
            },

            structure: {
              fields: {
                country: 'Country',
                parentStructure: 'Parent Structure',
              },

              errors: {
                notAuthorized: 'You are not authorized to view this structure.',
                structureDoesntExist:
                  'The structure you are trying to access does not exist.',
                structureNameRequired:
                  'The structure name is required. Cannot be left blank.',
                structureCityRequired:
                  'The city is required. Cannot be left blank.',
                structureCountryRequired:
                  'The country is required. Cannot be left blank.',
                structureTimezoneRequired:
                  'The structure timezone is required. Cannot be left blank.',
                genericError: 'Something went wrong. Please try again later.',
              },

              success: {
                structureAdded: 'A new structure has been added successfully.',
                structureUpdated:
                  'The structure has been updated successfully.',
                structureDeleted:
                  'The structure has been deleted successfully.',
              },
            },

            user: {
              fields: {
                machinesPerPage: 'Machines per page',
                unit: 'Unit',
                email: 'Email',
                password: 'Password',
                businessUserId: 'Business User ID',
                fullName: 'Full Name',
                confirmPassword: 'Confirm Password',
                username: 'Username',
                pin: 'PIN',
                confirmPin: 'Confirm PIN',
                language: 'Language',
                role: 'Role',
                defaultView: 'Default View',
              },

              errors: {
                otherError:
                  'Something went wrong. Please try again later.',
                invalidEmailFormat:
                  'Invalid email format. Should be user@site.com',
                roleNameRequired: 'The name is required. Cannot be left blank.',
                fullNameRequired:
                  'Full name is required. Cannot be left blank.',
                emailRequired: 'Email is required. Cannot be left blank.',
                passwordRequired:
                  'The password is required. Cannot be left blank.',
                passwordMismatch:
                  'The passwords do not match. Please try again.',
                passwordLength:
                  'The password must be at least 8 characters long.',
                businessidInUse:
                  'The provided business user ID is already in use.',
                languageRequired:
                  'The language is required. Cannot be left blank.',
                roleRequired: 'The role is required. Cannot be left blank.',
                structuresRequired:
                  'The structures are required. Cannot be left blank.',
                structureViewRequired:
                  'The structure view is required. Cannot be left blank.',
                structureRequired:
                  'A structure is required. Cannot be left blank.',
                machineViewRequired:
                  'The machine view is required. Cannot be left blank.',
                machineRequired: 'A machine is required. Cannot be left blank.',
                homeViewRequired:
                  'The home view is required. Cannot be left blank.',
                genericError: 'Something went wrong. Please try again later.',
                adminCannotBeDeleted: 'System Admin role cannot be deleted!',
                userAlreadyExists: 'A user with that email already exists.',
                pinRequired: 'The PIN is required. Cannot be left blank.',
                pinMismatch: 'The PIN numbers do not match. Please try again.',
              },

              success: {
                roleAdded: 'A new role has been added successfully.',
                roleUpdated: 'The role has been updated successfully.',
                roleDeleted: 'The role has been deleted successfully.',
                userAdded: 'A new user has been added successfully.',
                userUpdated: 'The user has been updated successfully.',
                userDeleted: 'The user has been deleted successfully.',
              },
            },

            reason: {
              fields: {
                name: 'Name',
                identifier: 'Identifier',
                description: 'Description',
                color: 'Color',
                category: 'Category',
                machineType: 'Machine Type',
                roles: 'Roles',
                origin: 'Origin',
                reasonConfiguration: 'Reason Configuration',
              },

              misc: {
                staticReasonActive: "Static Reason is active!",
                machineStatus: 'Machine Status',
                reason: 'Reason',
                note: 'Note',
                assignStaticReason: 'Assign Static Reason',
                assignReason: 'Assign Reason',
                changeReason: 'Change Reason',
                editReason: 'Edit Reason',
                editStaticReason: 'Edit Static Reason',
                removeReason: 'Remove Reason',
                loadingReasons: 'Loading reasons...',
              },

              errors: {
                reasonNameRequired:
                  'The reason name is required. Cannot be left empty.',
                reasonIdentifierRequired:
                  'The reason identifier is required. Cannot be left empty.',
                reasonColorRequired: 'Color is required. Cannot be left empty.',
                reasonCategoryRequired:
                  'Category is required. Cannot be left empty.',
                reasonOriginRequired:
                  'Origin is required. Cannot be left empty.',
                reasonRequired: 'The reason is required. Cannot be left empty.',
              },

              success: {
                staticReasonAdded: 'A new static reason has been set for this machine.',
                staticReasonDeleted: 'The static reason for this machine has been deleted.',
                reasonAdded: 'A new reason has been added successfully.',
                reasonUpdated: 'The reason has been updated successfully.',
                reasonDeleted: 'The reason has been deleted successfully.',
              },
            },

            quality: {
              errors: {
                qualityIdentifierRequired:
                  'The quality identifier is required. Cannot be left empty.',
                qualityNameRequired:
                  'The reason name is required. Cannot be left empty.',
                reasonIdentifierRequired:
                  'The reason identifier is required. Cannot be left empty.',
                reasonColorRequired: 'Color is required. Cannot be left empty.',
                reasonCategoryRequired:
                  'Category is required. Cannot be left empty.',
                reasonOriginRequired:
                  'Origin is required. Cannot be left empty.',
                reasonRequired: 'The reason is required. Cannot be left empty.',
              },
            },

            terminal: {
              fields: {
                username: 'Username',
                pin: 'PIN',
                logout: 'Logout',
              },

              errors: {
                terminalUsernameLength:
                  'The username should be at least 3 characters long.',
                terminalPinLength:
                  'Invalid PIN length. The PIN should be 4 numbers long.',
                terminalInvalidPinFormat:
                  'Invalid PIN format. The PIN can only contain numbers.',
              },
            },

            machineStatus: {
              fields: {
                name: 'Name',
                activity: 'Activity',
                color: 'Color',
                category: 'Category',
                dataLayer: 'Data Layer',
                origin: 'Origin',
                machineStatusConfiguration: 'Machine Status Configuration',
                identifier: 'Identifier',
                machineType: 'Machine Type',
              },

              errors: {
                statusNameRequired:
                  'The name is required. Cannot be left blank.',
              },

              success: {
                machineStatusAdded:
                  'A new machine status has been added successfully.',
                machineStatusUpdated:
                  'The machine status has been updated successfully.',
                machineStatusDeleted:
                  'The machine status has been deleted successfully.',
              },
            },

            category: {
              errors: {
                categoryNameRequired:
                  'The name is required. Cannot be left blank.',
              },

              success: {
                categoryAdded: 'A new category has been added successfully.',
                categoryUpdated: 'The category has been updated successfully.',
                categoryDeleted: 'The category has been deleted successfully.',
              },
            },

            shared: {
              fields: {
                name: 'Name',
                businessID: 'Business ID',
                description: 'Description',
                city: 'City',
                inactive: 'Inactive',
                active: 'Active',
                disabled: 'Disabled',
                enabled: 'Enabled',
                timezone: 'Timezone',
                none: 'None',
                alias: 'Alias',
                type: 'Type',
                config: 'Configuration',
                configSelect: "Select Configuration",
                structure: 'Structure',
                structures: 'Structures',
                sensors: 'Sensors',
                sortIndex: 'Sort Index',
                startTime: 'Start Time',
                endTime: 'End Time',
                duration: 'Duration',
                jobId: 'Job ID',
                partsProduced: 'Parts Produced',
                targetCount: 'Target Count',
                realisticCycleTime: 'Realistic Cycle Time',
                targetSetupTime: 'Target Setup Time',
                actualCount: 'Actual Count',
                reason: 'Reason',
                status: 'Status',

              },

              buttons: {
                merge: 'Merge',
                reset: 'Reset',
                submit: 'Submit',
                save: 'Save',
                clear: 'Clear',
                close: 'Close',
                cancel: 'Cancel',
                updatePulse: 'Update Pulse',
                goBack: 'GO BACK',
                activate: 'Activate',
                back: 'Back',
                next: 'Next',
                saveChanges: 'SAVE CHANGES',
                add: 'Add',
                remove: 'Remove',
                edit: 'Edit',
              },
            },
          },

          tables: {
            shared: {
              active: 'Active',
              inactive: 'Inactive',
              assigned: 'Assigned',
              unassigned: 'Unassigned',
              machine: 'Machine',
              machines: 'Machines',
              actions: 'Actions',
              machineInformation: 'Machine Information',
              categories: 'Categories',
              reasons: 'Reasons',
              machineStatuses: 'Machine statuses',
              product: 'Product'
            },
            noData: "Sorry, there is no matching data to display.",
            sensor: {
              registrationDate: 'Registration Date',
              lastSeen: 'Last Seen',
            },
            textLabels: {
              body: {
                noMatch: "Sorry, no matching records found",
                noData: "Sorry, no data to display yet",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
              },
              pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
              },
              toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
              },
              filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
              },
              viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
              },
              selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
              },
            }
          },
          confirmationModal: {
            textDeleteStaticReason: "Are you sure you want to stop the static reason for machine ",
            deactivateMachines: 'Deactivate machines',
            areYouSureDeactivateMachines:
              'Are you sure you want to deactivate these machines',
            areYouSureDeactivateMachine:
              'Are you sure you want to deactivate this machine',
            confirm: 'Confirm',
            cancel: 'Cancel',
            deactivateUsers: 'Deactivate users',
            areYouSureDeactivateUsers:
              'Are you sure you want to deactivate these users',
            areYouSureDeactivateUser:
              'Are you sure you want to deactivate this user',
            deactivateStructures: 'Deactivate structures',
            areYouSureDeactivateStructures:
              'Are you sure you want to deactivate these structures',
            areYouSureDeactivateStructure:
              'Are you sure you want to deactivate this structure',
            deactivateSensors: 'Deactivate sensors',
            areYouSureDeactivateSensors:
              'Are you sure you want to deactivate these sensors',
            areYouSureDeactivateSensor:
              'Are you sure you want to deactivate this sensor',
            deactivateReasons: 'Deactivate reasons',
            areYouSureDeactivateReasons:
              'Are you sure you want to deactivate these reasons',
            areYouSureDeactivateReason:
              'Are you sure you want to deactivate this reason',
          },

          machineStatusesText: {
            info: "Info",
            machineStatusColorHeading: "State Color",
            machineStatusNameHeading: "Title",
            machineReasonNameHeading: "Reason Title",
            machineReasonColorHeading: "Reason Color",
            machineStatusDescriptionHeading: "Description",
            running: 'Running',
            scheduled: 'Producing',
            slow_running: 'Slow Running',
            not_running: 'Not Running',
            unscheduled: 'Schedule Loss',
            creep: 'Break Creep',
            unscheduled_PO_presence: 'Vacant',
            not_running_PO_presence: 'Vacant',
            running_PO_presence: 'Occupied',
            running_PO_EOL: 'Test is running',
            waiting_PO_EOL: 'Waiting for part',
            test_done_PO_EOL: 'Test done',
            not_running_PO_EOL: 'Not Running',
            unscheduled_PO_EOL: 'Not Running',
            running_PO_rework: 'Productive',
            occupied_PO_rework: 'Occupied',
            not_running_PO_rework: 'Vacant',
            unscheduled_PO_rework: 'Vacant',
            maintenance: "Maintenance",
            descriptions: {
              machineStatusColorHeading: "Lorem Ipsum",
              machineStatusNameHeading: "Lorem Ipsum",
              machineStatusDescriptionHeading: "Lorem Ipsum",
              running: 'Lorem Ipsum',
              scheduled: 'Lorem Ipsum',
              slow_running: 'Lorem Ipsum',
              not_running: 'Lorem Ipsum',
              unscheduled: 'Lorem Ipsum',
              creep: 'Lorem Ipsum',
              unscheduled_PO_presence: 'Lorem Ipsum',
              not_running_PO_presence: 'Lorem Ipsum',
              running_PO_presence: 'Lorem Ipsum',
              running_PO_EOL: 'Lorem Ipsum',
              waiting_PO_EOL: 'Lorem Ipsum',
              test_done_PO_EOL: 'Lorem Ipsum',
              not_running_PO_EOL: 'Lorem Ipsum',
              unscheduled_PO_EOL: 'Lorem Ipsum',
              running_PO_rework: 'Lorem Ipsum',
              occupied_PO_rework: 'Lorem Ipsum',
              not_running_PO_rework: 'Lorem Ipsum',
              unscheduled_PO_rework: 'Lorem Ipsum',
              maintenance: 'Lorem Ipsum',
            }
          },

          reasonsText: {
            quality: 'Quality Testing',
            machine_malfunction: 'Machine Malfunction',
            tool_change: 'Tool Change (Wear)',
            setup: 'Setup',
            tool_breakage: 'Tool Breakage',
            defective_purchase_part: 'Defective Purchase Part',
            lack_of_personnel: 'Lack of Personnel',
            other_break: 'Other Break',
            repair_pneumatics: 'Repair Pneumatics',
            coil_change: 'Coil Change / Material Change',
            defective_material: 'Defective Material',
            material_lack: 'Lack of / Defective Material',
            repair_hydraulics: 'Repair Hydraulics',
            unavailable: 'Unavailable',
            cleaning: 'Cleaning / Maintenance',
            unscheduled: 'Unscheduled',
            repair_mechanics: 'Repair Mechanics',
            no_qc_approval: 'Waiting on qc approval',
            follow_up_process: 'Follow-up Process Unavailable',
            quality_issue: 'Quality issue',
            welder_adjustments: 'Welder Adjustments',
            repair_electrical: 'Repair Electrical',
            preventive_maintenance: 'Preventive Maintenance',
            break: 'Break',
            machine_adjustments: 'Machine Adjustments',
            missing_order: 'Missing Order',
            repair_category: 'Repair',
            other_reason: 'Other (e.g. meeting, power failure etc.)',
            601: '601 - Drucker',
            602: '602 - Elektrode',
            603: '603 - Flanschelektrode',
            604: '604 - Platine',
            605: '605 - Längsschweißung',
            606: '606 - Querschweißung',
            607: '607 - Teller',
            608: '608 - Töpfe',
            609: '609 - Spender',
            610: '610 - Lichtschranke',
            611: '611 - Steuerung',
            612: '612 - Spreizung',
            613: '613 - Greifer',
            614: '614 - Glättung',
            615: '615 - Paddel',
            616: '616 - Säule',
            617: '617 - Sockel',
            618: '618 - Entnahme',
            619: '619 - Transport',
            620: '620 - Sicherheit',
            621: '621 - Stempel',
            622: '622 - Farbband',
            623: '623 - allgemein',
            624: '624 - Druckluft',
            401: '401 - Drucker',
            402: '402 - Elektrode',
            403: '403 - Flanschelektrode',
            404: '404 - Platine',
            405: '405 - Längsschweißung',
            406: '406 - Querschweißung',
            407: '407 - Teller',
            408: '408 - Töpfe',
            409: '409 - Spender',
            410: '410 - Lichtschranke',
            411: '411 - Steuerung',
            412: '412 - Spreizung',
            413: '413 - Greifer',
            414: '414 - Glättung',
            415: '415 - Paddel',
            416: '416 - Säule',
            417: '417 - Sockel',
            418: '418 - Entnahme',
            419: '419 - Transport',
            420: '420 - Stempel',
            421: '421 - Farbband',
            422: '422 - allgemein',
            423: '423 - Druckluft',
            301: '301 - Drucker',
            302: '302 - Elektrode',
            303: '303 - Flanschelektrode',
            304: '304 - Platine',
            305: '305 - Längsschweißung',
            306: '306 - Querschweißung',
            307: '307 - Teller',
            308: '308 - Töpfe',
            309: '309 - Spender',
            310: '310 - Lichtschranke',
            311: '311 - Steuerung',
            312: '312 - Spreizung',
            313: '313 - Greifer',
            314: '314 - Glättung',
            315: '315 - Paddel',
            316: '316 - Säule',
            317: '317 - Sockel',
            318: '318 - Entnahme',
            319: '319 - Transport',
            320: '320 - Sicherheit',
            321: '321 - Stempel',
            322: '322 - Farbband',
            323: '323 - allgemein',
            324: '324 - Druckluft',
            201: '201 - Drucker',
            202: '202 - Elektrode',
            203: '203 - Flanschelektrode',
            204: '204 - Platine',
            205: '205 - Längsschweißung',
            206: '206 - Querschweißung',
            207: '207 - Teller',
            208: '208 - Töpfe',
            209: '209 - Spender',
            210: '210 - Lichtschranke',
            211: '211 - Spreizung',
            212: '212 - Greifer',
            213: '213 - Glättung',
            214: '214 - Paddel',
            215: '215 - Säule',
            216: '216 - Sockel',
            217: '217 - Entnahme',
            218: '218 - Transport',
            219: '219 - Sicherheit',
            220: '220 - Stempel',
            221: '221 - Farbband',
            222: '222 - allgemein',
            223: '223 - Druckluft',
            101: '101 - Drucker',
            102: '102 - Elektrode',
            103: '103 - Flanschelektrode',
            104: '104 - Längsschweißung',
            105: '105 - Querschweißung',
            106: '106 - Spender',
            107: '107 - Lichtschranke',
            108: '108 - Steuerung',
            109: '109 - Spreizung',
            110: '110 - Greifer',
            111: '111 - Glättung',
            112: '112 - Paddel',
            113: '113 - Säule',
            114: '114 - Sockel',
            115: '115 - Entnahme',
            116: '116 - Transport',
            117: '117 - Sicherheit',
            not_defined: 'Undefined',
            break_creep: 'Break Creep',
            not_defined_PO_presence: 'Undefined',
            break_creep_PO_presence: 'Break Creep',
            micro_stop: 'Micro Stop'
          },

          scrapTypesText: {
            coil_change: 'Coil Change',
            defective_material: 'Defective material',
            setup: 'Setup',
            quality_testing: 'Quality testing',
          },
        };

      case 'DE':
      case 'de':
        return {
          nav: {
            favorites: 'Favoriten',
            profile: 'Profil',
            settings: 'Einstellungen',
            logout: 'Ausloggen',
            collapseSidebar: 'Seitenleiste einklappen',
            home: 'Home',
            structures: 'Strukturen',
            machines: 'Maschinen',
            sensors: 'Sensoren',
            users: 'Benutzer',
            manageStructures: 'Strukturen',
            manageMachines: 'Maschinen',
            roles: 'Rollen',
            sensorTypes: 'Sensortypen',
            machineTypes: 'Maschinentypen',
            structureOverview: 'Strukturübersicht',
            machineOverview: 'Maschinenübersicht',
            reasons: 'Stillstandsgründe',
            reasonsManagement: 'Stillstandsgründe',
            categoriesManagement: 'Kategorien',
            machineStatusesManagement: 'Maschinenstatus',
            terminal: 'Terminal',
            oee: 'OEE',
            report: 'Bericht',
            advanced_report: 'E-Bericht',
            schedule: 'Zeitplan',
            andon: 'Andon',
            views: 'Ansichten',
            management: 'Management',
            orders: 'Aufträge',
            orders_export_validation: "Prüfung",
            supportTicket: 'Unterstüzungsticket',
            supportPage: 'Hilfe Website',
            help: 'Hilfe',
            machineNaming: 'Anlagen Benamung',
            machinePerformance: 'Leistungsübersicht',
            jobs: "Aufträge",
            reload: "Aktualisierung",
            instant: "Direkt",
            reloadTimes: {
              "0": "Direkt",
              "10": "Schnell",
              "60": "Medium",
              "300": "Langsam",
              "3600": "Stündlich",
            }
          },

          machineFilters: {
            default: 'default',
            availabilityminmax: 'verfuegbarkeit-min-max',
            availabilitymaxmin: 'verfuegbarkeit-max-min',
            performanceminmax: 'leistung-min-max',
            performancemaxmin: 'leistung-max-min',
            qualityminmax: 'qualitaet-min-max',
            qualitymaxmin: 'qualitaet-max-min',
          },

          pageTitles: {
            sensorsManagement: 'Sensormanagement',
            sensorTypesManagement: 'Sensortypen verwalten',
            usersManagement: 'Benutzer verwalten',
            rolesManagement: 'Rollen verwalten',
            machinesMangement: 'Maschinen verwalten',
            machineTypesManagement: 'Maschinentypen verwalten',
            structuresManagement: 'Strukturen verwalten',
            signIn: 'Einloggen',
            help: 'Hilfe',
          },

          tabs: {
            CreateStructure: 'Struktur erstellen',
            ExistingStructures: 'Existierende Strukturen',
            CreateMachine: 'Maschine erstellen',
            ExistingMachines: 'Existierende Maschinen',
            CreateSensor: 'Sensor erstellen',
            ExistingSensors: 'Existierende Sensoren',
            CreateUser: 'Benutzer erstellen',
            ExistingUsers: 'Existierende Benutzer',
            CreateRole: 'Benutzerrolle erstellen',
            ExistingRoles: 'Existierende Benutzerrollen',
            CreateSensorType: 'Sensortyp erstellen',
            ExistingSensorTypes: 'Existierende Sensortypen',
            CreateMachineType: 'Maschinentyp erstellen',
            ExistingMachineTypes: 'Existierende Maschinentypen',
            CreateReason: 'Stillstandsgrund erstellen',
            ExistingReasons: 'Existierende Stillstandsgründe',
            CreateCategory: 'Kategorie erstellen',
            ExistingCategories: 'Existierende Kategorien',
            CreateMachineStatus: 'Maschinenstatus erstellen',
            ExistingMachineStatuses: 'Existierende Maschinenstatus',
            UserLogin: 'Benutzeranmeldung',
            AppLogin: 'Login',
            TerminalLogin: 'Terminalanmeldung',
            SimpleView2Login: 'Simple Machine Login',
            SensorHealth: 'Sensorzustand',
          },

          errorMessages: {
            emailOrPasswordIncorret: 'Falsche E-Mail oder Passwort.',
            notAuthorized: 'Sie sind dazu nicht berechtigt.',
            pageNotFound:
              'Die Seite, auf die Sie zugreifen möchten, ist nicht vorhanden.',
            moduleNotFound:
              'Das Modul, auf das Sie zugreifen möchten, ist nicht verfügbar.',
            notAuthorizedToViewMachine:
              'Sie sind nicht berechtigt, diese Maschine anzuzeigen.',
            machineDoesNotExist: 'Diese Maschine ist nicht vorhanden.',
            noMaxCycleTime: 'Keine "maxCycleTime" in der Maschinenkonfiguration vorhanden.'
          },

          misc: {
            selectStructureYouWantToView:
              'Wählen Sie eine Struktur aus, die Sie anzeigen möchten.',
            selectMachineYouWantToView:
              'Wählen Sie eine Maschine aus, die Sie anzeigen möchten.',
            toContinueToLeanFocus: 'Weiter zu LEANFOCUS',
            noMachinesInThisStructure:
              'Keine Maschinen in der ausgewählten Struktur.',
            loading: 'Wird geladen...',
            minAvailability: 'Min. Verfügbarkeit',
            maxAvailability: 'Max. Verfügbarkeit',
            copyUrl: 'URL kopieren',
            urlCopySuccess: 'URL erfolgreich kopiert',
            availability: 'Verfügbarkeit',
            time: 'Ortszeit',
            search: 'Suche',
            overview: 'Überblick',
            showMachines: 'Maschinen anzeigen',
            machineStatusDistribution: 'Verteilung des Maschinenstatus',
            machineStatusTimeDistribution: 'Maschinenstatus Zeitverteilung',
            machineStatus: 'Maschinenstatus',
            deselectAll: 'Alle abwählen',
            sortBy: 'Sortieren nach',
            default: 'Standard',
            for: 'für',
            with: 'mit',
            average: 'Durchschnitt',
            applyFilters: 'Filter Anwenden',
            all: 'Alle',
            title: 'Filter',
            reset: 'Zurücksetzen',
          },

          modal: {
            EditStructure: 'Struktur bearbeiten',
            EditMachine: 'Maschine bearbeiten',
            EditSensor: 'Sensor bearbeiten',
            EditUser: 'Benutzer bearbeiten',
            EditRole: 'Rolle bearbeiten',
            EditSensorType: 'Sensortyp bearbeiten',
            CreateSensorType: 'Sensortyp erstellen',
            CreateMachineType: 'Maschinentyp erstellen',
            EditReason: 'Stillstandsgrund bearbeiten',
            EditCategory: 'Kategorie bearbeiten',
            EditMachineStatus: 'Maschinenstatus bearbeiten',
          },

          timespanSelection: {
            last: 'Letzte',
            from: 'Von',
            to: 'Bis',
            Last: 'Letzte',
            Hour: 'Stunde',
            Hours: 'Stunden',
            Minutes: 'Minuten',
            Minute: 'Minute',
            Days: 'Tage',
            Day: 'Tag',
            Weeks: 'Wochen',
            Week: 'Woche',
            LastHour: 'Letzte Stunde',
            Last6Hours: 'Letzte 6 Stunden',
            Last12Hours: 'Letzte 12 Stunden',
            Last24Hours: 'Letzte 24 Stunden',
            Last7Days: 'Letzte 7 Tage',
            ShowLastHour: 'Letzte Stunde anzeigen',
            ShowLast6Hours: 'Letzte 6 Stunden anzeigen',
            ShowLast12Hours: 'Letzte 12 Stunden anzeigen',
            ShowLast24Hours: 'Letzte 24 Stunden anzeigen',
            ShowLast7Days: 'Letzte 7 Tage anzeigen',
            Relative: 'Relative Zeitspanne',
            DateRange: 'Feste Zeitspanne',
            CustomTimePreset: "Eigene Zeitvorlage",
            ctpTitleAlreadyPresent: "Der Titel der Vorlage existiert bereits. Bitte wählen Sie einen anderen.",
            ctpTitleEmpty: "Der Titel ist leer oder ungültig",
            newPresetTitle: "Titel",
            addNewPreset: "Neue Vorlage hinzufügen",
            addNewPresetDescription: "Bitte geben Sie den Titel sowie die Start- und Endzeit der Vorlage ein.",
            deletePresetModalTitle: "Vorlage löschen",
            addPresetModalTitle: "Vorlage hinzufügen",
            areYouSureDeleteTimePreset: "Sind Sie sich sicher, dass sie die Vorlage löschen möchten?",
            dayOfPreset: "Tag der Vorlage",
            CustomBegin: "Beginn",
            CustomEnd: "Ende",
            SelectTimespan: 'Zeitspanne wählen',
            Today: 'Heute',
            ShowPulseForToday: 'Maschinenpulse für Heute anzeigen',
            Yesterday: 'Gestern',
            ShowPulseForYesterday: 'Maschinenpulse für Gestern anzeigen',
            ThisWeek: 'Diese Woche',
            ThisMonth: 'Dieser Monat',
            ThisYear: 'Dieses Jahr',
            ShowPulseForThisWeek: 'Maschinenpulse für diese Woche anzeigen',
            ShowPulseForThisMonth: 'Maschinenpulse für diesen Monat anzeigen',
            ShowPulseForThisYear: 'Maschinenpulse für dieses Jahr anzeigen',
            LastWeek: 'Letzte Woche',
            ShowPulseForLastWeek: 'Maschinenpulse für letzte Woche anzeigen',
            Presets: 'Voreinstellungen',
            CustomPresets: 'Eigene Voreinstellungen',
            RestoreDefaultTimespan: 'Standard wiederherstellen',
            localeDef: {
                "decimal": ",",
                "thousands": ".",
                "grouping": [3],
                "currency": ["€", ""],
                "dateTime": "%a %b %e %X %Y",
                "date": "%d.%m.%Y",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                "shortMonths": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
            },
            timeFormat: "dd. MMMM - HH:mm",
            dateFormat: "dd. MMMM",
          },

          modules: {
            andon: {
              config: 'ANDON Einstellungen',
              theme: 'Theme',
              light: 'Hell',
              dark: 'Dunkel',
              preview: 'ANDON Vorschau',
              loading: 'Wird geladen...',
              loadingMachines: 'Lade Maschinen',
              loadingStructure: 'Lade Struktur',
              noStructureSelected: 'Keine Struktur ausgewählt.',
              noMachines: 'Keine Maschinen in der ausgewählten Struktur.',
              slideshowInterval: 'Wechsel-Intervall',
              set: 'Festlegen',
              minutes: 'Minuten',
              seconds: 'Sekunden',
              minutesAbbr: 'Min',
              secondsAbbr: 'Sek',
              machines: 'Ausgewählte Maschinen',
              machinesLabel: 'Maschinen',
              running: 'Laufend in Zeitraum',
              machineTypes: 'Ausgewählte Maschinentypen',
              machineTypesLabel: 'Machinentypen',
              on: 'An',
              off: 'Aus',
              filter: 'Filter',
            },

            machine: {
              legendModalTitle: "Definitionsübersicht",
              staticReason: "Grund",
              statusTime: "Zeit",
              loadingPulse: 'Laden des Produktionspulses...',
              runtime: 'Laufzeit',
              downtime: 'Ausfallzeit',
              running: 'Laufend',
              notRunning: 'Stillstand',
              online: 'Online',
              lastSeen: 'Zuletzt gesehen',
              never: 'nie',
              duration: 'Dauer',
              state: 'Status',
              machine: 'Maschine',
              drillDown: 'Detailansicht',
              machinesPermissions: 'Berechtigungen für Maschinen',
              machineTypesPermission: 'Berechtigungen für Maschinentypen',
              machineStatusesPermission: 'Berechtigungen für Maschinenstatus',
              availabilityLowToHigh: 'Verfügbarkeit: Niedrig zu hoch',
              availabilityHighToLow: 'Verfügbarkeit: Hoch zu niedrig',
              performanceLowToHigh: 'Performance: Niedrig zu hoch',
              performanceHighToLow: 'Performance: Hoch zu niedrig',
              loadingMachine: 'Lade Maschine ...',
              partsPerMinute: 'Teile/Minute',
              secPerPart: 'Sek/Teil',
              partsPerMinuteString: 'Teile/Minute',
              secPerPartString: 'Sekunden/Teil',
              currentSpeedTarget: 'Aktuelle Geschwindigkeit (Soll)',
              selectMachine: 'Bitte wählen Sie eine Maschine aus',
              sortBy: 'Filtern nach',
              qualityLowToHigh: 'Qualität: Niedrig zu hoch',
              qualityHighToLow: 'Qualität: Hoch zu niedrig',
              speedTarget: 'Ø Geschwindigkeit (Soll)',
              speedJob: 'Auftragsgeschwindigkeit (Soll)',
              splitState: 'Zeitspanne aufteilen',
              mergeState: 'Zeitspanne zusammenführen',
              merge: 'Zusammenführen',
              addPreviousState: 'Nächsten Status hinzufügen',
              addNextState: 'Vorherigen Status hinzufügen',
              noMoreStates: 'Keine weiteren Zeitspannen im ausgewählten Zeitbereich verfügbar',
              splitStateSuccessful: 'Die Zeitspanne wurde erfolgreich aufgeteilt.',
              mergeStateSuccessful: 'Die Zeitspannen wurden erfolgreich zusammengeführt.',
            },
            simple_machine: {
              default: "Hallo",
              parts: "Teile",
              shiftGoalLabel: "Schicht\n[bold]Ziel[/]",
              shiftGoal: "Schichtziel",
              timeGoal: "Zeitziel",
              unit: "Teile",
              gauge: "Gauge",
              progress: "Progress Bar",
              targetCylceTime: 'Soll-Zyklus Zeit',
              currentSpeed: 'Aktuelle Geschwindigkeit',
              partsPerHour: 'Teile/Stunde',
            },
            simple_machine2: {
              currentSpeed: 'Ist',
              targetSpeed: 'Soll',
              shiftSpeed: "Schicht",
              currentOrder: 'Aktueller Auftrag',
              customer: 'Kunde',  
              orderLoading: 'Lade Auftrag...',     
              product: "Produkt",       
              order: "Auftrag",
              shiftStart: "Schichtbeginn",
              shiftEnd: "Schichtende",
            },
            order_export: {
              isWorkingOrderComplete: "Arbeitsauftrag abgeschlossen",
              totalScrap: "Ausschuss",
              isSetup: "Rüstung",
              intendedMachineNotEqualtoMachineTitle: "Geplante Maschine unterschiedlich zu ausgewählter Maschine",
              intendedMachineNotEqualtoMachine: "Die geplante Maschine ist nicht gleich der ausgewählten Maschine. Möchten Sie dennoch fortfahren?",
              noPartsProduced: "Keine Teile im Zeitraum produziert",
              handCountWarning: `Manuelle Zählung größer als ${parseInt(HANDCOUNT_THRESHOLD*100)}% der produzierten Teile`,
              label: "Berechtigungen für Auftrag Export",
              selectMachine: "Maschine auswählen",
              selectStructure: "Struktur auswählen",
              selectOrder: "Auftrag auswählen",
              activations: "Aktivierungen",
              article_id: "Artikel",
              operator: "Bediener",
              startDate: "Start",
              endDate: "Ende",
              oee: "OEE",
              mandant: "Mandant",   
              editActivation: "Aktivierung bearbeiten",         
              deleteActivation: "Aktivierung löschen",
              addActivation: "Aktivierung hinzufügen",
              qualityReports: "Qualitätsberichte",
              job: "Auftrag",
              activationStatus: "Aktivierungsstatus",
              handCount: "Manuell",
              user: "Benutzer",
              partsProduced: "Sensor",
              machine: "Maschine",
              editActivationModalTitle: "Aktivierung bearbeiten",
              addActivationModalTitle: "Aktivierung hinzufügen",
              activationUpdated: "Aktivierung wurde erfolgreich aktualisiert",
              deleteActivationModalTitle: "Aktivierung löschen",
              activationAdded: "Aktivierung wurde erfolgreich hinzugefügt",
              userNotFound: "Benutzer nicht gefunden",
              jobNotFound: "Auftrag nicht gefunden",
              productNotFound: "Produkt nicht gefunden",
              genericError: "Ein Fehler ist aufgetreten",
              activationDeleted: "Aktivierung wurde erfolgreich gelöscht",
              deleteError: "Löschen der Aktivierung fehlgeschlagen",
              deleteActivationModalText: "Sind Sie sicher, dass Sie die Aktivierung löschen möchten?",
              qualityReportsList: "Qualitätsberichte",
              selectedJob: "Ausgewählter Auftrag",
              errors: {
                activationAlreadyPresent: "Es existiert bereits eine Aktivierung für den ausgewählten Zeitraum",
                activationStatusRequired: "Status ist erforderlich",
                operatorRequired: "Bediener ist erforderlich",
                handCountRequired: "Handzählung ist erforderlich",
                partsProducedRequired: "Produzierte Teile sind erforderlich",
                startTimeRequired: "Startzeit ist erforderlich",
                endTimeRequired: "Endzeit ist erforderlich",
                endTimeBeforeStartTime: "Endzeit muss nach Startzeit liegen",
                productRequired: "Produkt ist erforderlich",
                jobRequired: "Auftrag ist erforderlich",
                machineRequired: "Maschine ist erforderlich",
              },
              quality_report: {
                wasteCount: "Ausschuss",
                startTime: "Start Zeit",
                endTime: "Endzeit",
                scrapType: "Ausschuss Typ",
                editQualityReport: "Qualitätsbericht bearbeiten",
                deleteQualityReport: "Qualitätsbericht löschen",
                addQualityReport: "Qualitätsbericht hinzufügen",
                deleteQualityReportModalTitle: "Qualitätsbericht löschen",
                deleteQualityReportModalText: "Sind Sie sicher, dass Sie den Qualitätsbericht löschen möchten?",
                qualityReportDeleted: "Qualitätsbericht wurde erfolgreich gelöscht",
              }
            },            
            machine_shift: {
              shift: 'Schicht',
              shifts: 'Schichten',
              createNewMachineShift: 'Neue Schicht erstellen',
              existingMachineShifts: 'Existierende Schichten',
              confirmDeleteTitle: "Schicht löschen bestätigen",
              deleteConfirmation: "Sind sie sicher, dass Sie Schicht '{shiftName}' von {startTime} bis {endTime} löschen möchten?",
              editMachineShift: "Schicht bearbeiten",
              machineShiftPermission: "Berechtigungen für Schichten",
              errors:{
                endTimeLargerStartTime: "Endzeit muss größer als Startzeit sein",
                noShiftFound: "Keine Schicht im aktuellen Zeitraum gefunden",
                nameRequired: "Schichtname ist erforderlich",
                machineRequired: "Maschine ist erforderlich",
                startTimeRequired: "Startzeit ist erforderlich",
                endTimeRequired: "Endzeit ist erforderlich",
                isActiveRequired: "Ein Wert für Aktiv ist erforderlich",
                targetNumberLargerZero: "Zielanzahl muss größer als 0 sein",
                mondayRequired: "Ein Wert für Montag ist erforderlich",
                tuesdayRequired: "Ein Wert für Dienstag ist erforderlich",
                wednesdayRequired: "Ein Wert für Mittwoch ist erforderlich",
                thursdayRequired: "Ein Wert für Donnerstag ist erforderlich",
                fridayRequired: "Ein Wert für Freitag ist erforderlich",
                saturdayRequired: "Ein Wert für Samstag ist erforderlich",
                sundayRequired: "Ein Wert für Sonntag ist erforderlich",
              },
              forms: {
                machineShiftAdded: "Schicht wurde erfolgreich hinzugefügt",
                machineShiftUpdated: "Schicht wurde erfolgreich aktualisiert",
                name: "Schichtname",
                startTime: "Startzeit",
                endTime: "Endzeit",
                machine: "Maschine",
                machines: "Maschinen",
                monday: "Montag",
                tuesday: "Dienstag",
                wednesday: "Mittwoch",
                thursday: "Donnerstag",
                friday: "Freitag",
                saturday: "Samstag",
                sunday: "Sonntag",
                isActive: "Aktiv",
                targetNumber: "Zielanzahl",
              }
            },
            reporting: {
              minStartTime: 'Min. Startzeit',
              minEndTime: 'Min. Endzeit',
              maxStartTime: 'Max. Startzeit',
              maxEndTime: 'Max. Endzeit',
              minDuration: 'Min. Dauer',
              maxDuration: 'Max. Dauer',
              loadingReportData: 'Lade Berichtdaten',
              oeeTab: 'OEE',
              machineTab: 'Maschine',
              machineTabMachine: 'Maschine',
              jobTab: 'Aufträge',
              noMatchingData:
                'Leider können keine übereinstimmenden Daten angezeigt werden.',
              minAvailability: 'Min. Verfügbarkeit',
              maxAvailability: 'Max. Verfügbarkeit',
              loadingMachines: 'Lade Maschinen...',
              loadingQualityReports: 'Lade Qualitätsberichte...',
              actualIdealCount: 'Menge Ist/Soll',
              actualCount: 'Ist-Menge',
              goodCount: 'Gut-Menge',
              targetCount: 'Soll-Menge',
              scrapCount: 'Ausschuss',
              plannedDowntime: 'Geplanter Stillstand',
              downtime: 'Ungeplanter Stillstand',
              scheduleLoss: 'Schichtmäßiger Stillstand',
            },
            advanced_reporting: {
              machineRankingHeadingText: "Machinen Leistung - Übersicht",
              machineRankingChartID: "Maschinen Ranking",
              machineRankingValue: "performance",
              machineRankingCategory: "name",
              noPerformanceText: "N/A",
              noPerformanceValue: 0,
              reasonRankingHeadingText: "Schlimmste Stillstandsgründe",
              reasonRankingChartID: "Reason Ranking",
              reasonRankingValue: "count",
              reasonRankingCategory: "name",
              noReasonsText: "No reasons yet",
              noReasonsValue: 1,
              averageHeadingText: "Durschnittszeiten - Übersicht",
              averageChartID: "Average Overview",
              averageValue: "value",
              averageCategory: "category",
              fromText: "Von",
              toText: "Bis",
              timeSelectorHeading: "Zeitauswahl",
              timeSelectorTimeframeSelect: "Wählen Sie ein Zeitfenster",
              pickTime: "Geben Sie eine Zeit an (letzte Stunden)"
            },
            user: {
              signIn: 'Einloggen',
              noStructures:
                'Dem Benutzer sind keine Strukturen oder Maschinen zugeordnet.',
              noViews:
                'Dem Benutzer sind keine Ansichten für Strukturen oder Maschinen zugewiesen.',
              note: 'Hinweis',
              editingDisabled:
                'Das Bearbeiten der Systemadministratorrolle ist deaktiviert!',
              users: 'Benutzer',
              usersPermissions: 'Berechtigungen für Benutzer',
              deactivateUsers: 'Benutzer deaktivieren',
              oeeOverview: 'OEE Übersicht',
              andonBoard: 'Andon Board',
              comparisonOverview: 'Vergleichsübersicht',
              reportingOverview: 'Berichtsübersicht',
              scheduleOverview: 'Schichtübersicht',
              machineOverview: 'Maschinenübersicht',
              structureManagement: 'Strukturverwaltung',
              machineManagement: 'Machinenverwaltung',
              sensorManagement: 'Sensorverwaltung',
              userManagement: 'Benutzerverwaltung',
              structure: 'Struktur',
              machine: 'Maschine',
              homeview: 'Home Ansicht',
              units: {
                SPP: "Sekunden pro Teil",
                MPP: "Minuten pro Teil",
                PPM: "Teile pro Minute",
                PPH: "Teile pro Stunde",
                PPS: "Teile pro Sekunde",
                short_caps : {
                  SPP: "Sekunden/Teil",
                  MPP: "Minuten/Teil",
                  PPM: "Teile/Minute",
                  PPH: "Teile/Stunde",
                  PPS: "Teile/Sekunde",
                },
                short: {
                  SPP: "s/Teil",
                  MPP: "min/Teil",
                  PPM: "Teile/min",
                  PPH: "Teile/h",
                  PPS: "Teile/s",
                }
              }
            },

            structure: {
              averageRuntime: 'Durchschnittliche Maschinenlaufzeit',
              averageDowntime: 'Durchschnittliche Maschinenstillstandszeit',
              machines: 'Maschinen',
              timespan: 'Zeitspanne',
              productionPulse: 'Produktionspuls',
              productionMachines: 'Produktionsanlagen',
              structureLoading: 'Lade Struktur...',
              runningCalculations: 'Berechnungen durchführen...',
              deactivateStructures: 'Strukturen deaktivieren',
              selectStructure: 'Bitte wählen Sie eine Struktur aus',
              machinesRunning: 'Maschinen laufen.',
              of: 'von',
            },

            performance: {
              slowRunning: 'Langsam',
              idle: 'Leerlauf',
              jobs: "Aufträge",
              availability: 'Verfügbarkeit',
              performance: 'Performance',
              minPerformance: 'Min. Performance',
              maxPerformance: 'Max. Performance',
              qualityShort: 'QUA',
              performanceShort: 'PER',
              availabilityShort: 'VER',
              avgSign: 'Ø',
              partsPerMinute: 'Teile/Min',
              quality: 'Qualität',
              oee: 'OEE',
              parts: 'Menge',
              partsProduced: 'Teile produziert',
              partsSurplus: 'Überschuss',
              partsMissed: 'Fehlende Teile',
              chartTargetCycleTime: 'Soll-Zyklus Zeit',
              target: 'Soll',
              chartParts: 'Teile',
              chartPartsOf: 'von',
              chartPartsSec: 'Sekunden',
              chartPartsProduced: 'Ist-Menge (Teile/Min)',
              chartPartsProducedSecPart: 'Ist-Menge (Sek/Teil)',
              chartPartsProducedPartsHour: 'Ist-Menge (Teile/Stunde)',
              chartPartsPartsHour: 'Teile/Stunde',
              chartPartsTitle: 'Produzierte Teile',
              chartPartsPartsMin: 'Teile/Min',
              chartPartsSecPart: 'Sek/Teil',
              isLoadingParts: 'Lade Teile...',
            },
            terminal: {
              productionCapacity: 'Produktionskapazität',
              onlineStatus: 'Online Status',
              connected: 'Verbunden',
              notConnected: 'Nicht verbunden',
              internet: 'Internet',
              machineData: 'Maschinendaten',
              mongodbData: 'MongoDB Daten',
              lfBackend: 'LF Server',
              postgresData: 'Postgres Daten',
              lastCheck: 'Letzte Überprüfung',
            },

            reasons: {
              reasons: 'Stillstandsgründe',
              noAssignedReasonsTimeframe: 'Keine Stillstandsgründe im ausgewählten Zeitraum.',
              deactivateReasons: 'Stillstandsgründe deaktivieren',
              categoriesPermissions: 'Berechtigungen für Kategorien',
              notesPermissions: 'Berechtigung für Notizen',
              reasonsPermissions: 'Berechtigung für Stillstandsgründe',
            },

            sensor: {
              deactivateSensors: 'Sensoren deaktivieren',
              sensorsPermission: 'Berechtigung für Sensoren',
              sensorTypesPermission: 'Berechtigung für Sensortypen',
              notConnected: 'Nicht verbunden',
              noSignal: 'Kein Signal',
              signalStrength: 'Signalstärke',
              rssiExcellent: 'Ausgezeichnet',
              rssiGood: 'Gut',
              rssiFair: 'Ausreichend',
              rssiWeak: 'Schwach',
              noMachine: 'Keine angeschlossene Maschine vorhanden',
              lastSeenAt: 'Zuletzt online: ',
              online: 'Online: ',
              rssiAverage: 'RSSI (7-Tage-Durchschnitt)',
              version: 'Version: ',
              hardware: 'Hardware: ',
            },

            roles: {
              rolesPermission: 'Berechtigungen für Rollen',
            },

            schedule: {
              setup: 'Aufsetzen',
              create: 'Erstellen',
              save: 'Speichern',
              createAndSave: 'Erstellen und speichern',
              saveAndDeploy: 'Speichern und anwenden',
              createAndDeploy: 'Erstellen und anwenden',
              monday: "Montag",
              tuesday: "Dienstag",
              wednesday: "Mittwoch",
              thursday: "Donnerstag",
              friday: "Freitag",
              saturday: "Samstag",
              sunday: "Sonntag",
              date: 'Datum',
              today: 'HEUTE',
              dayTemplatePermissions: 'Berechtigungen für Tagesvorlagen',
              weekTemplatePermissions: 'Berechtigungen für Wochenvorlagen',
              schedulePermissions: 'Berechtigungen für Zeitplanung',
              shiftsPermissions: 'Berechtigungen für Schichten',
              activateSchedule: 'Zeitplan aktivieren',
              schedule: 'Zeitplan',
              noEligibleSchedules: 'Kein auswählbarer Zeitplan vorhanden.',
              changeActiveSchedule: 'Aktiven Zeitplan ändern',
              editSchedule: 'Zeitplan bearbeiten',
              editDay: 'Tag bearbeiten',
              deactivateTemplates: 'Vorlagen deaktivieren',
              existingShifts: 'Existierende Zeitpläne',
              createShift: 'Zeitplan erstellen',
              start: 'Start',
              end: 'Ende',
              scheduled: 'Geplant',
              duration: 'Dauer',
              preview: 'Vorschau',
              enabled: 'Aktiviert',
              shiftUpdated: 'Zeitplan erfolgreich aktualisiert.',
              shifts: 'Zeitpläne',
              editShift: 'Zeitplan bearbeiten',
              selectedTime: 'Ausgewählter Zeitraum: ',
              invalidStart:
                ' ist fehlerhaft da es in Konflikt mit dem Beginn der Schicht steht.',
              invalidEnd:
                ' ist fehlerhaft da es in Konflikt mit dem Ende der Schicht steht.',
              shiftStart: 'Schichtbeginn',
              shiftEnd: 'Schichtende',
              manageSchedules: 'Zeitpläne bearbeiten',
              createSchedule: 'Zeitplan erstellen',
              existingWeekTemplates: 'Existierende Wochenvorlagen',
              createWeekTemplate: 'Wochenvorlage erstellen',
              existingDayTemplates: 'Existierende Tagesvorlagen',
              createDayTemplate: 'Tagesvorlage erstellen',
              weekRemoved: 'Woche erfolgreich entfernt.',
              weekEdited: 'Woche erfolgreich bearbeitet.',
              dayEdited: 'Tag erfolgreich bearbeitet.',
              scheduleCreated: 'Zeitplan erfolgreich erstellt.',
              scheduleUpdated: 'Zeitplan erfolgreich geändert.',
              startDate: 'Startdatum',
              endDate: 'Enddatum',
              addWeek: 'Woche hinzufügen',
              editWeek: 'Woche bearbeiten',
              scheduleInfo: 'Zeitplaninformation',
              scheduleName: 'Name',
              scheduleDescription: 'Beschreibung:',
              lastDeployed: 'Zuletzt eingesetzt:',
              lastEdited: 'Zuletzt bearbeitet:',
              goTo: 'Gehe zu:',
              scheduleDeployed: 'Zeitplan erfolgreich eingesetzt.',
              deploymentCanceled: 'Das Deployment wurde erfolgreich gestoppt.',
              scheduleActivated: 'Zeitplan erfolgreich aktiviert.',
              scheduleDuplicated: 'Kopie erstellt.',
              deployment: 'Einsatz',
              activation: 'Aktivierung',
              areYouSureDeploy: 'Wollen Sie den Zeitplan einsetzten ',
              areYouSureDuplicate: 'Wollen Sie sicher ein Duplikat erstellen?',
              areYouSureCancel: 'Wollen Sie sicher das Deployment abbrechen?',
              areYouSureActivate: 'Wollen Sie den Zeitplan aktivieren ',
              overwriteOnMachines:
                'Dies wird alle an den unten aufgelisteten Maschinen vorgenommenen Änderungen überschreiben.',
              onTheseMachines: ' auf folgenden Maschinen:',
              editCreatedSchedule:
                'Wollen Sie den neu erstellten Zeitplan bearbeiten?',
              areYouSureDelete: 'Den Zeitplan wirklich löschen ',
              scheduleDeleted: 'Der Zeitplan wurde erfolgreich gelöscht.',
              schedules: 'Zeitpläne',
              areYouSure: 'Sind Sie sicher?',
              editNewSchedule: 'Neuen Zeitplan bearbeiten',
              doItLater: 'Später',
              deployingSchedule: 'Zeitplan einsetzen: ',
              cancelingDeployment: 'Stoppen des Deployments:',
              takeWhile: 'Je nach Anzahl der Maschine kann dies etwas dauern.',
              activatingSchedule: 'Aktiviere Zeitplan: ',
              addRemoveMachines: 'Hinzufügen/Entfernen von Maschinen',
              editMasterTemplate: 'Hauptvorlage bearbeiten',
              editDeployedSchedule: 'Eingesetzten Zeitplan bearbeiten',
              editNewWeekTemplate:
                'Wollen Sie die neu erstellte Vorlage bearbeiten?',
              deployingYourSchedule: 'Einsetzen des Zeitplans...',
              editNewTemplate: 'Neue Vorlage bearbeiten',
              scheduleCantBeEdited:
                'Der Zeitplan kann für das gewünschte Datum nicht mehr bearbeitet werden',
              selectedMachines: 'Ausgewählte Maschinen: ',
              weekTemplateDuplicated:
                'Die Wochenvorlage wurde erfolgreich dupliziert.',
              addDayTemplate: 'Tagesvorlage hinzufügen',
              scheduleTemplateUpdated:
                'Die Vorlage wurde erfolgreich aktualisiert.',
              weekTemplates: 'Wochenvorlage',
              editWeekTemplate: 'Wochenvorlage bearbeiten',
              weekTemplateCreated:
                'Die Wochenvorlage wurde erfolgreich erstellt.',
              weekTemplateUpdated:
                'Die Wochenvorlage wurde erfolgreich aktualisiert.',
              clearDay: 'Tag leeren',
              templateNameRequired: 'Vorlagenname ist erforderlich.',
              templateEvents: 'Vorlagenereignis ist erforderlich.',
              templateTimeframe:
                'Der Zeitraum der Vorlage muss komplett ausgefüllt sein.',
              scheduleNameRequired: 'Name des Zeitplans erforderlich.',
              scheduleToMachine:
                'Der Zeitplan muss mindestens mit einer Maschine verbunden sein.',
              startDateCantBeInThePast:
                'Startdatum darf nicht in der Vergangenheit liegen.',
              endDateCantBeBeforeStartDate:
                'Enddatum darf nicht vor dem Startdatum liegen.',
              atLeastOneWeekTemplate: 'Dem Zeitplan muss mindestens eine Wochevorlage hinzugegefügt werden.',
              dayTemplateDuplicated:
                'Eine Kopie der Tagesvorlage wurde erfolgreich erstellt.',
              dayTemplates: 'Tagesvorlage',
              editTemplate: 'Vorlage bearbeiten',
              dayTemplateCreated:
                'Die Tagesvorlage wurde erfolgreich erstellt.',
              dayTemplateUpdated:
                'Die Tagesvorlage wurde erfolgreich aktualisiert.',
              duplicate: 'Duplizieren',
              edit: 'Editieren',
              view: 'Anzeigen',
              delete: 'Löschen',
              hasExpired: 'Abgelaufen',
              hasNotExpired: 'Nicht abgelaufen',
              all: 'Alle',
              expiration: 'Ablauf',
              cancelDeployment: 'Deployment abbrechen',
              deploy: 'Deploy',
              activateOn: 'Aktivieren am:',
              activationScheduledSuccessfully: 'Altivierung wirde erfolgreich geplant.',
              activationCanceledSuccessfully: 'Geplante Aktivierung wurde erfolgreich beendet.',
              isDueForActivationOn: 'ist geplant zu aktibieren am',
              selectAllMachines: 'ALLE MASCHINEN AUSWÄHLEN',
              remove: "ENTFERNEN"
            },

            quality: {
              scrapCountTextfield: 'Menge',
              quality: 'Qualität',
              easyScrap: 'Schnelle Ausschusszuweisung',
              easyScrapButton: 'Ausschuss',
              easyScrapNote: 'Durch einfache Ausschussauswahl hinzugefügt',
              easyScrapDescription: 'Schnelle Ausschusszuweisung',
              existingScrapTypes: 'Existierende Ausschussarten',
              createNewScrapType: 'Neue Ausschussart erstellen',
              scrapTypes: 'Ausschussarten',
              selectScrapType: 'Ausschussart Auswählen',
              scrapType: 'Ausschussart',
              minQuality: 'Min. Qualität',
              maxQuality: 'Max. Qualität',
              count: 'Menge',
              qualityReportAdded:
                'Ein neuer Qualitätsbericht wurde erfolgreich erstellt.',
              qualityReportDeleted: 'Der Qualititätsbericht wurder erfolgreich gelöscht',
              qualityReport: 'Qualitätsbericht',
              alreadyAdded:
                ' wurde bereits hinzugefügt. Bitte wählen Sie "Bearbeiten" aus um Änderungen vorzunehmen.',
              productionTimePeriod: 'Produktionszeitraum',
              wasteCount: 'Ausschussmenge',
              totalWasteCount: 'Gesamte Ausschussmenge:',
              scrapTypeRequired: 'Ausschussart erforderlich',
              endTimeRequired: 'Endzeitpunkt benötigt',
              startTimeRequired: 'Startzeitpunkt benötigt',
              countRequired:
                'Menge benötigt. Kann nicht 0 sein oder leer gelassen werden.',

              invalidScrapNumber: 'Ungültige Menge an Ausschuss; kann nicht negativ sein.',
              tooMuchScrap: 'Ungültige Menge an Ausschuss; Anzahl zu groß',
              actualCountInvalid: 'Ist-Menge ist ungültig, Ausschuss Eintrag nicht möglich.',
              typeNameRequired: 'Ausschussart Name erforderlich.',
              typeIdentifierRequired: 'Ausschussart erforderlich.',
              categoryRequired: 'Kategorie erforderlich.',
              originRequired: 'Datenquelle erforderlich.',
              scrapTypesPermissions: 'Berechtigungen für Ausschussarten.',
              parts: 'Menge',
              noQualityReportSubmitted: 'Keine Qualitätsberichte im ausgewählten Zeitraum',
              qualityLosses: 'Qualitätsverluste',
              qualityReports: 'Qualitätsreport',
              deleteQualityReportModalTitle: 'Qualitätsreport löschen?',
              areYouSureDeleteQualityReport: 'Wollen Sie den Qualitätsreport wirklich löschen?',
              noQualityReportsSubmitted: 'Kein Qualitätsreport für den ausgewählten Zeitraum vorhanden.',
            },

            orders: {
              start_working_order: 'Starte Arbeitsauftrag',
              stop_working_order: 'Beende Arbeitsauftrag',
              average: 'Durchschnitt',
              areYouSureDeleteJob: "Auftrag Löschen?",
              areYouSureDeleteJobText: "Sind Sie sich sicher, dass Sie den folgenden Auftrag löschen möchten?",
              orders: "Aufträge",
              edit: "Bearbeiten",
              delete: "Löschen",
              pastActiveJobs: "Vergangene aktive Jobs",
              noPastActiveJobsInTimeframe: 'Keine vergangenen aktiven Jobs im ausgewählten Zeitraum',
              ordersPermissions: 'Berechtigungen für Aufträge (Orders)',
              jobsPermissions: 'Berechtigungen für Arbeitsaufträge (Jobs) ',
              productsPermissions: 'Berechtigungen für Produkte',
              jobId: 'Auftragsnummer',
              progress: "Fortschritt (%)",
              speedJob: "Geschwindikeit",
              activeJobs: 'Aktiver Auftrag',
              orderId: 'Bestellnummer ',
              status: 'Status',
              plannedStartDate: 'Plan-Start',
              actualStartDate: 'Ist-Start',
              endDate: "End-Datum",
              target: 'Soll-Menge',
              sensorCount: 'Ist-Menge',
              goodCount: 'Gut-Menge',
              scrapCount: 'Ausschuss',
              handCount: 'Manuell gezählt',
              machine: 'Maschine',
              intended: 'Geplant',
              operator: 'Bediener',
              structureField: 'structure',
              intendedMachineField: 'intendedMachine',
              intendedMachine: 'Geplante Maschine',
              structure: 'Struktur',
              startTime: 'Startzeit',
              endTime: 'Endzeit',
              duration: 'Dauer ',
              uploadCSV: 'CSV Hochladen',
              chooseFileToUpload: 'Datei zum Hochladen auswählen',
              chooseFile: 'Datei wählen',
              close: 'Schließen',
              upload: 'Hochladen',
              uploading: 'Hochladen',
              uploadAnotherFile: 'Eine andere Datei hochladen',
              jobsCreatedSuccessfully: 'Aufträge erfolgreich erstellt',
              backToUploadFile: 'Zurück zum Hochladen der Datei',
              fileHasBeenUploaded: 'Datei wurde hochgeladen.',
              uploadAgainWithCorrections:
                'Die Datei muss mit folgenden Korrekturen erneut hochgeladen werden:',
              row: 'Reihe',
              backToJobsList: 'Zurück zur Auftragsliste',
              product: 'Produkt',
              description: 'Beschreibung',
              jobStatus: 'Auftragsstatus',
              targetCount: 'Soll-Menge',
              targetCycleTime: 'Soll-Zyklus Zeit',
              targetSetupTime: 'Soll-Setup Zeit',
              engagements: 'Aktivierungen',
              operatorNotes: 'Bedienernotizen',
              loadingJob: 'Lade Auftrag...',
              existingNotes: 'Vorhandene Notizen',
              enterNoteText: 'Notiz eingeben',
              addNote: 'Notiz hinzufügen',
              clearScreen: 'Ansicht zurücksetzen',
              start: 'Start Aufzeichung',
              stop: 'Stopp Aufzeichnung',
              setJobStatus: 'Stellen Sie den Auftragsstatus ein. ',
              ACTIVE: 'AKTIV',
              OPEN: 'OFFEN',
              PAUSED: 'PAUSIERT',
              COMPLETED: 'ABGESCHLOSSEN',
              setJobStatusPaused: 'Auftrag pausieren',
              setJobStatusCompleted:
                'Auftrag abschließen',
              currentlyEngaged: 'Aktuell aktiviert',
              jobTotalCount: 'Auftrag',
              speed: 'Geschwindigkeit',
              actual: 'Aktuell',
              setup: 'Setup',
              warning: 'Warnung',
              areYouSureOverwriteHandcount:
                'Manuelle Zählung überschreiben?',
              byClickingSubmit:
                'Durch Bestätigen wird die manuelle Zählung gesetzt auf:',
              willBeDeleted: 'als vorheriger Wert wird gelöscht.',
              yes: 'Ja',
              no: 'Nein',
              STARTED: 'GESTARTET',
              ENDED: 'BEENDET',
              intendedTable: 'geplant',
              unintendedTable: 'ungeplant',
              fulltime: 'Volles Zeitfenster',
              timeframe: 'Zeitfenster',
              pastTwoWeeks: 'Vergangene 2 Wochen',
              pastMonth: 'Verganger Monat',
              pastSixMonths: 'Vergangene 6 Monate',
              pastYear: 'Vergangenes Jahr',
              jobProgress: 'Auftragsfortschritt',
              jobProgressOverview: 'Auftragsfortschritt Übersicht',
              existingJobs: 'Alle Aufträge',
              createNewJob: "Neuen Auftrag erstellen",
              activationOverview: 'Aktivierung Übersicht',
              addedHandCount: 'Manuelle Zählung',
              removeAssignedJob: 'Zugeweisung entfernen',
              assignJob: 'Zuweisen',
              startHandCount: 'Start Anzahl',
              endHandCount: 'Ende Anzahl',
              handCountCalculator: 'Manuelle Zählung Rechner',
              apply: 'Anwenden',
              notAvailable: 'Auftrag ist nicht vorhanden: ',
            },
            machinePerformance: {
              machinePerformance: 'Machinen Leistungsbewertung',
              theoreticalOutput: 'Theoretische Maximalleistung',
              workingOutput: 'Theoretische Arbeitsleistung',
              scheduledOutput: 'Geplante Gesamtleistung',
              downtimeLoss: 'Unterbrechungsverluste',
              speedLoss: 'Geschwindigkeitsverluste',
              qualityLoss: 'Qualitätsverluste',
              theoreticalOutputDesc: 'Theoretische Maximalzahl an produzierten Teilen, wenn die Maschine 24/7 in Betrieb ist.',
              workingOutputDesc: 'Theoretische Anzahl produzierter Teile, wenn nach Schichtplan produziert wird.',
              scheduledOutputDesc: 'Theoretische Anzahl produzierter Teile nach Schichtplan und geplanten Stops.',
              downtimeLossDesc: 'Theoretische maximale Anzahl produzierter Teile während die Maschine lief.',
              speedLossDesc: 'Produzierte Teile',
              qualityLossDesc: 'Qualitativ gute Teile',
              performanceLoading: 'Leistungsbewertung wird geladen...',
              unit: 'Teile',
              totalTime: 'Gesamtzeit',
              maxCycleTime: 'Max. Teile pro Minute',
              unitMaxCycleTime: 'Teile/min',
              noPerformance: 'Keine Leistungsdaten verfügbar.',
              plannedReasons: 'Geplante Pausenzeit',
              plannedReasonsHeading: 'Geplante Stillstandsgründe',
              runningTime: 'Gesamte Laufzeit',
              avgPartsPerMinute: 'Durchschnittliche Teile pro Minute',
              avgScrapPerMinute: 'Durchschnittlicher Ausschuss pro Minute',
              total: 'Gesamt',
              totalDowntimeLoss: 'Gesamtverlust durch Ausfall',
              delta: 'Delta',
              reasonsHeading: 'Stillstandsgründe',
              qualityLossesHeading: 'Qualitätsverluste',
              backToMachineView: 'Zurück zur Maschinenübersicht',
              scheduledWorkingTime: 'Geplante Arbeitszeit',
              overtime: 'Zusätzliche Arbeitszeit',
              plannedRunningTime: "Geplante Laufzeit"
            }
          },

          forms: {
            reloadInterval: {
              success: "Neu Laden Interval erfolgreich aktualisiert",
              failure: "Aktualisieren des neu Laden Intervals fehlgeschlagen",
            },
            jobs: {
              product: "Produkt",
              targetCycleTime: 'Soll-Zyklus Zeit',
              targetSetupTime: 'Soll-Setup Zeit',
              targetQuantity: "Sollmenge",
              description: "Beschreibung",
              intendedMachine: 'Geplante Maschine',
              scanCode: "Scan Code",
              handCount: 'Manuell gezählt',
              completionStatus: "Status",
              businessJobId: "Business ID",
              modalTitle: "Auftrag bearbeiten",
              jobEditSuccess: "Auftrag gespeichert",
              jobEditFailure: "Fehler beim Speichern des Auftrages",
              jobDeleteSuccess: "Auftrag erfolgreich gelöscht",
              jobDeleteFailure: "Fehler beim Löschen des Auftrages",
              validate: {
                handCountRequired: "Anzahl für manuell gezählte Teile ist erforderlich",
                descriptionRequired: "Beschreibung des Auftrages ist erforderlich",
                completionStatusRequired: "Ein Auftragsstatus ist erforderlich",
                intendedMachineRequired: "Eine Maschine muss ausgewählt werden",
                targetQuantityRequired: "Eine Soll-Menge ist erforderlich",
                targetSetupTimeRequired: "Eine Soll-Setup Zeit ist erforderlich",
                targetCycleTimeRequired: "Eine Soll-Zyklus Zeit ist erforderlich",
                productRequired: "Ein Produkt muss ausgewählt werden"
              }
            },
            machine: {
              fields: {
                placeNumber: 'Stellplatz',
                manufacturer: 'Hersteller',
                machineConfiguration: 'Maschinenkonfiguration',
                newMachineConfiguration: "Neue Maschinenkonfiguration",
                configurationName: 'Name',
                configurationValue: 'Wert',
                selectConfigurationValue: "Eigener Wert",
              },
              configLabels: {
                defaultActivationStatus: "Standardaktivierungsstatus",
                targetCycleTime: "Soll-Zyklus Zeit",
                maxCycleTime: "Maximal Zyklus Zeit",
                partsChartSimple: "Erlaube einfache Teile-Diagramme",
                partsChart: "Erlaube Teile-Diagramme",
                speedLow: "Minimum Maschinengeschwindigkeit",
                speedMedium: "Medium Maschinengeschwindigkeit",
                speedMax: "Maximum Maschinengeschwindigkeit",
                handCountStartEnd: "Erlaube manuelle Zählung Start/Ende Zeit",
                jobBasedTerminal: "Job basiertes Terminal",
              },
              configUnits: {
                defaultActivationStatus: "ACTIVE/ENDED",
                targetCycleTime: "Sekunden/Teil",
                maxCycleTime: "Sekunden/Teil",
                partsChartSimple: "true/false",
                partsChart: "true/false",
                speedLow: "Sekunden/Teil",
                speedMedium: "Sekunden/Teil",
                speedMax: "Sekunden/Teil",
                handCountStartEnd: "true/false",
                jobBasedTerminal: "true/false",
              },
              buttons: {
                removeField: 'Entfernen',
                addField: 'Hinzufügen',
                addCustomField: 'Eigenes Feld hinzufügen',
              },

              errors: {
                machineNameRequired:
                  'Der Maschinenname ist erforderlich. Kann nicht leer gelassen werden.',
                structureRequired:
                  'Die Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                machineTypeRequired:
                  'Der Maschinentyp ist erforderlich. Kann nicht leer gelassen werden.',
                nameCannotBeBlank:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                sensorPerMachine:
                  'Die aktuelle Version unterstützt nur einen Sensor pro Maschine.',
                machineStatusName:
                  'Der Maschinenstatus-Name ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusActivity:
                  'Die Maschinenstatus-Aktivität ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusColor:
                  'Die Farbe ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusCategory:
                  'Die Kategorie ist erforderlich. Kann nicht leer gelassen werden.',
                machineStatusDataLayer:
                  'Die Datenschicht ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                machineAdded:
                  'Eine neue Maschine wurde erfolgreich hinzugefügt.',
                machineUpdated: 'Die Maschine wurde erfolgreich aktualisiert.',
                machineTypeAdded:
                  'Ein neuer Maschinentyp wurde erfolgreich hinzugefügt.',
                machineTypeUpdated:
                  'Der Maschinentyp wurde erfolgreich aktualisiert.',
                machineTypeDeleted:
                  'Der Maschinentyp wurde erfolgreich gelöscht.',
                machineConfigSent:
                  'Die Maschinenkonfiguration wurde erfolgreich gesendet.',
              },
            },

            sensor: {
              fields: {
                sensorID: 'Sensor ID',
                measurementUnit: 'Maßeinheit',
              },

              errors: {
                sensorIdRequired:
                  'Die Sensor ID ist erforderlich. Kann nicht leer gelassen werden.',
                aliasRequired:
                  'Der Sensor Alias ist erforderlich. Kann nicht leer gelassen werden.',
                sensorTypeRequired:
                  'Der Sensortyp ist erforderlich. Kann nicht leer gelassen werden.',
                isActiveUndefined:
                  'Aktives Umschalten ist erforderlich. Kann nicht undefiniert bleiben.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                sensorNameRequired:
                  'Der Sensorname ist erforderlich. Kann nicht leer gelassen werden.',
                measurementUnitRequired:
                  'Die Maßeinheit ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                sensorAdded: 'Ein neuer Sensor wurde erfolgreich hinzugefügt.',
                sensorUpdated: 'Der Sensor wurde erfolgreich aktualisiert.',
                sensorDeleted: 'Der Sensor wurde erfolgreich gelöscht.',
                sensorTypeAdded:
                  'Ein neuer Sensortyp wurde erfolgreich hinzugefügt.',
                sensorTypeUpdated:
                  'Der Sensortyp wurde erfolgreich aktualisiert.',
                sensorTypeDeleted: 'Sensortyp erfolgreich gelöscht.',
              },
            },

            structure: {
              fields: {
                country: 'Land',
                parentStructure: 'Übergeordnete Struktur',
                structure: 'Struktur',
              },

              errors: {
                notAuthorized:
                  'Sie sind nicht berechtigt, diese Struktur anzuzeigen.',
                structureDoesntExist:
                  'Die Struktur, auf die Sie zugreifen möchten, ist nicht vorhanden.',
                structureNameRequired:
                  'Der Strukturname ist erforderlich. Kann nicht leer gelassen werden.',
                structureCityRequired:
                  'Der Ort ist erforderlich. Kann nicht leer gelassen werden.',
                structureCountryRequired:
                  'Das Land ist erforderlich. Kann nicht leer gelassen werden.',
                structureTimezoneRequired:
                  'Die Zeitzone der Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                genericError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
              },

              success: {
                structureAdded:
                  'Eine neue Struktur wurde erfolgreich hinzugefügt.',
                structureUpdated:
                  'Die Struktur wurde erfolgreich aktualisiert.',
                structureDeleted: 'Die Struktur wurde erfolgreich gelöscht.',
              },
            },

            user: {
              fields: {
                machinesPerPage: 'Machine pro Seite',
                unit: 'Einheit',
                email: 'E-Mail',
                password: 'Passwort',
                businessUserId: 'Geschäftsnutzer-ID',
                fullName: 'Vollständiger Name',
                confirmPassword: 'Passwort Bestätigen',
                username: 'Benutzername',
                pin: 'PIN',
                confirmPin: 'PIN bestätigen',
                language: 'Sprache',
                role: 'Rolle',
                defaultView: 'Standardansicht',
              },

              errors: {
                otherError:
                  'Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal.',
                invalidEmailFormat:
                  'Ungültiges E-Mail Format. Sollte user@site.com sein.',
                roleNameRequired:
                  'Der Rollenname ist erforderlich. Kann nicht leer gelassen werden.',
                fullNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
                emailRequired:
                  'Die E-Mail ist erforderlich. Kann nicht leer gelassen werden.',
                passwordRequired:
                  'Das Passwort ist erforderlich. Kann nicht leer gelassen werden.',
                passwordMismatch:
                  'Das Passwort stimmt nicht überein. Bitte versuche es erneut.',
                passwordLength:
                  'Das Passwort muss mindestens 8 Zeichen lang sein.',
                businessidInUse:
                  'Die Geschäftsnutzer-ID wird bereits verwendet. Bitte wählen Sie eine andere.',
                languageRequired:
                  'Die Sprache ist erforderlich. Kann nicht leer gelassen werden.',
                roleRequired:
                  'Die Benutzerrolle ist erforderlich. Kann nicht leer gelassen werden.',
                structuresRequired:
                  'Die Strukturen sind erforderlich. Kann nicht leer gelassen werden.',
                structureViewRequired:
                  'Die Strukturansicht ist erforderlich. Kann nicht leer gelassen werden.',
                structureRequired:
                  'Eine Struktur ist erforderlich. Kann nicht leer gelassen werden.',
                machineViewRequired:
                  'Die Maschinenansicht ist erforderlich. Kann nicht leer gelassen werden.',
                machineRequired:
                  'Eine Maschine ist erforderlich. Kann nicht leer gelassen werden.',
                homeViewRequired:
                  'Die Standardansicht ist erforderlich. Kann nicht leer gelassen werden.',
                adminCannotBeDeleted:
                  'Die Systemadministratorrolle kann nicht gelöscht werden!',
                userAlreadyExists:
                  'Ein Benutzer mit dieser Email existiert bereits.',
              },

              success: {
                roleAdded:
                  'Eine neue Benutzerrolle wurde erfolgreich hinzugefügt.',
                roleUpdated:
                  'Die Benutzerrolle wurde erfolgreich aktualisiert.',
                roleDeleted: 'Die Benutzerrolle wurde erfolgreich gelöscht',
                userAdded: 'Ein neuer Benutzer wurde erfolgreich hinzugefügt.',
                userUpdated: 'Der Benutzer wurde erfolgreich aktualisiert.',
                userDeleted: 'Der Benutzer wurde erfolgreich gelöscht.',
              },
            },

            reason: {
              fields: {
                name: 'Name',
                identifier: 'Kennung',
                description: 'Beschreibung',
                color: 'Farbe',
                category: 'Kategorie',
                machineType: 'Maschinentyp',
                roles: 'Benutzerrollen',
                origin: 'Datenquelle',
                reasonConfiguration: 'Konfiguration Stillstandsgründe',
              },

              misc: {
                staticReasonActive: "Statischer Grund ist aktiv!",
                machineStatus: 'Maschinenstatus',
                reason: 'Stillstandsgrund',
                note: 'Notiz',
                assignReason: 'Stillstandsgrund',
                assignStaticReason: 'Fester Stillstandsgrund',
                changeReason: 'Stillstandsgrund ändern',
                editReason: 'Stillstandsgrund bearbeiten',
                editStaticReason: 'Festen Stillstandsgrund bearbeiten',
                removeReason: 'Stillstandsgrund entfernen',
                loadingReasons: 'Stillstandsgründe werden geladen...',
              },

              errors: {
                staticReasonAdded: 'Ein neuer statischer Stillstandsgrund wurde für diese Maschine gesetzt.',
                staticReasonDeleted: 'Der statische Stillstandsgrund wurde für diese Maschine gelöscht.',
                reasonNameRequired:
                  'Der Name für den Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
                reasonIdentifierRequired:
                  'Die Kennung des Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
                reasonColorRequired:
                  'Die Farbe ist erforderlich. Kann nicht leer gelassen werden.',
                reasonCategoryRequired:
                  'Die Kategorie ist erforderlich. Kann nicht leer gelassen werden.',
                reasonOriginRequired:
                  'Die Datenquelle ist erforderlich. Kann nicht leer gelassen werden.',
                reasonRequired:
                  'Der Stillstandsgrund ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                reasonAdded:
                  'Ein neuer Stillstandsgrund wurde erfolgreich hinzugefügt.',
                reasonUpdated:
                  'Der Stillstandsgrund wurde erfolgreich aktualisiert.',
                reasonDeleted:
                  'Der Stillstandsgrund wurde erfolgreich gelöscht.',
              },
            },

            terminal: {
              fields: {
                username: 'Benutzername',
                pin: 'PIN',
                logout: 'Logout',
              },

              errors: {
                terminalUsernameLength:
                  'Der Benutzername muss mindestens 3 Zeichen lang sein.',
                terminalPinLength:
                  'Ungültige PIN-Länge. Die PIN sollte 4 Nummern lang sein.',
                terminalInvalidPinFormat:
                  'Ungültiges PIN-Format. Die PIN kann nur Zahlen enthalten.',
              },
            },

            machineStatus: {
              fields: {
                name: 'Name',
                identifier: 'Kennung',
                color: 'Farbe',
                category: 'Kategorie',
                machineType: 'Maschinentyp',
                origin: 'Datenquelle',
                machineStatusConfiguration: 'Maschinenstatuskonfiguration',
                activity: 'Aktivität',
                dataLayer: 'Data Layer',
              },

              errors: {
                reasonNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                reasonAdded:
                  'Ein neuer Stillstandsgrund wurde erfolgreich hinzugefügt.',
                reasonUpdated:
                  'Der Stillstandsgrund wurde erfolgreich aktualisiert.',
                reasonDeleted:
                  'Der Stillstandsgrund wurde erfolgreich gelöscht.',
              },
            },

            category: {
              errors: {
                categoryNameRequired:
                  'Der Name ist erforderlich. Kann nicht leer gelassen werden.',
              },

              success: {
                categoryAdded:
                  'Eine neue Kategorie wurde erfolgreich hinzugefügt.',
                categoryUpdated:
                  'Die Kategorie wurde erfolgreich aktualisiert.',
                categoryDeleted: 'Die Kategorie wurde erfolgreich gelöscht.',
              },
            },

            shared: {
              fields: {
                name: 'Name',
                businessID: 'Business ID',
                description: 'Beschreibung',
                city: 'Ort',
                inactive: 'Inaktiv',
                active: 'Aktiv',
                disabled: 'Deaktiviert',
                enabled: 'Aktiviert',
                timezone: 'Zeitzone',
                none: 'Keiner',
                alias: 'Alias',
                config: 'Koniguration',
                configSelect: "Konfiguration Auswahl",
                type: 'Typ',
                structure: 'Struktur',
                structures: 'Strukturen',
                sensors: 'Sensoren',
                sortIndex: 'Sortierindex',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                duration: 'Laufzeit',
                jobId: 'Job ID',
                partsProduced: 'Produzierte Teile',
                targetCount: 'Soll Menge',
                realisticCycleTime: 'Realistische Zyklus Zeit',
                targetSetupTime: 'Soll-Setup Zeit',
                actualCount: 'Tatsächliche Menge',
                reason: 'Ausfallgrund',
                status: 'Status',
              },

              buttons: {
                merge: 'Zusammenführen',
                reset: 'Zurücksetzen',
                submit: 'Anwenden',
                save: 'Speichern',
                clear: 'Inhalte löschen',
                close: 'Schließen',
                cancel: 'Abbrechen',
                updatePulse: 'Anwenden',
                goBack: 'Zurück',
                activate: 'Aktivieren',
                back: 'Zurück',
                next: 'Weiter',
                saveChanges: 'ÄNDERUNGEN SPEICHERN',
                add: 'Hinzufügen',
                remove: 'Entfernen',
                edit: 'Bearbeiten',
              },
            },
          },

          tables: {
            shared: {
              active: 'Aktiv',
              inactive: 'Inaktiv',
              assigned: 'Zugewiesen',
              unassigned: 'Nicht zugewiesen',
              machine: 'Maschine',
              machines: 'Maschinen',
              actions: 'Aktionen',
              machineInformation: 'Maschineninformationen',
              product: 'Produkt',
              categories: 'Kategorien',
              reasons: 'Stillstandsgründe',
              machineStatuses: 'Maschinenstatus',
            },
            noData: 'Entschuldigung, es wurden keine passenden Einträge gefunden.',
            sensor: {
              registrationDate: 'Registrierungsdatum',
              lastSeen: 'Zuletzt gesehen',
            },
            textLabels: {
              body: {
                noMatch: "Entschuldigung, es wurden keine passenden Einträge gefunden",
                noData: "Entschuldigung, es liegen noch keine Daten vor",
                toolTip: "Sortieren",
                columnHeaderTooltip: column => `Sortieren nach ${column.label}`
              },
              pagination: {
                next: "Nächste Seite",
                previous: "Vorherige Seite",
                rowsPerPage: "Reihen pro Seite:",
                displayRows: "von",
              },
              toolbar: {
                search: "Suchen",
                downloadCsv: "Download CSV",
                print: "Ausdrucken",
                viewColumns: "Spalten anzeigen",
                filterTable: "Tabelle filtern",
              },
              filter: {
                all: "Alle",
                title: "FILTER",
                reset: "ZURÜCKSETZEN",
              },
              viewColumns: {
                title: "Zeig Spalte ",
                titleAria: "Zeige/Verdecke Tabellenspalten",
              },
              selectedRows: {
                text: "ausgewählt(e) Reihe(n)",
                delete: "Löschen",
                deleteAria: "Lösche ausgewählte Reihen",
              },
            }
          },

          confirmationModal: {
            textDeleteStaticReason: "Sind Sie sicher, dass Sie den statischen Stillstandsgrund beenden wollen? Maschine: ",
            deactivateMachines: 'Maschinen deaktivieren',
            areYouSureDeactivateMachines:
              'Sind Sie sicher, dass Sie die ausgewählten Maschinen deaktivieren wollen?',
            areYouSureDeactivateMachine:
              'Sind Sie sicher, dass Sie die ausgewählte Maschine deaktivieren wollen?',
            confirm: 'Bestätigen',
            cancel: 'Abbrechen',
            deactivateUsers: 'Benutzer deaktivieren',
            areYouSureDeactivateUsers:
              'Sind Sie sicher, dass Sie die ausgewählten Benutzer deaktivieren wollen?',
            areYouSureDeactivateUser:
              'Sind Sie sicher, dass Sie den ausgewählten Benutzer deaktivieren wollen?',
            deactivateStructures: 'Strukturen deaktivieren',
            areYouSureDeactivateStructures:
              'Sind Sie sicher, dass Sie die ausgewählten Strukturen deaktivieren wollen?',
            areYouSureDeactivateStructure:
              'Sind Sie sicher, dass Sie die ausgewählte Struktur deaktivieren wollen?',
            deactivateSensors: 'Sensoren deaktivieren',
            areYouSureDeactivateSensors:
              'Sind Sie sicher, dass Sie die ausgewählten Sensoren deaktivieren wollen?',
            areYouSureDeactivateSensor:
              'Sind Sie sicher, dass Sie den ausgewählten Sensoren deaktivieren wollen?',
            deactivateReasons: 'Stillstandsgründe deaktivieren',
            areYouSureDeactivateReasons:
              'Sind Sie sicher, dass Sie die ausgewählten Stillstandsgründe deaktivieren wollen?',
            areYouSureDeactivateReason:
              'Sind Sie sicher, dass Sie den ausgewählten Stillstandsgrund deaktivieren wollen?',
          },

          machineStatusesText: {
            info: "Info",
            machineStatusColorHeading: "Statusfarbe",
            machineStatusNameHeading: "Titel",
            machineReasonNameHeading: "Ausfallgrund Titel",
            machineReasonColorHeading: "Ausfallgrund Farbe",
            machineStatusDescriptionHeading: "Beschreibung",
            running: 'Produzierend',
            scheduled: 'Geplant produzierend',
            slow_running: 'Langsam produzierend',
            not_running: 'Ungeplanter Stillstand',
            unscheduled: 'Geplanter Stillstand',
            creep: 'Pausenüberzieher',
            unscheduled_PO_presence: 'Unbesetzt',
            not_running_PO_presence: 'Unbesetzt',
            running_PO_presence: 'Besetzt',
            running_PO_EOL: 'Prüfung läuft',
            waiting_PO_EOL: 'Warten auf Teil',
            test_done_PO_EOL: 'Prüfung fertig',
            not_running_PO_EOL: 'Stillstand',
            unscheduled_PO_EOL: 'Ungeplant',
            running_PO_rework: 'Produktiv',
            occupied_PO_rework: 'Besetzt',
            not_running_PO_rework: 'Unbesetzt',
            unscheduled_PO_rework: 'Unbesetzt',
            maintenance: 'Wartung',
            descriptions: {
              machineStatusColorHeading: "Lorem Ipsum",
              machineStatusNameHeading: "Lorem Ipsum",
              machineStatusDescriptionHeading: "Lorem Ipsum",
              running: 'Lorem Ipsum',
              scheduled: 'Lorem Ipsum',
              slow_running: 'Lorem Ipsum',
              not_running: 'Lorem Ipsum',
              unscheduled: 'Lorem Ipsum',
              creep: 'Lorem Ipsum',
              unscheduled_PO_presence: 'Lorem Ipsum',
              not_running_PO_presence: 'Lorem Ipsum',
              running_PO_presence: 'Lorem Ipsum',
              running_PO_EOL: 'Lorem Ipsum',
              waiting_PO_EOL: 'Lorem Ipsum',
              test_done_PO_EOL: 'Lorem Ipsum',
              not_running_PO_EOL: 'Lorem Ipsum',
              unscheduled_PO_EOL: 'Lorem Ipsum',
              running_PO_rework: 'Lorem Ipsum',
              occupied_PO_rework: 'Lorem Ipsum',
              not_running_PO_rework: 'Lorem Ipsum',
              unscheduled_PO_rework: 'Lorem Ipsum',
              maintenance: 'Lorem Ipsum',
            }
          },

          reasonsText: {
            quality: 'Qualitätskontrolle',
            machine_malfunction: 'Maschinenstörung',
            tool_change: 'Werkzeugwechsel (Verschleiß)',
            setup: 'Einrichten/Rüsten',
            tool_breakage: 'Werkzeugdefekt',
            lack_of_personnel: 'Fehlendes Personal',
            defective_purchase_part: 'Mangelhaftes Zukaufteil',
            other_break: 'Andere Unterbrechung',
            repair_pneumatics: 'Reparatur Pneumatik',
            coil_change: 'Coilwechsel / Materialwechsel',
            defective_material: 'Materialdefekt',
            material_lack: 'Fehlendes Material',
            repair_hydraulics: 'Reparatur Hydraulik',
            unavailable: 'Nicht verfügbar',
            cleaning: 'Reinigung / Wartung',
            unscheduled: 'Geplanter Stillstand',
            repair_mechanics: 'Reparatur Mechanik',
            no_qc_approval: 'Q.S. Freigabe ausstehend',
            follow_up_process: 'Folgeprozess nicht verfügbar',
            quality_issue: 'Qualitätsmangel',
            welder_adjustments: 'Einstellung Schweißanlage',
            repair_electrical: 'Reparatur Elektrik',
            preventive_maintenance: 'Präventive Wartung',
            break: 'Pause',
            machine_adjustments: 'Justage der Anlage',
            missing_order: 'Fehlender Auftrag',
            repair_category: 'Reparatur',
            other_reason: 'Sonstiges (z.B. Besprechung, Stromausfall etc.)',
            601: '601 - Drucker',
            602: '602 - Elektrode',
            603: '603 - Flanschelektrode',
            604: '604 - Platine',
            605: '605 - Längsschweißung',
            606: '606 - Querschweißung',
            607: '607 - Teller',
            608: '608 - Töpfe',
            609: '609 - Spender',
            610: '610 - Lichtschranke',
            611: '611 - Steuerung',
            612: '612 - Spreizung',
            613: '613 - Greifer',
            614: '614 - Glättung',
            615: '615 - Paddel',
            616: '616 - Säule',
            617: '617 - Sockel',
            618: '618 - Entnahme',
            619: '619 - Transport',
            620: '620 - Sicherheit',
            621: '621 - Stempel',
            622: '622 - Farbband',
            623: '623 - allgemein',
            624: '624 - Druckluft',
            401: '401 - Drucker',
            402: '402 - Elektrode',
            403: '403 - Flanschelektrode',
            404: '404 - Platine',
            405: '405 - Längsschweißung',
            406: '406 - Querschweißung',
            407: '407 - Teller',
            408: '408 - Töpfe',
            409: '409 - Spender',
            410: '410 - Lichtschranke',
            411: '411 - Steuerung',
            412: '412 - Spreizung',
            413: '413 - Greifer',
            414: '414 - Glättung',
            415: '415 - Paddel',
            416: '416 - Säule',
            417: '417 - Sockel',
            418: '418 - Entnahme',
            419: '419 - Transport',
            420: '420 - Stempel',
            421: '421 - Farbband',
            422: '422 - allgemein',
            423: '423 - Druckluft',
            301: '301 - Drucker',
            302: '302 - Elektrode',
            303: '303 - Flanschelektrode',
            304: '304 - Platine',
            305: '305 - Längsschweißung',
            306: '306 - Querschweißung',
            307: '307 - Teller',
            308: '308 - Töpfe',
            309: '309 - Spender',
            310: '310 - Lichtschranke',
            311: '311 - Steuerung',
            312: '312 - Spreizung',
            313: '313 - Greifer',
            314: '314 - Glättung',
            315: '315 - Paddel',
            316: '316 - Säule',
            317: '317 - Sockel',
            318: '318 - Entnahme',
            319: '319 - Transport',
            320: '320 - Sicherheit',
            321: '321 - Stempel',
            322: '322 - Farbband',
            323: '323 - allgemein',
            324: '324 - Druckluft',
            201: '201 - Drucker',
            202: '202 - Elektrode',
            203: '203 - Flanschelektrode',
            204: '204 - Platine',
            205: '205 - Längsschweißung',
            206: '206 - Querschweißung',
            207: '207 - Teller',
            208: '208 - Töpfe',
            209: '209 - Spender',
            210: '210 - Lichtschranke',
            211: '211 - Spreizung',
            212: '212 - Greifer',
            213: '213 - Glättung',
            214: '214 - Paddel',
            215: '215 - Säule',
            216: '216 - Sockel',
            217: '217 - Entnahme',
            218: '218 - Transport',
            219: '219 - Sicherheit',
            220: '220 - Stempel',
            221: '221 - Farbband',
            222: '222 - allgemein',
            223: '223 - Druckluft',
            101: '101 - Drucker',
            102: '102 - Elektrode',
            103: '103 - Flanschelektrode',
            104: '104 - Längsschweißung',
            105: '105 - Querschweißung',
            106: '106 - Spender',
            107: '107 - Lichtschranke',
            108: '108 - Steuerung',
            109: '109 - Spreizung',
            110: '110 - Greifer',
            111: '111 - Glättung',
            112: '112 - Paddel',
            113: '113 - Säule',
            114: '114 - Sockel',
            115: '115 - Entnahme',
            116: '116 - Transport',
            117: '117 - Sicherheit',
            not_defined: 'Unbestimmt',
            break_creep: 'Pausenüberzieher',
            not_defined_PO_presence: 'Unbestimmt',
            break_creep_PO_presence: 'Pausenüberzieher',
            micro_stop: 'Kurzunterbrechung'
          },

          scrapTypesText: {
            coil_change: 'Coilwechsel',
            defective_material: 'Materialfehler',
            setup: 'Einrichtung',
            quality_testing: 'Qualitätskontrolle',
          },
        };

      case 'IT':
      case 'it':
        return {
          nav: {
            favorites: 'Preferiti',
            profile: 'Profilo',
            settings: 'Impostazioni',
            logout: 'Disconnettersi',
            collapseSidebar: 'Comprimere la barra laterale',
            home: 'Home',
            structures: 'Strutture',
            machines: 'Macchine',
            sensors: 'Sensori',
            users: 'Utenti',
            manageStructures: 'Strutture',
            manageMachines: 'Macchine',
            roles: 'Ruolo',
            sensorTypes: 'Tipi di Sensori',
            machineTypes: 'Tipo di macchina',
            structureOverview: 'Panoramica della Struttura',
            machineOverview: 'Panormacia della Macchina',
            reasons: 'Motivi',
            reasonsManagement: 'Gestione delle Ragioni',
            categoriesManagement: 'Gestione delle Categorie' ,
            machineStatusesManagement: 'Gestione dello stato della Macchina',
            terminal: 'Terminale',
            oee: 'OEE',
            report: 'Rapporto',
            advanced_report: 'Rapporto Avanzato',
            schedule: 'Programma',
            andon: 'Andon',
            views: 'Visualizzazioni',
            management: 'Gestione',
            orders: 'Ordini',
            orders_export_validation: "Convalida dell’esportanzione degli ordini",
            supportTicket: 'Biglietto di Supporto',
            supportPage: 'Pagina di Supporto',
            help: 'Aiuto',
            machineNaming: 'Denominazione della Macchina',
            machinePerformance: 'Prestaziono della Macchina',
            jobs: "Lavori",
            reloadTimes: {
              "0": "Nessuno",
              "10": "Veloce",
              "60": "Normale",
              "300": "Lento",
              "3600": "Molto lento",
            }
          },

          machineFilters: {
            default: 'predefinito',
            availabilityminmax: 'disponibilità-min-max',
            availabilitymaxmin: 'disponibilità-max-min',
            performanceminmax: 'prestazione-min-max',
            performancemaxmin: 'prestazione-max-min',
            qualityminmax: 'qualità-min-max',
            qualitymaxmin: 'qualità-max-min',
          },

          pageTitles: {
            sensorsManagement: 'Gestione dei sensori',
            sensorTypesManagement: 'Gestione Tipi di sensori',
            usersManagement: 'Gestione degli utenti',
            rolesManagement: 'Gestione dei ruoli',
            machinesMangement: 'Gestione macchine',
            machineTypesManagement: 'Gestione tipologie macchina',
            structuresManagement: 'Gestione strutture',
            signIn: 'Registrazione',
            help: 'Aiuto',
          },

          tabs: {
            CreateStructure: 'Crea struttura',
            ExistingStructures: 'Strutture Esistenti',
            CreateMachine: 'Crea Macchina',
            ExistingMachines: 'Macchine Esistenti',
            CreateSensor: 'Crea sensore',
            ExistingSensors: 'Sensori esistenti',
            CreateUser: 'Creare un utente',
            ExistingUsers: 'Utenti esistenti',
            CreateRole: 'Crea ruolo',
            ExistingRoles: 'Ruoli esistenti',
            CreateSensorType: 'Crea tipo sensore',
            ExistingSensorTypes: 'Tipi di sensori esistenti',
            CreateMachineType: 'Crea tipo di macchina',
            ExistingMachineTypes: 'Tipi di macchine esistenti',
            CreateReason: 'Crea ragione',
            ExistingReasons: 'Ragioni esistenti',
            CreateCategory: 'Crea categoria',
            ExistingCategories: 'Categorie esistenti',
            CreateMachineStatus: 'Crea stato macchina',
            ExistingMachineStatuses: 'Stati della macchina esistente',
            UserLogin: 'Login utente',
            AppLogin: 'Accesso all\'app',
            TerminalLogin: 'Accesso al terminale',
            SimpleView2Login: 'Accesso alla panoramica della macchina',
            SensorHealth: 'Salute del sensore',
          },

          errorMessages: {
            emailOrPasswordIncorret: 'email o password errate.',
            notAuthorized: 'Non sei autorizzato a farlo',
            pageNotFound: 'La pagina a cui stai tentando di accedere non esiste.',
            moduleNotFound:
              'Il modulo a cui stai tentando di accedere non è disponibile.',
            notAuthorizedToViewMachine:
              'Non sei autorizzato a visualizzare questa macchina.',
            machineDoesNotExist:
              'La macchina a cui stai tentando di accedere non esiste.',
              noMaxCycleTime: 'Nessun "Tempo di ciclo massimo" configurato per questa macchina.',
          },

          misc: {
            selectStructureYouWantToView:
              'Seleziona una struttura che desideri visualizzare.',
            selectMachineYouWantToView: 'Seleziona una macchina che desideri visualizzare.',
            toContinueToLeanFocus: 'Per continuare con LEANFOCUS',
            noMachinesInThisStructure: 'Non ci sono macchine in questa struttura',
            loading: 'Caricamento...',
            minAvailability: 'Min. Disponibilità',
            maxAvailability: 'Max. Disponibilità',
            copyUrl: 'Copia l\'URL',
            urlCopySuccess: 'URL copiato correttamente',
            availability: 'Disponibilità',
            time: 'Tempo',
            search: 'Ricerca',
            overview: 'Panoramica',
            showMachines: 'Mostra macchine',
            machineStatusDistribution: 'Distribuzione dello stato della macchina',
            machineStatusTimeDistribution: 'Distribuzione temporale dello stato della macchina',
            machineStatus: 'Stato delle macchine',
            deselectAll: 'Deselezionare tutto',
            sortBy: 'Ordina per',
            default: 'Predefinito',
            for: 'per',
            with: 'con',
            average: 'ad una media di',
            applyFilters: 'Applica filtri',
            all: 'Tutto',
            title: 'Filtri\' titolo',
            reset: 'Ripristina',
          },

          modal: {
            EditStructure: 'Modifica struttura',
            EditMachine: 'Modifica macchina',
            EditSensor: 'Modifica sensore',
            EditUser: 'Modifica utente',
            EditRole: 'Modifica ruolo',
            EditSensorType: 'Modifica tipo di sensore',
            EditMachineType: 'Modifica tipo di macchina',
            CreateSensorType: 'Crea tipo di sensore',
            CreateMachineType: 'Crea tipo di macchina',
            EditReason: 'Modifica motivo',
            EditCategory: 'Modifica categoria',
            EditMachineStatus: 'Modifica stato macchina',
          },

          timespanSelection: {
            last: 'durare',
            from: 'Da',
            to: 'a',
            Last: 'Durare',
            Hour: 'Ora',
            Hours: 'Ore',
            Minutes: 'Minuti',
            Minute: 'Minuto',
            Days: 'Giorni',
            Day: 'Giorno',
            Weeks: 'Settimane',
            Week: 'Settimana',
            LastHour: 'Ultima ora',
            Last6Hours: 'Ultime 6 ore',
            Last12Hours: 'Ultime 12 ore',
            Last24Hours: 'Ultime 24 ore',
            Last7Days: 'Ultimi 7 giorni',
            ShowLastHour: 'Mostra l\'ultima ora',
            ShowLast6Hours: 'Mostra le ultime 6 ore',
            ShowLast12Hours: 'Mostra le ultime 12 ore',
            ShowLast24Hours: 'Mostra le ultime 24 ore',
            ShowLast7Days: 'Mostra gli ultimi 7 giorni',
            Relative: 'Parente',
            DateRange: 'Intervallo di date',
            CustomTimePreset: "Preimpostazione dell'ora personalizzata",
            ctpTitleAlreadyPresent: "Il titolo del nuovo preset è già presente nella lista. Per favore scegline un altro",
            ctpTitleEmpty: "Il titolo fornito è vuoto o non valido",
            newPresetTitle: "Titolo",
            addNewPreset: "Aggiungi una nuova preimpostazione",
            addNewPresetDescription: "Aggiungi il titolo della preimpostazione e le ore di inizio e fine.",
            deletePresetModalTitle: "Elimina la preimpostazione personalizzata",
            addPresetModalTitle: "Aggiungi preimpostazione personalizzata",
            areYouSureDeleteTimePreset: "Sei sicuro di voler eliminare la preimpostazione?",
            dayOfPreset: "Giorno preimpostato",
            CustomBegin: "Inizio",
            CustomEnd: "Fine",
            SelectTimespan: 'Seleziona Intervallo di tempo',
            Today: 'Oggi',
            ShowPulseForToday: 'Mostra polso per oggi',
            Yesterday: 'Ieri',
            ShowPulseForYesterday: 'Mostra Pulse per ieri',
            ThisWeek: 'Questa settimana',
            ThisMonth: 'Questo mese',
            ThisYear: 'Quest\'anno',
            ShowPulseForThisWeek: 'Mostra Pulse per questa settimana',
            ShowPulseForThisMonth: 'Mostra Pulse per questo mese',
            ShowPulseForThisYear: 'Mostra Pulse per quest\'anno',
            LastWeek: 'La settimana scorsa',
            ShowPulseForLastWeek: 'Mostra Pulse per la scorsa settimana',
            Presets: 'Preimpostazioni',
            CustomPresets: 'Preimpostazioni personalizzate',
            RestoreDefaultTimespan: 'Ripristina l\'intervallo di tempo predefinito',
            localeDef: {
                "decimal": ".",
                "thousands": ",",
                "grouping": [3],
                "currency": ["$", ""],
                "dateTime": "%a %b %e %X %Y",
                "date": "%Y-%m-%d",
                "time": "%H:%M:%S",
                "periods": ["AM", "PM"],
                "days": ["la Domenica", "il Lunedi", "il Martedi", "il Mercoledi", "il Giovedi", "il Venerdi", "il Sabato"],
                "shortDays": ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
                "months": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
                "shortMonths": ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
            },
            timeFormat: "MMMM do - HH:mm",
            dateFormat: "MMMM do",
          },

          modules: {
            andon: {
              config: 'CONFIGURAZIONE ANDON',
              theme: 'Tema',
              light: 'Luminoso',
              dark: 'Buio',
              preview: 'ANTEPRIMA ANDON',
              loadingMachines: 'Caricamento macchine',
              loadingStructure: 'Struttura di caricamento',
              noStructureSelected: 'Nessuna struttura selezionata.',
              noMachines: 'Nessuna macchina nella struttura selezionata.',
              slideshowInterval: 'Intervallo della presentazione',
              set: 'stabilire',
              minutes: 'Minuti',
              seconds: 'Secondi',
              minutesAbbr: 'Min',
              secondsAbbr: 'Sec',
              machines: 'Macchine',
              machinesLabel: 'Macchine',
              running: 'Corsa',
              machineTypes: 'Tipi di macchina',
              machineTypesLabel: 'Tipi di macchina',
              on: 'Acceso',
              off: 'Spento',
              filter: 'Filtro',
            },
            machine: {
              legendModalTitle: "Panoramica sulle definizioni",
              staticReason: "Motivo",
              statusTime: "Tempo",
              loadingPulse: 'Caricamento dell\'impulso di produzione...',
              runtime: 'Durata',
              downtime: 'tempi di inattività',
              running: 'corsa',
              notRunning: 'Non correndo',
              online: 'in linea',
              lastSeen: 'Ultima visualizzazione',
              never: 'mai',
              duration: 'Durata',
              state: 'Stato',
              machine: 'Macchina',
              drillDown: 'Scavare a fondo',
              machinesPermissions: 'Autorizzazioni macchine',
              machineTypesPermission: 'Autorizzazioni Tipi di macchina',
              machineStatusesPermission: 'Autorizzazioni sugli stati della macchina',
              availabilityLowToHigh: 'Disponibilità: da bassa ad alta',
              availabilityHighToLow: 'Disponibilità: da alta a bassa',
              performanceLowToHigh: 'Prestazioni: da basse ad alte',
              performanceHighToLow: 'Prestazioni: da alte a basse',
              loadingMachine: 'Caricamento macchina...',
              partsPerMinute: 'Parti/Minuto',
              secPerPart: 'Sez/Parte',
              partsPerMinuteString: 'parti al minuto',
              secPerPartString: 'secondi per parte',
              currentSpeedTarget: 'Velocità attuale (obiettivo)',
              selectMachine: 'Seleziona una macchina',
              sortBy: 'Ordina per',
              qualityLowToHigh: 'Qualità: da bassa ad alta',
              qualityHighToLow: 'Qualità: da alta a bassa',
              speedTarget: 'Ø Velocità (bersaglio)',
              speedJob: 'Velocità del lavoro (obiettivo)',
              splitState: 'Stato diviso',
              mergeState: 'Unire gli Stati',
              merge: 'Unisci',
              addPreviousState: 'Aggiungi lo stato precedente',
              addNextState: 'Aggiungi lo stato successivo',
              noMoreStates: 'Nessun altro stato disponibile nell\'intervallo di tempo selezionato',
              splitStateSuccessful: 'Lo stato è stato diviso con successo.',
              mergeStateSuccessful: 'Gli stati sono stati uniti con successo.',
            },
            simple_machine: {
              default: "Ciao",
              parts: "Parti",
              shiftGoalLabel: "Maiusc\n[bold]Obiettivo[/]",
              shiftGoal: "Obiettivo del cambio",
              timeGoal: "Obiettivo temporale",
              unit: "Parti",
              gauge: "Misura",
              progress: "Barra di avanzamento",
              targetCylceTime: 'Tempo di ciclo target',
              currentSpeed: 'Velocità attuale',
              partsPerHour: 'Parti/ora',
            },
            simple_machine2: {
              currentSpeed: 'Attuale',
              targetSpeed: 'Bersaglio',
              shiftSpeed: "strato",
              currentOrder: 'Ordine attuale',
              customer: 'Cliente',
              orderLoading: 'Caricamento ordine...', 
              product: "Prodotto",
              order: "Ordine",
              shiftStart: "Inizio turno",
              shiftEnd: "Fine turno",
            },
            order_export: {
              isWorkingOrderComplete: "L'ordine di lavoro completato",
              totalScrap: "Comitato",
              isSetup: "Setup",
              intendedMachineNotEqualtoMachineTitle: "Macchina pianificata non uguale alla macchina",
              intendedMachineNotEqualtoMachine: "La macchina pianificata non è uguale alla macchina selezionata. Sei sicuro di voler continuare?",
              noPartsProduced: "Nessuna parte prodotta",
              handCountWarning: `Conteggio manuale superiore al ${parseInt(HANDCOUNT_THRESHOLD*100)}% dei pezzi prodotti`,
              label: "Autorizzazioni per l'esportazione degli ordini",
              selectMachine: "Seleziona Macchina",
              selectStructure: "Seleziona Struttura",
              selectOrder: "Seleziona l'ordine",
              activations: "attivazioni",
              article_id: "Articolo",
              operator: "operatore",
              startDate: "Inizio",
              endDate: "Fine",
              oee: "OEE",
              mandant: "Mandante",  
              editActivation: "Modifica attivazione",         
              deleteActivation: "Elimina attivazione",
              addActivation: "Aggiungi attivazione",
              qualityReports: "Rapporti sulla qualità",
              job: "Lavoro",
              activationStatus: "Stato di attivazione",
              handCount: "Manuale",
              user: "Operatore",
              partsProduced: "Sensore",
              machine: "Macchina",
              editActivationModalTitle: "Modifica attivazione",
              addActivationModalTitle: "Aggiungi attivazione",
              activationUpdated: "L'attivazione è stata aggiornata con successo",
              deleteActivationModalTitle: "Elimina attivazione",
              activationAdded: "L'attivazione è stata aggiunta con successo",
              userNotFound: "Utente non trovato",
              jobNotFound: "Lavoro non trovato",
              productNotFound: "Prodotto non trovato",
              genericError: "Qualcosa è andato storto",
              activationDeleted: "L'attivazione è stata eliminata con successo",
              deleteError: "La cancellazione dell'attivazione non è riuscita",
              deleteActivationModalText: "Sei sicuro di voler eliminare l'attivazione?",
              qualityReportsList: "Rapporti sulla qualità",
              selectedJob: "Lavoro selezionato",
              errors: {
                activationAlreadyPresent: "L'attivazione è già presente per la macchina e il lavoro selezionati",
                activationStatusRequired: "Lo stato di attivazione è obbligatorio",
                operatorRequired: "L'operatore è obbligatorio",
                handCountRequired: "È richiesto il conteggio manuale",
                partsProducedRequired: "Le parti prodotte sono obbligatorie",
                startTimeRequired: "L'ora di inizio è obbligatoria",
                endTimeRequired: "L'ora di fine è obbligatoria",
                endTimeBeforeStartTime: "L'ora di fine deve essere successiva all'ora di inizio",
                productRequired: "Il prodotto è obbligatorio",
                jobRequired: "Il lavoro è richiesto",
                machineRequired: "La macchina è necessaria",
              },
              quality_report: {
                wasteCount: "Conteggio dei rifiuti",
                startTime: "Ora di inizio",
                endTime: "Tempo scaduto",
                scrapType: "Tipo di scarto",
                editQualityReport: "Modifica rapporto qualità",
                deleteQualityReport: "Elimina rapporto qualità",
                addQualityReport: "Aggiungi rapporto sulla qualità",
                deleteQualityReportModalTitle: "Elimina rapporto qualità",
                deleteQualityReportModalText: "Sei sicuro di voler eliminare il rapporto sulla qualità?",
                qualityReportDeleted: "Il rapporto sulla qualità è stato eliminato correttamente",
              }

            }, 
            machine_shift: {
              shift: 'Spostare',
              shifts: 'Turni',
              createNewMachineShift: 'Crea un nuovo turno macchina',
              existingMachineShifts: 'Turni macchina esistenti',
              deleteConfirmation: "Sei sicuro di voler eliminare il turno '{nome turno}' dall'{ora di inizio} all'ora di {fine}?",
              confirmDeleteTitle: "Conferma l'eliminazione del turno",
              editMachineShift: "Modifica turno macchina",
              machineShiftPermission: "Autorizzazione per turni macchina",
              errors:{
                endTimeLargerStartTime: "L'ora di fine deve essere maggiore dell'ora di inizio",
                noShiftFound: "Nessun turno trovato per l'ora corrente",
                nameRequired: "Il nome del turno è obbligatorio",
                machineRequired: "La macchina è necessaria",
                startTimeRequired: "L'ora di inizio è obbligatoria",
                endTimeRequired: "L'ora di fine è obbligatoria",
                isActiveRequired: "Il valore attivo è obbligatorio",
                targetNumberLargerZero: "Il numero di destinazione deve essere maggiore di 0",
                mondayRequired: "Il valore del lunedì è obbligatorio",
                tuesdayRequired: "Il valore martedì è obbligatorio",
                wednesdayRequired: "Il valore del mercoledì è obbligatorio",
                thursdayRequired: "Il valore del giovedì è obbligatorio",
                fridayRequired: "Il valore del venerdì è obbligatorio",
                saturdayRequired: "Il valore del sabato è obbligatorio",
                sundayRequired: "Il valore della domenica è obbligatorio",
              },
              forms: {
                machineShiftAdded: "Il turno macchina è stato aggiunto con successo",
                machineShiftUpdated: "Il turno macchina è stato aggiornato con successo",
                name: "Nome del turno",
                startTime: "Ora di inizio",
                endTime: "Tempo scaduto",
                machine: "Macchina",
                machines: "Macchine",
                monday: "Lunedi",
                tuesday: "Martedì",
                wednesday: "Mercoledì",
                thursday: "Giovedì",
                friday: "Venerdì",
                saturday: "Sabato",
                sunday: "Domenica",
                isActive: "Attivo",
                targetNumber: "Numero di destinazione",
              }
            },
            reporting: {
              minStartTime: 'Min Ora di inizio',
              minEndTime: 'Min Tempo scaduto',
              maxStartTime: 'Max Ora di inizio',
              maxEndTime: 'Max Tempo scaduto',
              minDuration: 'Min Durata',
              maxDuration: 'Max Durata',
              loadingReportData: 'Caricamento dei dati del rapporto',
              oeeTab: 'OEE',
              machineTab: 'Macchina',
              machineTabMachine: 'Macchina',
              jobTab: 'Lavori',
              noMatchingData: 'Siamo spiacenti, non ci sono dati corrispondenti da visualizzare',
              minAvailability: 'Min Disponibilità',
              maxAvailability: 'Max Disponibilità',
              loadingMachines: 'Caricamento macchine...',
              loadingQualityReports: 'Caricamento dei report sulla qualità...',
              actualIdealCount: 'Conteggio effettivo/obiettivo',
              actualCount: 'Conteggio effettivo',
              goodCount: 'Buon Conte',
              targetCount: 'Conteggio bersagli',
              scrapCount: 'Conteggio scarti',
              plannedDowntime: 'Tempo di inattività pianificato',
              downtime: 'Tempi di inattività non pianificati',
              scheduleLoss: 'Perdita di pianificazione',
            },
            advanced_reporting: {
                machineRankingHeadingText: "Panoramica delle prestazioni della macchina",
                machineRankingChartID: "Classifica macchine",
                machineRankingValue: "prestazione",
                machineRankingCategory: "nome",
                noPerformanceText: "N/A",
                noPerformanceValue: 0,
                reasonRankingHeadingText: "Motivo peggiore del fallimento Panoramica",
                reasonRankingChartID: "Classifica dei motivi",
                reasonRankingValue: "contare",
                reasonRankingCategory: "nome",
                noReasonsText: "Nessun motivo ancora",
                noReasonsValue: 1,
                averageHeadingText: "Panoramica media",
                averageChartID: "Panoramica media",
                averageValue: "valore",
                averageCategory: "categoria",
                fromText: "Da",
                toText: "A",
                oneh: "1h",
                twoh: "2h",
                threeh: "3h",
                timeSelectorHeading: "Selettore del tempo",
                timeSelectorTimeframeSelect: "Seleggere un intervallo di tempo",
                pickTime: "Inserisci un orario (ultime ore)"
            },
            user: {
              signIn: 'Registrazione',
              noStructures: 'Nessuna struttura o macchina assegnata all\'utente.',
              noViews: 'Nessuna vista per la struttura o la macchina assegnata all\'utente.',
              note: 'NOTA',
              editingDisabled: 'La modifica del ruolo di amministratore di sistema è disabilitata dal sistema!',
              users: 'Utenti',
              usersPermissions: 'Autorizzazioni degli utenti',
              deactivateUsers: 'Disattiva utenti',
              oeeOverview: 'Panoramica dell\'OEE',
              andonBoard: 'Andon Board',
              comparisonOverview: 'Panoramica del confronto',
              reportingOverview: 'Panoramica dei rapporti',
              scheduleOverview: 'Panoramica del programma',
              machineOverview: 'Panoramica della macchina',
              structureManagement: 'Gestione della struttura',
              machineManagement: 'Gestione della macchina',
              sensorManagement: 'Gestione dei sensori',
              userManagement: 'Gestione utenti',
              view: 'Visualizzazione',
              structure: 'Struttura',
              machine: 'Macchina',
              homeview: 'Vista iniziale',
              units: {
                SPP: "Secondi per parte",
                MPP: "Minuti per parte",
                PPM: "Parti al minuto",
                PPH: "Parti all'ora",
                PPS: "Parti al secondo",
                short_caps : {
                  SPP: "Secondi/Parte",
                  MPP: "Verbale/Parte",
                  PPM: "Parti/Minuto",
                  PPH: "Parti/ora",
                  PPS: "Parti/secondo",
                },
                short: {
                  SPP: "s/parte",
                  MPP: "min/parte",
                  PPM: "parti/min",
                  PPH: "parti/h",
                  PPS: "parti/s",
                }
              }
            },
            structure: {
              averageRuntime: 'Media Autonomia della macchina',
              averageDowntime: 'Media Fermo macchina',
              machines: 'macchina',
              timespan: 'Arco di tempo',
              productionPulse: 'Impulso di produzione',
              productionMachines: 'Macchine di produzione',
              structureLoading: 'Caricamento della struttura...',
              runningCalculations: 'Esecuzione dei calcoli...',
              deactivateStructures: 'Disattivare le strutture',
              selectStructure: 'Seleziona una struttura',
              machinesRunning: 'macchine in funzione.',
              of: 'di',
            },
            performance: {
              slowRunning: 'Corsa lenta',
              idle: 'Oziare',
              jobs: "Lavori",
              availability: 'Disponibilità',
              performance: 'Prestazione',
              minPerformance: 'Min. Prestazione',
              maxPerformance: 'Max. Prestazione',
              qualityShort: 'QUA',
              performanceShort: 'PRE',
              availabilityShort: 'DIS',
              quality: 'Qualità',
              oee: 'OEE',
              parts: 'Parti',
              avgSign: 'Ø',
              partsPerMinute: 'Parti/Min',
              partsProduced: 'Parti prodotte',
              partsSurplus: 'Eccedenza di parti',
              partsMissed: 'Parti mancanti',
              target: 'Conteggio degli obiettivi',
              chartTargetCycleTime: 'Tempo di ciclo target',
              chartParts: 'Parti',
              chartPartsOf: 'di',
              chartPartsSec: 'Secondi',
              chartPartsProduced: 'Parti prodotte (Parti/Min)',
              chartPartsProducedSecPart: 'Parti prodotte (Sec/Parte)',
              chartPartsProducedPartsHour: 'Parti prodotte (Parti/ora)',
              chartPartsPartsHour: 'Parti/ora',
              chartPartsTitle: 'Parti prodotte',
              chartPartsPartsMin: 'Parti/Min',
              chartPartsSecPart: 'Sez/Parte',
              isLoadingParts: 'Caricamento parti...',
            },
            terminal: {
              productionCapacity: 'Capacità produttiva',
              onlineStatus: 'Stato online',
              connected: 'Connesso',
              notConnected: 'Non connesso',
              internet: 'Internet',
              machineData: 'Dati della macchina',
              mongodbData: 'Dati MongoDB',
              lfBackend: 'LF Server',
              postgresData: 'Dati Postgres',
              lastCheck: 'Ultimo controllo',
            },
            reasons: {
              reasons: 'Motivi',
              noAssignedReasonsTimeframe: 'Nessun motivo assegnato in questo intervallo di tempo.',
              deactivateReasons: 'Disattiva motivi',
              categoriesPermissions: 'Autorizzazioni delle categorie',
              notesPermissions: 'Autorizzazioni per le note',
              reasonsPermissions: 'Motivi autorizzazioni',
            },
            sensor: {
              deactivateSensors: 'Disattiva i sensori',
              sensorsPermission: 'Autorizzazioni dei sensori',
              sensorTypesPermission: 'Autorizzazioni Tipi di sensore',
              notConnected: 'Non collegata',
              noSignal: 'Nessun segnale',
              signalStrength: 'La potenza del segnale',
              rssiExcellent: 'Eccellente',
              rssiGood: 'Bene',
              rssiFair: 'Giusto',
              rssiWeak: 'Debole',
              noMachine: 'Nessuna macchina associata',
              lastSeenAt: 'Visto l\'ultima volta a: ',
              online: 'In linea: ',
              rssiAverage: 'RSSI (media 7 giorni)',
              version: 'Versione: ',
              hardware: 'Hardware: ',
            },
            roles: {
              rolesPermission: 'Autorizzazioni dei ruoli',
            },
            schedule: {
              setup: 'Impostare',
              create: 'Creare',
              save: 'Salva',
              createAndSave: 'Crea e salva',
              saveAndDeploy: 'Salva e distribuisci',
              createAndDeploy: 'Crea e distribuisci',
              monday: "Lunedi",
              tuesday: "Martedì",
              wednesday: "Mercoledì",
              thursday: "Giovedì",
              friday: "Venerdì",
              saturday: "Sabato",
              sunday: "Domenica",
              date: 'Data',
              today: 'OGGI',
              dayTemplatePermissions: 'Autorizzazioni del modello giornaliero',
              weekTemplatePermissions: 'Autorizzazioni del modello settimanale',
              schedulePermissions: 'Pianifica le autorizzazioni',
              shiftsPermissions: 'Sposta i permessi',
              activateSchedule: 'Attiva pianificazione',
              schedule: 'Programma',
              noEligibleSchedules: 'Nessuna pianificazione idonea per questa macchina.',
              changeActiveSchedule: 'Modifica il programma attivo',
              editSchedule: 'Modifica programma',
              editDay: 'Modifica giorno',
              deactivateTemplates: 'Disattiva modelli',
              existingShifts: 'Turni esistenti',
              createShift: 'Crea turno',
              start: 'Inizio',
              end: 'Fine',
              scheduled: 'Programmato',
              duration: 'Durata',
              preview: 'Anteprima',
              enabled: 'Abilitato',
              shiftUpdated: 'Turno aggiornato con successo.',
              shifts: 'Turni',
              editShift: 'Modifica turno',
              selectedTime: 'Orario selezionato di: ',
              invalidStart:' non è valido perché in conflitto con l\'inizio del turno.',
              invalidEnd: ' non è valido poiché è in conflitto con la fine del turno.',
              shiftStart: 'Inizio turno',
              shiftEnd: 'Fine turno',
              manageSchedules: 'Gestisci gli orari',
              createSchedule: 'Crea pianificazione',
              existingWeekTemplates: 'Modelli settimanali esistenti',
              createWeekTemplate: 'Crea modello settimanale',
              existingDayTemplates: 'Modelli giornalieri esistenti',
              createDayTemplate: 'Crea modelli giornalieri',
              weekRemoved: 'Settimana rimossa con successo.',
              weekEdited: 'Settimana modificata con successo.',
              dayEdited: 'Giorno modificato correttamente.',
              scheduleCreated: 'La pianificazione è stata creata correttamente.',
              scheduleUpdated: 'Il programma è stato aggiornato con successo.',
              startDate: 'Data d\'inizio',
              endDate: 'Data di fine',
              addWeek: 'AGGIUNGI SETTIMANA',
              editWeek: 'Modifica settimana',
              scheduleInfo: 'Informazioni sulla pianificazione',
              scheduleName: 'Nome',
              scheduleDescription: 'Descrizione:',
              lastDeployed: 'Ultimo distribuito:',
              lastEdited: 'Ultima modifica:',
              goTo: 'Vai a:',
              scheduleDeployed: 'La pianificazione è stata distribuita con successo.',
              deploymentCanceled: 'La distribuzione è stata annullata con successo.',
              scheduleActivated:
                'La pianificazione è stata attivata con successo.',
              scheduleDuplicated:
                'La pianificazione è stata duplicata con successo.',
              deployment: 'Distribuzione',
              activation: 'Attivazione',
              areYouSureDeploy: 'Sei sicuro di voler eseguire la distribuzione ',
              areYouSureActivate: 'Sei sicuro di voler attivare? ',
              overwriteOnMachines:
                'Ciò sovrascriverà tutte le modifiche apportate su una qualsiasi delle macchine elencate di seguito.',
              onTheseMachines: ' su queste macchine:',
              editCreatedSchedule:
                'Vuoi modificare la pianificazione appena creata?',
              areYouSureDelete: 'Sei sicuro di voler eliminare ',
              areYouSureDuplicate: 'Sei sicuro di voler duplicare? ',
              areYouSureCancel: 'Sei sicuro di voler annullare la distribuzione per ',
              scheduleDeleted: 'La pianificazione è stata eliminata con successo.',
              schedules: 'Orari',
              areYouSure: 'Sei sicuro?',
              editNewSchedule: 'Modifica il nuovo programma',
              doItLater: 'lo farò più tardi',
              deployingSchedule: 'Pianificazione della distribuzione: ',
              cancelingDeployment: 'Annullamento della distribuzione della pianificazione: ',
              takeWhile: 'Potrebbe richiedere del tempo a seconda del numero di macchine.',
              activatingSchedule: 'Attivazione del programma: ',
              addRemoveMachines: 'Aggiungi/Rimuovi macchine',
              editMasterTemplate: 'Modifica modello principale',
              editDeployedSchedule: 'Modifica la pianificazione distribuita',
              editNewWeekTemplate:
                'Vuoi modificare il modello della settimana appena creato?',
              deployingYourSchedule: 'Distribuzione della pianificazione...',
              editNewTemplate: 'Modifica nuovo modello',
              scheduleCantBeEdited: 'La pianificazione non può essere modificata prima',
              selectedMachines: 'Macchine selezionate: ',
              weekTemplateDuplicated:
                'Il modello della settimana è stato duplicato con successo.',
              addDayTemplate: 'Aggiungi modello giornaliero',
              scheduleTemplateUpdated:
                'Il modello di pianificazione è stato aggiornato correttamente.',
              weekTemplates: 'Modelli settimanali',
              editWeekTemplate: 'Modifica il modello della settimana',
              weekTemplateCreated:
                'Il modello della settimana è stato creato correttamente.',
              weekTemplateUpdated:
                'Il modello della settimana è stato aggiornato con successo.',
              clearDay: 'Giornata limpida',
              templateNameRequired:
                'Il nome del modello è obbligatorio. Non può essere lasciato vuoto.',
              templateEvents: 'Gli eventi del modello non possono essere lasciati vuoti.',
              templateTimeframe:
                'Il periodo di tempo del modello deve essere compilato completamente.',
              scheduleNameRequired:
                'Il nome della pianificazione è obbligatorio. Non può essere lasciato vuoto.',
              scheduleToMachine:
                'Il programma deve essere collegato ad almeno una macchina.',
              startDateCantBeInThePast:
                'La data di inizio non deve essere nel passato.',
              endDateCantBeBeforeStartDate:
                'La data di fine non deve essere anteriore alla data di inizio.',
              atLeastOneWeekTemplate: 'Alla pianificazione deve essere aggiunto almeno un modello settimanale.',
              dayTemplateDuplicated:
                'Il modello del giorno è stato duplicato con successo.',
              dayTemplates: 'Modelli giornalieri',
              editTemplate: 'Modifica modello',
              dayTemplateCreated:
                'Il modello del giorno è stato creato con successo.',
              dayTemplateUpdated:
                'Il modello del giorno è stato aggiornato con successo.',
              duplicate: 'Duplicare',
              edit: 'Modificare',
              view: 'Visualizzazione',
              delete: 'Eliminare',
              hasExpired: 'È scaduta',
              hasNotExpired: 'Non è scaduto',
              all: 'Tutto',
              expiration: 'Scadenza',
              cancelDeployment: 'Annulla distribuzione',
              deploy: 'Distribuire',
              activateOn: 'Attiva il:',
              activationScheduledSuccessfully: 'L\'attivazione è stata pianificata correttamente.',
              activationCanceledSuccessfully: 'L\'attivazione pianificata è stata annullata con successo.',
              isDueForActivationOn: 'è prevista l\'attivazione il',
              selectAllMachines: 'SELEZIONA TUTTE LE MACCHINE',
              remove: "RIMUOVERE",
            },
            quality: {
              quality: 'Qualità',
              easyScrap: 'Rottamazione facile',
              easyScrapButton: 'Rottamazione facile',
              existingScrapTypes: 'Tipi di scarto esistenti',
              createNewScrapType: 'Crea un nuovo tipo di scarto',
              scrapTypes: 'Tipi di scarto',
              selectScrapType: 'Seleziona il tipo di scarto',
              easyScrapNote: 'Aggiunto da Easy Scrap',
              easyScrapDescription: 'Rottamazione facile',
              scrapType: 'Tipo di scarto',
              minQuality: 'Min. Qualità',
              maxQuality: 'Max. Qualità',
              count: 'Contare',
              qualityReportAdded:
                'Un nuovo rapporto sulla qualità è stato aggiunto correttamente.',
              qualityReportDeleted: 'Il rapporto sulla qualità è stato eliminato correttamente',
              qualityReport: 'Rapporto sulla qualità',
              alreadyAdded:
                ' già aggiunto. Fare clic su Modifica sopra per modificare.',
              productionTimePeriod: 'Periodo di produzione',
              wasteCount: 'Conteggio dei rifiuti',
              totalWasteCount: 'Conteggio totale dei rifiuti: ',
              scrapTypeRequired: 'Tipo di scarto richiesto',
              endTimeRequired: 'Ora di fine richiesta',
              startTimeRequired: 'Ora di inizio richiesta',
              countRequired: 'Conte richiesto. Non può essere 0 o lasciato vuoto.',
              invalidScrapNumber: 'Quantità di scarto non valida, non può essere negativa',
              tooMuchScrap: 'Quantità di scarto non valida, quantità troppo grande',
              actualCountInvalid: 'Conteggio del sensore non valido, impossibile inserire scarti',
              typeNameRequired: 'Il nome del tipo di scarto è obbligatorio. Non può essere lasciato vuoto.',
              typeIdentifierRequired: 'L\'identificatore del tipo di scarto è obbligatorio. Non può essere lasciato vuoto.',
              categoryRequired: 'La categoria è obbligatoria. Non può essere lasciato vuoto.',
              originRequired: 'L\'origine è obbligatoria. Non può essere lasciato vuoto.',
              scrapTypesPermissions: 'Autorizzazioni per i tipi di scarto',
              parts: 'Parti',
              noQualityReportSubmitted: 'Nessun rapporto sulla qualità inviato nel periodo di tempo selezionato.',
              qualityLosses: 'Perdite di qualità',
              qualityReports: 'Rapporti sulla qualità',
              deleteQualityReportModalTitle: 'Eliminare il rapporto sulla qualità?',
              areYouSureDeleteQualityReport: 'Eliminare questo rapporto sulla qualità?',
              noQualityReportsSubmitted: 'Nessun rapporto sulla qualità inviato nel periodo di tempo selezionato.',
            },
            orders: {
              start_working_order: 'Inizia a lavorare sull ordine',
              stop_working_order: 'Ferma di lavorare sull ordine',
              average: 'Media',
              areYouSureDeleteJob: "Eliminare il lavoro?",
              areYouSureDeleteJobText: "Sei sicuro di voler eliminare il seguente lavoro?",              
              orders: "Ordini",
              edit: "Modificare",
              delete: "Eliminare",
              pastActiveJobs: "Lavori attivi passati",
              noPastActiveJobsInTimeframe: 'Nessun lavoro attivo passato nell\'intervallo di tempo selezionato',
              ordersPermissions: 'Autorizzazioni per gli ordini',
              progress: "Progresso (%)",
              speedJob: "Velocità",
              jobsPermissions: 'Permessi di lavoro ',
              productsPermissions: 'Autorizzazioni sui prodotti',
              activeJobs: 'Lavoro attivo',
              jobId: 'ID lavoro',
              orderId: 'ID ordine',
              status: 'Stato',
              plannedStartDate: 'Data di inizio pianificata',
              actualStartDate: 'Data di inizio effettiva',
              endDate: "Data di fine",
              target: 'Bersaglio',
              sensorCount: 'Conteggio dei sensori',
              goodCount: 'Buone parti',
              scrapCount: 'Rottame totale',
              handCount: 'Conteggio manuale',
              machine: 'Macchina',
              structureField: 'struttura',
              intendedMachineField: 'previstoMacchina',
              intendedMachine: 'Macchina prevista',
              structure: 'Struttura',
              intended: 'Destinato',
              operator: 'Operatore',
              startTime: 'Ora di inizio',
              endTime: 'Tempo scaduto',
              duration: 'Durata',
              uploadCSV: 'Carica CSV',
              chooseFileToUpload: 'Scegli il file da caricare',
              chooseFile: 'Scegli il file',
              close: 'Vicino',
              upload: 'Caricamento',
              uploading: 'Caricamento',
              uploadAnotherFile: 'Carica un altro file',
              jobsCreatedSuccessfully: 'posti di lavoro creati con successo',
              backToUploadFile: 'Torna al caricamento del file',
              fileHasBeenUploaded: 'Il file è stato caricato.',
              uploadAgainWithCorrections:
                'Tuttavia lo stesso file deve essere caricato nuovamente con le seguenti correzioni:',
              row: 'Riga',
              backToJobsList: 'Torna all\'elenco dei lavori',
              product: 'Prodotto',
              description: 'Descrizione',
              jobStatus: 'Stato del lavoro',
              targetCount: 'Conteggio bersagli',
              targetCycleTime: 'Tempo di ciclo target',
              targetSetupTime: 'Tempo di configurazione target',
              engagements: 'Attivazioni',
              operatorNotes: 'Note per l\'operatore',
              loadingJob: 'Caricamento lavoro...',
              existingNotes: 'Note esistenti',
              enterNoteText: 'Inserisci il testo della nota',
              addNote: 'Aggiungi nota',
              clearScreen: 'Schermo pulito',
              start: 'Inizio',
              stop: 'Fermare',
              setJobStatus: 'Imposta lo stato del lavoro.',
              ACTIVE: 'ATTIVO',
              OPEN: 'APRIRE',
              PAUSED: 'IN PAUSA',
              COMPLETED: 'COMPLETATO',
              setJobStatusPaused: 'Imposta lo stato del lavoro su In pausa.',
              setJobStatusCompleted: 'Imposta lo stato del lavoro su completato.',
              currentlyEngaged: 'Attualmente attivato',
              jobTotalCount: 'Conteggio totale lavori',
              speed: 'Velocità',
              actual: 'Effettivo',
              setup: 'Setup',
              warning: 'Avvertimento',
              areYouSureOverwriteHandcount:
                'Sei sicuro di voler sovrascrivere il conteggio attuale?',
              byClickingSubmit: 'Facendo clic su Invia, il conteggio corrente:',
              willBeDeleted: 'sarà cancellato.',
              yes: 'SÌ',
              no: 'No',
              STARTED: 'INIZIATO',
              ENDED: 'CONCLUSA',
              intendedTable: 'destinato',
              unintendedTable: 'non inteso',
              fulltime: 'Tempo pieno',
              timeframe: 'Lasso di tempo',
              pastTwoWeeks: 'Ultime 2 settimane',
              pastMonth: 'Mese scorso',
              pastSixMonths: 'Ultimi 6 mesi',
              pastYear: 'L\'anno scorso',
              jobProgressOverview: 'Panoramica sull\'avanzamento del lavoro',
              jobProgress: 'Avanzamento del lavoro',
              existingJobs: 'Lavori esistenti',
              createNewJob: "Crea nuovo lavoro",
              activationOverview: 'Panoramica sull\'attivazione',
              addedHandCount: 'Aggiunto conteggio delle mani',
              removeAssignedJob: 'Rimuovi incarico',
              assignJob: 'Assegna',
              startHandCount: "Numero di partenza",
              endHandCount: "Numero finale",
              handCountCalculator: "Calcolatrice del conteggio manuale",
              apply: "Applicare",
              notAvailable: 'L\'ordine di lavoro non è disponibile: ',
            },
            machinePerformance: {
              machinePerformance: 'Prestazioni della macchina',
              theoreticalOutput: 'Produzione massima teorica',
              workingOutput: 'Uscita massima pianificata',
              scheduledOutput: 'Dopo la perdita di programma',
              downtimeLoss: 'Dopo la perdita del tempo di inattività',
              speedLoss: 'Dopo la perdita di velocità',
              qualityLoss: 'Dopo la perdita di qualità',
              theoreticalOutputDesc: 'Massimo teorico di parti prodotte quando una macchina è in funzione 24/7',
              workingOutputDesc: 'Parti teoriche prodotte secondo il piano di pianificazione',
              scheduledOutputDesc: 'Parti teoriche prodotte secondo il piano di pianificazione senza interruzioni pianificate',
              downtimeLossDesc: 'Numero massimo teorico di parti prodotte mentre la macchina era in funzione',
              speedLossDesc: 'Parti prodotte',
              qualityLossDesc: 'Buone parti',
              performanceLoading: 'Caricamento delle prestazioni della macchina...',
              unit: 'Parti',
              unitMaxCycleTime: 'Parti/min',
              totalTime: 'Tempo totale',
              maxCycleTime: 'Max. parti al minuto',
              noPerformance: 'Nessun dato sulle prestazioni disponibile',
              workingTime: 'Orario di lavoro pianificato',
              plannedReasons: 'Tempo totale dei motivi pianificati',
              plannedReasonsHeading: 'Tempi di inattività pianificati',
              runningTime: 'Tempo di esecuzione totale',
              runningStates: 'Numero di stati in esecuzione',
              avgPartsPerMinute: 'Pezzi medi al minuto',
              avgScrapPerMinute: 'Scarto medio al minuto',
              total: 'Totale',
              totalDowntimeLoss: 'Perdita totale di tempi di inattività',
              delta: 'Delta',
              reasonsHeading: 'Tempi di inattività non pianificati',
              qualityLossesHeading: 'Rapporti sulla qualità',
              backToMachineView: 'Torna alla vista macchina',
              scheduledWorkingTime: 'Orario di lavoro programmato',
              overtime: 'Col tempo',
              plannedRunningTime: "Durata pianificata"
            }
          },

          forms: {
            reloadInterval: {
              success: "Intervallo di ricarica aggiornato con successo",
              failure: "Aggiornamento dell'intervallo di ricarica non riuscito",
            },
            jobs: {
              product: "Prodotto",
              targetCycleTime: "Tempo di ciclo target",
              targetSetupTime: "Tempo di impostazione dell'obiettivo",
              targetQuantity: "Quantità target",
              description: "Descrizione",
              intendedMachine: "Macchina prevista",
              scanCode: "Scansione codice",
              handCount: "Conteggio manuale",
              completionStatus: "Stato di completamento",
              businessJobId: "ID aziendale",
              modalTitle: "Modifica lavoro",
              jobEditSuccess: "Lavoro salvato con successo",
              jobEditFailure: "Salvataggio del lavoro non riuscito",
              jobDeleteSuccess: "Lavoro eliminato con successo",
              jobDeleteFailure: "Eliminazione del lavoro non riuscita",
              validate: {
                handCountRequired: "È richiesto il numero del conteggio manuale",
                descriptionRequired: "La descrizione del lavoro è obbligatoria",
                completionStatusRequired: "È richiesto uno stato di Completamento lavoro",
                intendedMachineRequired: "È necessaria una macchina",
                targetQuantityRequired: "È richiesto un numero di quantità target",
                targetSetupTimeRequired: "È richiesto un valore temporale di configurazione target",
                targetCycleTimeRequired: "È richiesto un valore del tempo di ciclo target",
                productRequired: "È richiesta una selezione del prodotto"
              }

            },
            machine: {
              fields: {
                placeNumber: 'Numero del posto',
                manufacturer: 'Produttore',
                machineConfiguration: 'Configurazione della macchina',
                newMachineConfiguration: "Nuova configurazione della macchina",
                configurationName: 'Nome della configurazione',
                configurationValue: 'Valore di configurazione',
                selectConfigurationValue: "Valore di configurazione personalizzata",
              },
              configLabels: {
                defaultActivationStatus: "Stato di attivazione predefinito",
                targetCycleTime:"Tempo di ciclo target",
                maxCycleTime: "Tempo di ciclo massimo",
                partsChartSimple: "Consenti visualizzazione grafico parti semplici",
                partsChart: "Consenti visualizzazione grafico parti",
                speedLow: "Velocità minima della macchina",
                speedMedium: "Velocità media della macchina",
                speedMax: "Velocità massima della macchina",
                handCountStartEnd: "Consenti conteggio manuale",
                jobBasedTerminal: "Consenti terminale basato su lavoro",
              },
              configUnits: {
                defaultActivationStatus: "ACTIVE/ENDED",
                targetCycleTime: "secondi/parte",
                maxCycleTime: "ssecondi/part",
                partsChartSimple: "vero/falso",
                partsChart: "vero/falso",
                speedLow: "secondi/parte",
                speedMedium: "secondi/parte",
                speedMax: "secondi/parte",
                handCountStartEnd: "vero/falso",
                jobBasedTerminal: "vero/falso",
              },
              buttons: {
                removeField: 'Rimuovi campo',
                addField: 'Aggiungi campo',
                addCustomField: 'Aggiungi campo personalizzato',
              },

              errors: {
                machineNameRequired:
                  'Il nome della macchina è obbligatorio. Non può essere lasciato vuoto.',
                structureRequired:
                  'La struttura è necessaria. Non può essere lasciato vuoto.',
                machineTypeRequired:
                  'Il tipo di macchina è obbligatorio. Non può essere lasciato vuoto.',
                nameCannotBeBlank: 'Il nome è obbligatorio. Non può essere lasciato vuoto.',
                genericError: 'Qualcosa è andato storto. Per favore riprova più tardi.',
                sensorPerMachine:
                  'La versione attuale supporta solo un sensore per macchina.',
                machineStatusName:
                  'Il nome dello stato della macchina è obbligatorio. Non può essere lasciato vuoto.',
                machineStatusActivity: 'L\'attività relativa allo stato della macchina è obbligatoria. Non può essere lasciato vuoto.',
                machineStatusColor: 'Il colore è obbligatorio. Non può essere lasciato vuoto.',
                machineStatusCategory:
                  'La categoria è obbligatoria. Non può essere lasciato vuoto.',
                machineStatusDataLayer:
                  'Il livello dati è obbligatorio. Non può essere lasciato vuoto.',
              },

              success: {
                machineAdded: 'Una nuova macchina è stata aggiunta con successo.',
                machineUpdated: 'La macchina è stata aggiornata con successo.',
                machineTypeAdded:
                  'Un nuovo tipo di macchina è stato aggiunto con successo.',
                machineTypeUpdated:
                  'Il tipo di macchina è stato aggiornato con successo.',
                machineTypeDeleted:
                  'Il tipo di macchina è stato eliminato correttamente.',
                machineConfigSent:
                  'La configurazione della macchina è stata inviata con successo.',
              },
            },

            sensor: {
              fields: {
                sensorID: 'ID del sensore',
                measurementUnit: 'Unità di misura',
              },

              errors: {
                sensorIdRequired:
                  'L\'ID del sensore è obbligatorio. Non può essere lasciato vuoto.',
                aliasRequired:
                  'L\'alias del sensore è obbligatorio. Non può essere lasciato vuoto.',
                sensorTypeRequired:
                  'Il tipo di sensore è obbligatorio. Non può essere lasciato vuoto.',
                isActiveUndefined:
                  'È richiesto l\'attivazione/disattivazione attiva. Non può essere lasciato indefinito.',
                genericError: 'Qualcosa è andato storto. Per favore riprova più tardi.',
                sensorNameRequired:
                  'Il nome del sensore è obbligatorio. Non può essere lasciato vuoto.',
                measurementUnitRequired:
                  'L\'unità di misura è obbligatoria. Non può essere lasciato vuoto',
              },

              success: {
                sensorAdded: 'Un nuovo sensore è stato aggiunto con successo.',
                sensorUpdated: 'Il sensore è stato aggiornato con successo.',
                sensorDeleted: 'Il sensore è stato eliminato con successo.',
                sensorTypeAdded:
                  'Un nuovo tipo di sensore è stato aggiunto con successo.',
                sensorTypeUpdated:
                  'Il tipo di sensore è stato aggiornato con successo.',
                sensorTypeDeleted: 'Tipo di sensore eliminato correttamente.',
              },
            },

            structure: {
              fields: {
                country: 'Paese',
                parentStructure: 'Struttura principale',
              },

              errors: {
                notAuthorized: 'Non sei autorizzato a visualizzare questa struttura.',
                structureDoesntExist:
                  'La struttura a cui stai tentando di accedere non esiste.',
                structureNameRequired:
                  'Il nome della struttura è obbligatorio. Non può essere lasciato vuoto.',
                structureCityRequired:
                  'La città è obbligatoria. Non può essere lasciato vuoto.',
                structureCountryRequired:
                  'Il paese è obbligatorio. Non può essere lasciato vuoto.',
                structureTimezoneRequired:
                  'Il fuso orario della struttura è obbligatorio. Non può essere lasciato vuoto.',
                genericError: 'Qualcosa è andato storto. Per favore riprova più tardi.',
              },

              success: {
                structureAdded: 'Una nuova struttura è stata aggiunta con successo.',
                structureUpdated:
                  'La struttura è stata aggiornata con successo.',
                structureDeleted:
                  'La struttura è stata eliminata con successo.',
              },
            },

            user: {
              fields: {
                machinesPerPage: 'Macchine per pagina',
                unit: 'Unità',
                email: 'Email',
                password: 'Parola d\'ordine',
                businessUserId: 'ID utente aziendale',
                fullName: 'Nome e cognome',
                confirmPassword: 'Conferma password',
                username: 'Nome utente',
                pin: 'PIN',
                confirmPin: 'Conferma il PIN',
                language: 'Lingua',
                role: 'Ruolo',
                defaultView: 'Visualizzazione predefinita',
              },

              errors: {
                otherError:
                  'Qualcosa è andato storto. Per favore riprova più tardi.',
                invalidEmailFormat:
                  'Formato email non valido. Dovrebbe essere utente@sito.com',
                roleNameRequired: 'Il nome è obbligatorio. Non può essere lasciato vuoto.',
                fullNameRequired:
                  'È richiesto il nome completo. Non può essere lasciato vuoto.',
                emailRequired: 'L\'e-mail è obbligatoria. Non può essere lasciato vuoto.',
                passwordRequired:
                  'È richiesta la password. Non può essere lasciato vuoto.',
                passwordMismatch:
                  'Le password non corrispondono. Per favore riprova.',
                passwordLength:
                  'La password deve essere lunga almeno 8 caratteri.',
                businessidInUse:
                  'L\'ID utente aziendale fornito è già in uso.',
                languageRequired:
                  'La lingua è obbligatoria. Non può essere lasciato vuoto.',
                roleRequired: 'Il ruolo è richiesto. Non può essere lasciato vuoto.',
                structuresRequired:
                  'Le strutture sono necessarie. Non può essere lasciato vuoto.',
                structureViewRequired:
                  'La vista della struttura è obbligatoria. Non può essere lasciato vuoto.',
                structureRequired:
                  'È necessaria una struttura. Non può essere lasciato vuoto.',
                machineViewRequired:
                  'La vista macchina è obbligatoria. Non può essere lasciato vuoto.',
                machineRequired: 'È necessaria una macchina. Non può essere lasciato vuoto.',
                homeViewRequired:
                  'È richiesta la vista Home. Non può essere lasciato vuoto.',
                genericError: 'Qualcosa è andato storto. Per favore riprova più tardi.',
                adminCannotBeDeleted: 'Il ruolo di amministratore di sistema non può essere eliminato!',
                userAlreadyExists: 'Un utente con quell\'indirizzo email esiste già.',
                pinRequired: 'Il PIN è obbligatorio. Non può essere lasciato vuoto.',
                pinMismatch: 'I numeri PIN non corrispondono. Per favore riprova.',
              },

              success: {
                roleAdded: 'Un nuovo ruolo è stato aggiunto con successo.',
                roleUpdated: 'Il ruolo è stato aggiornato con successo.',
                roleDeleted: 'Il ruolo è stato eliminato con successo.',
                userAdded: 'Un nuovo utente è stato aggiunto con successo.',
                userUpdated: 'L\'utente è stato aggiornato con successo.',
                userDeleted: 'L\'utente è stato eliminato con successo.',
              },
            },

            reason: {
              fields: {
                name: 'Nome',
                identifier: 'Identificatore',
                description: 'Descrizione',
                color: 'Colore',
                category: 'Categoria',
                machineType: 'Tipo di macchina',
                roles: 'Ruoli',
                origin: 'Origine',
                reasonConfiguration: 'Configurazione motivo',
              },

              misc: {
                staticReasonActive: "Il motivo statico è attivo!",
                machineStatus: 'Stato della macchina',
                reason: 'Motivo',
                note: 'Nota',
                assignStaticReason: 'Assegna motivo statico',
                assignReason: 'Assegna motivo',
                changeReason: 'Cambia motivo',
                editReason: 'Modifica motivo',
                editStaticReason: 'Modifica motivo statico',
                removeReason: 'Rimuovi motivo',
                loadingReasons: 'Caricamento motivi...',
              },

              errors: {
                reasonNameRequired:
                  'Il nome del motivo è obbligatorio. Non può essere lasciato vuoto.',
                reasonIdentifierRequired:
                  'L\'identificatore del motivo è obbligatorio. Non può essere lasciato vuoto.',
                reasonColorRequired: 'Il colore è obbligatorio. Non può essere lasciato vuoto.',
                reasonCategoryRequired:
                  'La categoria è obbligatoria. Non può essere lasciato vuoto.',
                reasonOriginRequired:
                  'L\'origine è obbligatoria. Non può essere lasciato vuoto.',
                reasonRequired: 'Il motivo è obbligatorio. Non può essere lasciato vuoto.',
              },

              success: {
                staticReasonAdded: 'Per questa macchina è stato impostato un nuovo motivo statico.',
                staticReasonDeleted: 'Il motivo statico di questa macchina è stato eliminato.',
                reasonAdded: 'Un nuovo motivo è stato aggiunto con successo.',
                reasonUpdated: 'Il motivo è stato aggiornato con successo.',
                reasonDeleted: 'Il motivo è stato eliminato con successo.',
              },
            },

            quality: {
              errors: {
                qualityIdentifierRequired:
                  'L\'identificatore di qualità è obbligatorio. Non può essere lasciato vuoto.',
                qualityNameRequired:
                  'Il nome del motivo è obbligatorio. Non può essere lasciato vuoto.',
                reasonIdentifierRequired:
                  'L\'identificatore del motivo è obbligatorio. Non può essere lasciato vuoto.',
                reasonColorRequired: 'Il colore è obbligatorio. Non può essere lasciato vuoto.',
                reasonCategoryRequired:
                  'La categoria è obbligatoria. Non può essere lasciato vuoto.',
                reasonOriginRequired:
                  'La categoria è obbligatoria. Non può essere lasciato vuoto.',
                reasonRequired: 'Il motivo è obbligatorio. Non può essere lasciato vuoto.',
              },
            },

            terminal: {
              fields: {
                username: 'Nome utente',
                pin: 'PIN',
                logout: 'Disconnettersi',
              },

              errors: {
                terminalUsernameLength:
                  'Il nome utente deve contenere almeno 3 caratteri.',
                terminalPinLength:
                  'Lunghezza PIN non valida. Il PIN deve essere lungo 4 numeri.',
                terminalInvalidPinFormat:
                  'Formato PIN non valido. Il PIN può contenere solo numeri.',
              },
            },

            machineStatus: {
              fields: {
                name: 'Nome',
                activity: 'Attività',
                color: 'Colore',
                category: 'Categoria',
                dataLayer: 'Livello dati',
                origin: 'Origine',
                machineStatusConfiguration: 'Configurazione dello stato della macchina',
                identifier: 'Identificatore',
                machineType: 'Tipo di macchina',
              },

              errors: {
                statusNameRequired:
                  'Il nome è obbligatorio. Non può essere lasciato vuoto.',
              },

              success: {
                machineStatusAdded:
                  'Un nuovo stato della macchina è stato aggiunto con successo.',
                machineStatusUpdated:
                  'Lo stato della macchina è stato aggiornato con successo.',
                machineStatusDeleted:
                  'Lo stato della macchina è stato cancellato con successo.',
              },
            },

            category: {
              errors: {
                categoryNameRequired:
                  'Il nome è obbligatorio. Non può essere lasciato vuoto.',
              },

              success: {
                categoryAdded: 'Una nuova categoria è stata aggiunta con successo.',
                categoryUpdated: 'La categoria è stata aggiornata con successo.',
                categoryDeleted: 'La categoria è stata eliminata con successo.',
              },
            },

            shared: {
              fields: {
                name: 'Nome',
                businessID: 'ID aziendale',
                description: 'Descrizione',
                city: 'Città',
                inactive: 'Inattivo',
                active: 'Attivo',
                disabled: 'Disabilitato',
                enabled: 'Abilitato',
                timezone: 'Fuso orario',
                none: 'Nessuno',
                alias: 'Alias',
                type: 'Tipo',
                config: 'Configurazione',
                configSelect: "Seleziona Configurazione",
                structure: 'Struttura',
                structures: 'Strutture',
                sensors: 'Sensori',
                sortIndex: 'Ordina indice',
                startTime: 'Ora di inizio',
                endTime: 'Tempo scaduto',
                duration: 'Durata',
                jobId: 'ID lavoro',
                partsProduced: 'Parti prodotte',
                targetCount: 'Conteggio bersagli',
                realisticCycleTime: 'Tempo di ciclo realistico',
                targetSetupTime: 'Tempo di impostazione dell\'obiettivo',
                actualCount: 'Conteggio effettivo',
                reason: 'Motivo',
                status: 'Stato',

              },

              buttons: {
                merge: 'Unisci',
                reset: 'Ripristina',
                submit: 'Invia',
                save: 'Salva',
                clear: 'Chiaro',
                close: 'Vicino',
                cancel: 'Annulla',
                updatePulse: 'Aggiorna impulso',
                goBack: 'TORNA INDIETRO',
                activate: 'Attivare',
                back: 'Indietro',
                next: 'Prossimo',
                saveChanges: 'SALVARE LE MODIFICHE',
                add: 'Aggiungere',
                remove: 'Rimuovere',
                edit: 'Modificare',
              },
            },
          },

          tables: {
            shared: {
              active: 'Attivo',
              inactive: 'Inattivo',
              assigned: 'Assegnato',
              unassigned: 'Non assegnato',
              machine: 'Macchina',
              machines: 'Macchine',
              actions: 'Azioni',
              machineInformation: 'Informazioni sulla macchina',
              categories: 'Categorie',
              reasons: 'Motivi',
              machineStatuses: 'Stati della macchina',
              product: 'Prodotto'
            },
            noData: "Siamo spiacenti, non ci sono dati corrispondenti da visualizzare.",
            sensor: {
              registrationDate: 'Data di registrazione',
              lastSeen: 'Ultima visualizzazione',
            },
            textLabels: {
              body: {
                noMatch: "Spiacenti, nessun record corrispondente trovato",
                noData: "Spiacenti, nessun dato da visualizzare ancora",
                toolTip: "Ordinare",
                columnHeaderTooltip: column => `Sort for ${column.label}`
              },
              pagination: {
                next: "Pagina successiva",
                previous: "Pagina precedente",
                rowsPerPage: "Righe per pagina:",
                displayRows: "di",
              },
              toolbar: {
                search: "Ricerca",
                downloadCsv: "Scaricamento CSV",
                print: "Stampa",
                viewColumns: "Visualizza colonne",
                filterTable: "Tabella dei filtri",
              },
              filter: {
                all: "Tutto",
                title: "FILTRI",
                reset: "RIPRISTINA",
              },
              viewColumns: {
                title: "Mostra colonne",
                titleAria: "Mostra/nascondi colonne della tabella",
              },
              selectedRows: {
                text: "riga/i selezionata/e",
                delete: "Eliminare",
                deleteAria: "Elimina righe selezionate",
              },
            }
          },
          confirmationModal: {
            textDeleteStaticReason: "Sei sicuro di voler interrompere il motivo statico per la macchina ",
            deactivateMachines: 'Disattivare le macchine',
            areYouSureDeactivateMachines:
              'Sei sicuro di voler disattivare queste macchine?',
            areYouSureDeactivateMachine:
              'Sei sicuro di voler disattivare questa macchina?',
            confirm: 'Confermare',
            cancel: 'Annulla',
            deactivateUsers: 'Disattiva gli utenti',
            areYouSureDeactivateUsers:
              'Sei sicuro di voler disattivare questi utenti',
            areYouSureDeactivateUser:
              'Sei sicuro di voler disattivare questo utente',
            deactivateStructures: 'Disattivare le strutture',
            areYouSureDeactivateStructures:
              'Sei sicuro di voler disattivare queste strutture',
            areYouSureDeactivateStructure:
              'Sei sicuro di voler disattivare questa struttura',
            deactivateSensors: 'Disattiva i sensori',
            areYouSureDeactivateSensors:
              'Sei sicuro di voler disattivare questi sensori',
            areYouSureDeactivateSensor:
              'Sei sicuro di voler disattivare questo sensore',
            deactivateReasons: 'Disattiva motivi',
            areYouSureDeactivateReasons:
              'Sei sicuro di voler disattivare questi motivi',
            areYouSureDeactivateReason:
              'Sei sicuro di voler disattivare questo motivo',
          },

          machineStatusesText: {
            info: "Informazioni",
            machineStatusColorHeading: "Colore dello stato",
            machineStatusNameHeading: "Titolo",
            machineReasonNameHeading: "Titolo motivo",
            machineReasonColorHeading: "Motivo Colore",
            machineStatusDescriptionHeading: "Descrizione",
            running: 'Corsa',
            scheduled: 'Produrre',
            slow_running: 'Corsa lenta',
            not_running: 'Non correndo',
            unscheduled: 'Perdita di pianificazione',
            creep: 'Rompi Creep',
            unscheduled_PO_presence: 'Vacante',
            not_running_PO_presence: 'Vacante',
            running_PO_presence: 'Occupato',
            running_PO_EOL: 'Il test è in corso',
            waiting_PO_EOL: 'In attesa della parte',
            test_done_PO_EOL: 'Prova fatta',
            not_running_PO_EOL: 'Non correndo',
            unscheduled_PO_EOL: 'Non correndo',
            running_PO_rework: 'Produttivo',
            occupied_PO_rework: 'Occupato',
            not_running_PO_rework: 'Vacante',
            unscheduled_PO_rework: 'Vacante',
            maintenance: "Manutenzione",
            descriptions: {
              machineStatusColorHeading: "Lorem Ipsum",
              machineStatusNameHeading: "Lorem Ipsum",
              machineStatusDescriptionHeading: "Lorem Ipsum",
              running: 'Lorem Ipsum',
              scheduled: 'Lorem Ipsum',
              slow_running: 'Lorem Ipsum',
              not_running: 'Lorem Ipsum',
              unscheduled: 'Lorem Ipsum',
              creep: 'Lorem Ipsum',
              unscheduled_PO_presence: 'Lorem Ipsum',
              not_running_PO_presence: 'Lorem Ipsum',
              running_PO_presence: 'Lorem Ipsum',
              running_PO_EOL: 'Lorem Ipsum',
              waiting_PO_EOL: 'Lorem Ipsum',
              test_done_PO_EOL: 'Lorem Ipsum',
              not_running_PO_EOL: 'Lorem Ipsum',
              unscheduled_PO_EOL: 'Lorem Ipsum',
              running_PO_rework: 'Lorem Ipsum',
              occupied_PO_rework: 'Lorem Ipsum',
              not_running_PO_rework: 'Lorem Ipsum',
              unscheduled_PO_rework: 'Lorem Ipsum',
              maintenance: 'Lorem Ipsum',
            }
          },

          reasonsText: {
            quality: 'Test di qualità',
            machine_malfunction: 'Malfunzionamento della macchina',
            tool_change: 'Cambio utensile (usura)',
            setup: 'Setup',
            tool_breakage: 'Rottura dell\'utensile',
            defective_purchase_part: 'Parte acquistata difettosa',
            lack_of_personnel: 'Mancanza di personale',
            other_break: 'Altra pausa',
            repair_pneumatics: 'Riparazione pneumatica',
            coil_change: 'Cambio bobina/Cambio materiale',
            defective_material: 'Materiale difettoso',
            material_lack: 'Mancanza di materiale',
            repair_hydraulics: 'Riparazione idraulica',
            unavailable: 'Non disponibile',
            cleaning: 'Pulizia/Manutenzione',
            unscheduled: 'Non programmato',
            repair_mechanics: 'Meccanica di riparazione',
           no_qc_approval: 'In attesa dell\'approvazione del controllo qualità',
            follow_up_process: 'Processo di follow-up non disponibile',
            quality_issue: 'Problema di qualità',
            welder_adjustments: 'Regolazioni del saldatore',
            repair_electrical: 'Riparazione elettrica',
            preventive_maintenance: 'Manutenzione preventiva',
            break: 'Pausa',
            machine_adjustments: 'Regolazioni della macchina',
            missing_order: 'Ordine mancante',
            repair_category: 'Riparazione',
            other_reason: 'Altro (ad esempio riunione, interruzione di corrente, ecc.)',
            601: '601 - Stampante',
            602: '602 - Elettrodo',
            603: '603 - Elettrodo flangiato',
            604: '604 - Scheda di circuito',
            605: '605 - Saldatura longitudinale',
            606: '606 - Saldatura incrociata',
            607: '607 - Piatto',
            608: '608 - Pentole',
            609: '609 - Donatore',
            610: '610 - Barriera fotoelettrica',
            611: '611 - Timone',
            612: '612 - Diffondere',
            613: '613 - Arraffatore',
            614: '614 - Levigante',
            615: '615 - Pagaia',
            616: '616 - Pilastro',
            617: '617 - Base',
            618: '618 - Ritiro',
            619: '619 - Trasporto',
            620: '620 - Sicurezza',
            621: '621 - Timbro',
            622: '622 - Nastro',
            623: '623 - generalmente',
            624: '624 - Aria compressa',
            401: '401 - Stampante',
            402: '402 - Elettrodo',
            403: '403 - Elettrodo flangiato',
            404: '404 - Scheda di circuito',
            405: '405 - Saldatura longitudinale',
            406: '406 - Saldatura incrociata',
            407: '407 - Piatto',
            408: '408 - Pentole',
            409: '409 - Donatore',
            410: '410 - Barriera fotoelettrica',
            411: '411 - Timone',
            412: '412 - Diffondere',
            413: '413 - Arraffatore',
            414: '414 - Levigante',
            415: '415 - Pagaia',
            416: '416 - Pilastro',
            417: '417 - Base',
            418: '418 - Ritiro',
            419: '419 - Trasporto',
            420: '420 - Timbro',
            421: '421 - Nastro',
            422: '422 - generalmente',
            423: '423 - Aria compressa',
            301: '301 - Stampante',
            302: '302 - Elettrodo',
            303: '303 - Elettrodo flangiato',
            304: '304 - Scheda di circuito',
            305: '305 - Saldatura longitudinale',
            306: '306 - Saldatura incrociata',
            307: '307 - Piatto',
            308: '308 - Pentole',
            309: '309 - Donatore',
            310: '310 - Barriera fotoelettrica',
            311: '311 - Timone',
            312: '312 - Diffondere',
            313: '313 - Arraffatore',
            314: '314 - Levigante',
            315: '315 - Pagaia',
            316: '316 - Pilastro',
            317: '317 - Base',
            318: '318 - Ritiro',
            319: '319 - Trasporto',
            320: '320 - Sicurezza',
            321: '321 - Timbro',
            322: '322 - Nastro',
            323: '323 - generalmente',
            324: '324 - Aria compressa',
            201: '201 - Stampante',
            202: '202 - Elettrodo',
            203: '203 - Elettrodo flangiato',
            204: '204 - Scheda di circuito',
            205: '205 - Saldatura longitudinale',
            206: '206 - Saldatura incrociata',
            207: '207 - Piatto',
            208: '208 - Pentole',
            209: '209 - Donatore',
            210: '210 - Barriera fotoelettrica',
            211: '211 - Diffondere',
            212: '212 - Arraffatore',
            213: '213 - Levigante',
            214: '214 - Pagaia',
            215: '215 - Pilastro',
            216: '216 - Base',
            217: '217 - Ritiro',
            218: '218 - Trasporto',
            219: '219 - Sicurezza',
            220: '220 - Timbro',
            221: '221 - Nastro',
            222: '222 - generalmente',
            223: '223 - Aria compressa',
            101: '101 - Stampante',
            102: '102 - Elettrodo',
            103: '103 - Elettrodo flangiato',
            104: '104 - Saldatura longitudinale',
            105: '105 - Saldatura incrociata',
            106: '106 - Donatore',
            107: '107 - Barriera fotoelettrica',
            108: '108 - Timone',
            109: '109 - Diffondere',
            110: '110 - Arraffatore',
            111: '111 - Levigante',
            112: '112 - Pagaia',
            113: '113 - Pilastro',
            114: '114 - Base',
            115: '115 - Ritiro',
            116: '116 - Trasporto',
            117: '117 - Sicurezza',
            not_defined: 'Non definito',
            break_creep: 'Rompi Creep',
            not_defined_PO_presence: 'Non definito',
            break_creep_PO_presence: 'Rompi Creep',
            micro_stop: 'Microstop'
          },

          scrapTypesText: {
            coil_change: 'Cambio bobina',
            defective_material: 'Materiale difettoso',
            setup: 'Setup',
            quality_testing: 'Test di qualità',
          },
        };
    }
  };

  return {
    phrases,
  };
};
export {
  // eslint-disable-next-line import/prefer-default-export
  usePhrases,
};
